import React, { useState } from "react";
import {
  Box,
  RoomListItemWrap,
  RoomThumbnailWrap,
  Text,
  ScrapButton,
} from "./Map.style";
import { roomInfoI } from "./MapPinDetail";
import scrapRed from "../../../assets/images/mainPage/scrap-colored.png";
import scrapGrayFill from "../../../assets/images/mapPage/scrapGrayFill.png";
import axiosInstance from "../../../services/axiosConfig";
import { useNavigate } from "react-router-dom";

interface props {
  room: roomInfoI;
}

export default function RoomContentsItem({ room }: props) {
  const navigate = useNavigate();
  const [scrapState, setScrapState] = useState<boolean>(room.scrapbook);

  const onClickScrap = async (e: React.MouseEvent) => {
    const token = window.sessionStorage.getItem("Authorization");

    e.stopPropagation();
    if (token) {
      try {
        const response = await axiosInstance.post(`/normal/user/scrap`, {
          category: "room",
          entityId: room?.id,
        });

        if (response.status === 200) {
          if (scrapState) {
            alert("스크랩 취소 되었습니다.");
          } else {
            alert("스크랩 되었습니다.");
          }
          setScrapState((prev) => !prev);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("로그인 후 이용할 수 있는 서비스 입니다.");
      navigate("/login");
    }
  };

  return (
    <RoomListItemWrap onClick={() => navigate(`/detail?roomId=${room.id}`)}>
      <RoomThumbnailWrap $bgUrl={room.floorPlanUrl}>
        {scrapState ? (
          <ScrapButton onClick={onClickScrap} src={scrapRed} />
        ) : (
          <ScrapButton onClick={onClickScrap} src={scrapGrayFill} />
        )}
      </RoomThumbnailWrap>
      <Box $direction="column" $justifyContent="flex-start">
        <Text $color="#0123B4" $fontWeight="bold">
          {room.rent.toLocaleString()}
          {` 엔 / `}
          {room.managementFee.toLocaleString()}
          {` 엔`}
        </Text>
        <Box $direction="column" $alignItems="flex-start" $margin="12px 0">
          <Box $justifyContent="center" $alignItems="center">
            <Box
              $bgColor="#f5f5f5"
              $fontSize="12px"
              $border="1px solid #e4e4e4"
              $padding="2px 8px"
              $margin="1px 8px 1px 0"
              $alignItems="center"
              $justifyContent="center"
            >
              시키킹
            </Box>
            <Text $fontSize="12px">
              {room.deposit.toLocaleString()}
              {` 엔`}
            </Text>
          </Box>
          <Box $justifyContent="center" $alignItems="center">
            <Box
              $bgColor="#f5f5f5"
              $fontSize="12px"
              $border="1px solid #e4e4e4"
              $padding="2px 8px"
              $margin="1px 8px 1px 0"
              $alignItems="center"
              $justifyContent="center"
            >
              레이킹
            </Box>
            <Text $fontSize="12px">
              {room.gratuity.toLocaleString()}
              {` 엔`}
            </Text>
          </Box>
        </Box>
        <Text $fontSize="12px">
          {room.roomType}
          {` / `}
          {room.area}
          {`㎡ / `}
          {room.numOfFloors}
          {`층`}
        </Text>
      </Box>
    </RoomListItemWrap>
  );
}
