import styled from "styled-components";
import InformationBox from "./InformationBox";
import { roomDetailType } from "../Detail";
import priceIcon from "../../../assets/images/detail/price.png";
import { Fragment } from "react/jsx-runtime";

function DetailPrice({
  roomDetailState,
}: {
  roomDetailState?: roomDetailType;
}) {
  const PRICE1 = [
    { name: "월세", price: roomDetailState?.rent.toLocaleString() },
    { name: "관리비", price: roomDetailState?.managementFee.toLocaleString() },
  ];
  const PRICE2 = [
    { name: "시키킹", price: roomDetailState?.deposit.toLocaleString() },
    { name: "레이킹", price: roomDetailState?.gratuity.toLocaleString() },
    {
      name: "열쇠교환비",
      price: roomDetailState?.keyExchangeFee.toLocaleString(),
    },
    {
      name: "화재보험비",
      price: roomDetailState?.fireInsuranceCost.toLocaleString(),
    },
    {
      name: "보증심사료",
      price: roomDetailState?.guaranteeReviewFee.toLocaleString(),
    },
    {
      name: "중개수수료",
      price: roomDetailState?.brokerageFee.toLocaleString(),
    },
  ];

  const totalMonthPrice = () => {
    let totalMonthPrice =
      roomDetailState && roomDetailState?.rent + roomDetailState?.managementFee;

    roomDetailState &&
      roomDetailState?.moreFixedCost.split("$").map((item, index) => {
        if (index > 0) {
          totalMonthPrice =
            totalMonthPrice && totalMonthPrice + Number(item.split("@")[1]);
        }
      });

    return totalMonthPrice && totalMonthPrice.toLocaleString();
  };

  const totalInitialPrice = () => {
    let totalInitialPrice =
      roomDetailState &&
      roomDetailState?.deposit +
        roomDetailState?.gratuity +
        roomDetailState?.keyExchangeFee +
        roomDetailState?.fireInsuranceCost +
        roomDetailState?.guaranteeReviewFee +
        roomDetailState?.brokerageFee;

    roomDetailState &&
      roomDetailState?.moreInitialCost.split("$").map((item, index) => {
        if (index > 0) {
          totalInitialPrice =
            totalInitialPrice && totalInitialPrice + Number(item.split("@")[1]);
        }
      });
    return totalInitialPrice && totalInitialPrice?.toLocaleString();
  };

  return (
    <>
      <DetailPriceWrapper>
        <InformationBox
          width={{ pc: "338px", laptop: "315px", mobile: "100%" }}
          borderRadius="10px"
        >
          <PriceTop>
            <PriceIcon src={priceIcon} />
            <PriceTitle>월고정비용</PriceTitle>
          </PriceTop>
          <PriceInner>
            <PriceContents>
              {PRICE1.map((item, index) => (
                <PriceItem key={index}>
                  <PriceItemName>{item.name}</PriceItemName>
                  <Line></Line>
                  <PriceItemDetail>{item.price}</PriceItemDetail>
                </PriceItem>
              ))}
              {roomDetailState?.moreFixedCost.split("$").map((item, index) => (
                <Fragment key={index}>
                  {index > 0 && (
                    <PriceItem key={index}>
                      <PriceItemName>
                        {item && item.split("@")[0]}
                      </PriceItemName>
                      <Line></Line>
                      <PriceItemDetail>
                        {item && Number(item.split("@")[1]).toLocaleString()}
                      </PriceItemDetail>
                    </PriceItem>
                  )}
                </Fragment>
              ))}
            </PriceContents>
            <TotalPrice>
              <TotalPriceName>합계</TotalPriceName>
              <TotalPriceDetail>{totalMonthPrice()}円</TotalPriceDetail>
            </TotalPrice>
          </PriceInner>
        </InformationBox>
        <InformationBox
          width={{ pc: "338px", laptop: "315px", mobile: "100%" }}
          borderRadius="10px"
        >
          <PriceTop>
            <PriceIcon src={priceIcon} />
            <PriceTitle>초기비용</PriceTitle>
          </PriceTop>
          <PriceInner>
            <PriceContents>
              {PRICE2.map((item, index) => (
                <PriceItem key={index}>
                  <PriceItemName>{item.name}</PriceItemName>
                  <Line></Line>
                  <PriceItemDetail>{item.price}</PriceItemDetail>
                </PriceItem>
              ))}
              {roomDetailState?.moreInitialCost
                .split("$")
                .map((item, index) => (
                  <Fragment key={index}>
                    {index > 0 && (
                      <PriceItem key={index}>
                        <PriceItemName>
                          {item && item.split("@")[0]}
                        </PriceItemName>
                        <Line></Line>
                        <PriceItemDetail>
                          {item && Number(item.split("@")[1]).toLocaleString()}
                        </PriceItemDetail>
                      </PriceItem>
                    )}
                  </Fragment>
                ))}
            </PriceContents>
            <TotalPrice>
              <TotalPriceName>합계</TotalPriceName>
              <TotalPriceDetail>{totalInitialPrice()}円</TotalPriceDetail>
            </TotalPrice>
          </PriceInner>
        </InformationBox>
      </DetailPriceWrapper>
      <Notification>
        <div>각 관리회사의 변경, 오너에 따른 월세 변경이 있을 수 있으므로</div>
        <div>상담시 자세한 금액을 확인해주세요</div>
      </Notification>
    </>
  );
}

export default DetailPrice;

const DetailPriceWrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: #f7f9fa;
  @media screen and (min-width: 1280px) {
    margin-top: 34px;
    justify-content: space-between;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 32px;
    justify-content: space-between;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 18px;
    flex-direction: column;
    gap: 28px;
  }
`;

const PriceTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #0123B4;
  @media screen and (min-width: 1280px) {
    height: 80px;
    gap: 16px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 75px;
    gap: 14px;
  }
  @media screen and (max-width: 1023px) {
    height: 58px;
    gap: 9px;
  }
`;
const PriceIcon = styled.img`
  @media screen and (min-width: 1280px) {
    width: 26px;
    height: 22px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 24px;
    height: 20px;
  }
  @media screen and (max-width: 1023px) {
    width: 25px;
    height: 21px;
  }
`;

const PriceTitle = styled.div`
  color: #ffffff;
  @media screen and (min-width: 1280px) {
    font-size: 38px;
    font-weight: bold;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 34px;
    font-weight: bold;
  }
  @media screen and (max-width: 1023px) {
    font-size: 20px;
    font-weight: bold;
  }
`;

const PriceInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  @media screen and (min-width: 1280px) {
    /* min-height: 358px; */
    padding: 38px 19px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    /* min-height: 335px; */
    padding: 36px 25px;
  }
  @media screen and (max-width: 1023px) {
    padding: 28px 18px;
  }
`;

const PriceContents = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1280px) {
    row-gap: 20px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    row-gap: 15px;
  }
  @media screen and (max-width: 1023px) {
    row-gap: 21px;
  }
`;

const PriceItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
`;
const PriceItemName = styled.div`
  @media screen and (min-width: 1280px) {
    font-size: 19px;
    margin-right: 25px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 19px;
    margin-right: 20px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
    margin-right: 26px;
  }
`;

const Line = styled.div`
  // 부모 컴포넌트의 남은 공간을 채우도록 설정
  flex: 1;
  height: 1px;
  border-top: 1px dotted black;
`;

const PriceItemDetail = styled.div`
  @media screen and (min-width: 1280px) {
    font-size: 19px;
    margin-left: 25px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 19px;
    margin-left: 20px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
    margin-left: 26px;
  }
`;

const TotalPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media screen and (min-width: 1280px) {
    min-height: 74px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    min-height: 68px;
  }
  @media screen and (max-width: 1023px) {
    min-height: 59px;
  }
`;

const TotalPriceName = styled.div`
  font-weight: bold;
  color: #0123B4;
  @media screen and (min-width: 1280px) {
    font-size: 38px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 34px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 26px;
  }
`;

const TotalPriceDetail = styled.div`
  font-weight: bold;
  @media screen and (min-width: 1280px) {
    font-size: 38px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 34px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 26px;
  }
`;

const Notification = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 1280px) {
    font-size: 16px;
    margin-top: 32px;
    gap: 4px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 15px;
    margin-top: 29px;
    gap: 3px;
  }
  @media screen and (max-width: 1023px) {
    flex-direction: column;
    font-size: 12px;
    margin-top: 23px;
    gap: 7px;
  }
`;
