import React,{useEffect, useState} from "react";
import styled from "styled-components";
import Like from "../../../assets/images/ResidentReview/Like.png";
import ColoredLike from "../../../assets/images/ResidentReview/ColoredLike.png";
import useDeviceSize from "../../../hooks/useDeviceSize";
import AdminIMG from "../../../assets/images/ResidentReview/ProfileIMG.png"
import axiosInstance from "../../../services/axiosConfig";
interface HeaderProps {
  profileIMG: string | undefined;
  userNickname: string | undefined;
  createAt: string | undefined;
  id:string | undefined;
  isRecommend:boolean | undefined;
}

const Header: React.FC<HeaderProps> = ({
  profileIMG,
  userNickname,
  createAt,
  id,
  isRecommend,
}) => {
  const { isMobile } = useDeviceSize();
  const [recommended, setRecommended] = useState<boolean>();

useEffect(()=>{
  setRecommended(isRecommend);
},[isRecommend])

  const handleRecomand = async () => {
    try{
    const res = await axiosInstance.put(`/review/recommend/${id}`,{},
    );
    setRecommended(!recommended);
    }catch(error){
    }
  };

  return (
    <HeaderContainer>
      <ProfileDiv>
      <img src={profileIMG || AdminIMG} alt="Profile" />
        <TextDiv>
          <div style={{ fontWeight: "bold" }}>{userNickname}</div>
          <RresponsiveDiv>{createAt}</RresponsiveDiv>
        </TextDiv>
      </ProfileDiv>
      {!isMobile ? (
        <ProfileDiv onClick={handleRecomand}  className="second">
          <img src={recommended ? ColoredLike : Like} alt="Like" />
          <div style={{ paddingTop: "3px", fontFamily:"Noto Sans KR", color:'#9BA2AE',fontWeight:'500' }}>
            추천하기
          </div>
        </ProfileDiv>
      ) : (
        <StyledBTN onClick={handleRecomand} recommended={recommended}>추천하기</StyledBTN>
      )}
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 49px;
  align-items: center;
`;

const ProfileDiv = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 49px;
    height: 49px;
    @media screen and (max-width: 1023px) {
      width: 31px;
      height: 31px;
      padding-left: 19px;
    }
  }

  &.second {
    flex-direction: column;
    img {
      width: 28px;
      height: 30px;
    }
  }
`;

const TextDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 10px;
  div {
    @media screen and (max-width: 1023px) {
      font-size: 10px;
    }
  }

  @media screen and (max-width: 1023px) {
    padding-left: 5px;
  }
`;

const StyledBTN = styled.button<{ recommended?: boolean }>`
  margin-right: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 28px;
  background-color: ${({ recommended }) => (recommended ? "#0123B4" : "#d3d3d3")};
  border: none;
  border-radius: 5px;
  font-size: 11px;
  color: ${({ recommended }) => (recommended ? "white" : "black")};
  font-weight: bold;
`;


const RresponsiveDiv = styled.div`
  padding-top: 5px;
  font-family: "Noto Sans KR";
  font-weight: 500;
  color: #9BA2AE;
  @media screen and (max-width:500px) {
  padding-top: 3.5px;
  }

`;
