import styled from "styled-components";
import { roomInfoType } from "./MansionContentsItem";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../services/axiosConfig";
import { useAdminSelectedRoomState } from "../../../recoil/mansionManagement/adminSelectedRoomState";
import { useAdminPendingDeleteRoomState } from "../../../recoil/mansionManagement/adminPendingDeleteRoomState";

function MansionContentsRoomItem({
  roomInfo,
  mansionName,
  agency,
  mansionId,
  mansionStatusState,
}: {
  roomInfo: roomInfoType;
  agency: string;
  mansionName: string;
  mansionId: number;
  mansionStatusState: "Public" | "Private" | "Temp";
}) {
  const { adminSelectedRoomState, setAdminSelectedRoomState } =
    useAdminSelectedRoomState();
  const { adminPendingDeleteRoomState } = useAdminPendingDeleteRoomState();

  const navigate = useNavigate();
  const [roomStatusState, setRoomStatusState] = useState(roomInfo.status);

  const fetchRoomState = async () => {
    if (roomStatusState !== "Temp") {
      if (mansionStatusState === "Public") {
        try {
          const response = await axiosInstance.patch(
            "admin/property/room/status",
            {
              targetId: roomInfo.id,
              changeStatus: roomStatusState === "Public" ? "Private" : "Public",
            }
          );
          if (response.status === 200)
            setRoomStatusState(
              roomStatusState === "Public" ? "Private" : "Public"
            );
        } catch (error) {
          alert("공개상태 변경에 실패했습니다.");
        }
      } else {
        alert("매물이 비공개 상태인 경우 호실을 공개할 수 없습니다.");
      }
    }
  };

  const handleSelectRoom = (e: React.ChangeEvent) => {
    if (adminSelectedRoomState.includes(roomInfo.id)) {
      const newSelectedMansion = [...adminSelectedRoomState];
      const index = newSelectedMansion.indexOf(roomInfo.id);
      if (index > -1) {
        newSelectedMansion.splice(index, 1);
      }
      setAdminSelectedRoomState(newSelectedMansion);
    } else {
      setAdminSelectedRoomState([...adminSelectedRoomState, roomInfo.id]);
    }
  };

  const isPendingDeleted = adminPendingDeleteRoomState.includes(roomInfo.id);

  useEffect(() => {
    setRoomStatusState(roomInfo.status);
  }, [roomInfo]);
  return (
    <ItemWrapper>
      <CheckboxDiv></CheckboxDiv>
      <Sequence></Sequence>
      <MansionInfo $pendingdeleted={isPendingDeleted}>
        <MnansionThumnail>
          <CheckBox
            type="checkBox"
            checked={adminSelectedRoomState.includes(roomInfo.id)}
            onChange={(e) => handleSelectRoom(e)}
          ></CheckBox>
        </MnansionThumnail>
        <MansionNameDiv
          onClick={() => navigate(`/detail?roomId=${roomInfo.id}`)}
        >
          {mansionName} {roomInfo.roomNum}
        </MansionNameDiv>
      </MansionInfo>
      <ManagementCompany $pendingdeleted={isPendingDeleted}>
        {agency}
      </ManagementCompany>
      <MansionState>
        <MansionStateItem
          onClick={() => fetchRoomState()}
          roomStatus={roomStatusState}
        >
          {roomStatusState === "Temp"
            ? "임시저장"
            : roomStatusState === "Private"
            ? "비공개"
            : "공개"}
        </MansionStateItem>
      </MansionState>
      <ModifyButtonDiv>
        <ModifyButton
          onClick={() =>
            navigate(
              `/admin/registrationRoom?type=modify&mansionId=${mansionId}&roomId=${roomInfo.id}`
            )
          }
        >
          수정
        </ModifyButton>
      </ModifyButtonDiv>
      <MoreInfoButtonDiv></MoreInfoButtonDiv>
    </ItemWrapper>
  );
}

export default MansionContentsRoomItem;

const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 85px;
  height: 85px;
  font-size: 18px;
  border-bottom: 1px solid #e6e6e6;
`;

const CheckboxDiv = styled.div`
  width: 55px;
  min-height: 100%;
`;

const CheckBox = styled.input`
  display: flex;
  accent-color: #0123B4;
  width: 26px;
  height: 26px;
`;

const Sequence = styled.div`
  height: 100%;
  width: 77px;
`;

const MansionInfo = styled.div<{ $pendingdeleted: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 7px;
  height: 100%;
  width: 620px;
  gap: 35px;
  color: ${(props) => props.$pendingdeleted && "#ccc"};
  cursor: pointer;
  font-weight: 500;
`;

const MnansionThumnail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 51px;
  height: 51px;
`;

const MansionNameDiv = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  height: 100%;
`;

const ManagementCompany = styled.div<{ $pendingdeleted: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 134px;
  font-weight: 500;
  color: ${(props) => props.$pendingdeleted && "#ccc"};
`;

const MansionState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 164px;
`;

const MansionStateItem = styled.div<{ roomStatus: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 106px;
  height: 28px;
  background-color: ${(props) =>
    props.roomStatus === "Temp"
      ? "white"
      : props.roomStatus === "Private"
      ? "black"
      : "#00A8EC"};
  color: ${(props) => (props.roomStatus === "Temp" ? "#0123B4" : "white")};
  border: ${(props) => props.roomStatus === "Temp" && "1px solid #99393a"};
  border-radius: 20px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
`;

const ModifyButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 101px;
`;

const ModifyButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 59px;
  height: 28px;
  border: 1px solid #a7a7a7;
  background-color: white;
  border-radius: 20px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
`;

const MoreInfoButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 55px;
`;
