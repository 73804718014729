import styled from "styled-components";
import { useAdminRegistrationRoomState } from "../../../recoil/mansionManagement/adminRegistrationRoomStateAtom";

export type fileDataType = {
  id?: number;
  fileUrl?: string;
  file?: File | null;
};

function RoomRegistrationImage({ roomIndex }: { roomIndex: number }) {
  const { adminRegistrationRoomState, handleUpdateRoom } =
    useAdminRegistrationRoomState();

  console.log(adminRegistrationRoomState);

  const roomData = adminRegistrationRoomState[roomIndex];

  const handleImageDrag = (event: React.DragEvent) => {
    // 이미지 드래그&드롭 시 새 창에 이미지가 뜨지 않도록 제어
    event.preventDefault();
  };

  const handleRoomImageUpload = (
    event: React.ChangeEvent<HTMLInputElement> | React.DragEvent
  ) => {
    event.preventDefault();
    let files: FileList | null = null;
    let inputElement: HTMLInputElement | null = null;

    if (event.type === "drop") {
      const dragEvent = event as React.DragEvent;
      files = dragEvent.dataTransfer.files;
    } else if (event.type === "change") {
      const changeEvent = event as React.ChangeEvent<HTMLInputElement>;
      files = changeEvent.target.files;
      inputElement = changeEvent.target; // input element 참조 저장
    }

    if (files) {
      console.log("rooImg: ", roomData.roomImg.length);
      console.log("addRooImg: ", roomData.addRoomImg.length);
      const totalImagesCount =
        roomData.roomImg.length + roomData.addRoomImg.length;
      console.log("totalRooImg: ", totalImagesCount);

      const newImages: fileDataType[] = [];
      const fileReaders: Promise<fileDataType>[] = [];

      Array.from(files).forEach((file) => {
        if (newImages.length + totalImagesCount < 15) {
          // 15장보다 작을 때 추가
          console.log("totalRooImg+new: ", newImages.length + totalImagesCount);
          console.log("+new: ", newImages.length);

          const reader = new FileReader();
          const fileReaderPromise = new Promise<fileDataType>((resolve) => {
            reader.onloadend = () => {
              if (typeof reader.result === "string") {
                resolve({
                  fileUrl: reader.result,
                  file,
                });
              }
            };
          });
          reader.readAsDataURL(file);
          fileReaders.push(fileReaderPromise);
        }
      });

      // 모든 FileReader가 끝난 후 상태 업데이트
      Promise.all(fileReaders).then((uploadedImages) => {
        const allImages = [...(roomData.addRoomImg || []), ...uploadedImages];
        if (allImages.length + roomData.roomImg.length <= 15) {
          handleUpdateRoom(roomIndex, {
            ...roomData,
            addRoomImg: allImages,
          });
        }

        // 파일 처리 후 input 초기화
        if (inputElement) {
          inputElement.value = "";
        }
      });
    }
  };

  const handleFloorPlanUpload = (
    event: React.ChangeEvent<HTMLInputElement> | React.DragEvent
  ) => {
    event.preventDefault();
    let file: File | null = null;

    if (event.type === "drop") {
      const dragEvent = event as React.DragEvent;
      file = dragEvent.dataTransfer.files[0];
    } else if (event.type === "change") {
      const changeEvent = event as React.ChangeEvent<HTMLInputElement>;
      file = changeEvent.target.files && changeEvent.target.files[0];
    }

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          handleUpdateRoom(roomIndex, {
            ...roomData,
            floorPlan: {
              fileUrl: reader.result,
              file,
            },
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleResetRoomImages = () => {
    const deletedIds = roomData.roomImg
      .filter((img) => img.id !== undefined)
      .map((img) => img.id as number);

    handleUpdateRoom(roomIndex, {
      ...roomData,
      roomImg: [],
      addRoomImg: [],
      deletedTargetImgId: deletedIds,
    });
  };

  const handleImageDelete = (index: number) => {
    const deletedImage = roomData.roomImg[index];
    const images = roomData.roomImg.filter((_, i) => i !== index);

    const deletedIds = deletedImage.id
      ? [...(roomData.deletedTargetImgId || []), deletedImage.id]
      : roomData.deletedTargetImgId || [];

    handleUpdateRoom(roomIndex, {
      ...roomData,
      roomImg: images,
      deletedTargetImgId: deletedIds,
    });
  };

  const handleAdditionalImageDelete = (index: number) => {
    const images = roomData.addRoomImg?.filter((_, i) => i !== index);
    handleUpdateRoom(roomIndex, {
      ...roomData,
      addRoomImg: images,
    });
  };

  return (
    <InformationWrapper>
      <ImageInputArea>
        <InformationTitle>이미지</InformationTitle>

        <UploadImageWrapper
          onDragOver={handleImageDrag}
          onDrop={handleRoomImageUpload}
        >
          드래그 앤 드롭으로 이미지 추가
          <ChooseImage>
            <StyledFileInput
              type="file"
              multiple
              onChange={handleRoomImageUpload}
            />
            + 이미지 선택
          </ChooseImage>
        </UploadImageWrapper>
      </ImageInputArea>
      <NotificationArea>
        {(roomData.roomImg?.length > 0 || roomData.addRoomImg?.length > 0) && (
          <Preview>
            {roomData.roomImg &&
              roomData.roomImg.map((item, index) => (
                <PreviewImg
                  src={item.fileUrl}
                  onClick={() => handleImageDelete(index)}
                  key={index}
                ></PreviewImg>
              ))}
            {roomData.addRoomImg &&
              roomData.addRoomImg.map((item, index) => (
                <PreviewImg
                  src={item.fileUrl}
                  onClick={() => handleAdditionalImageDelete(index)}
                  key={index}
                ></PreviewImg>
              ))}
            <DeleteAllImageButton onClick={() => handleResetRoomImages()}>
              모두 삭제
            </DeleteAllImageButton>
          </Preview>
        )}
      </NotificationArea>
      <NotificationArea>
        <Notification>
          <div>※ 이미지는 최대 15장까지 추가할 수 있습니다.</div>
          <div>※ 권장 크기는 1280px x 1280px 입니다.</div>
          <div>※ 10MB 이내의 이미지 파일을 준비해주세요.</div>
          <div>※ 대응 파일 : JPG, PNG, GIF </div>
        </Notification>
      </NotificationArea>
      <ImageInputArea style={{ marginTop: 36 }}>
        <InformationTitle>도면 이미지</InformationTitle>
        {roomData.floorPlan?.fileUrl ? (
          <UploadedImageView>
            <PreviewImage
              src={roomData.floorPlan.fileUrl}
              alt="uploadedfileImage"
              onClick={() => {
                handleUpdateRoom(roomIndex, {
                  ...roomData,
                  floorPlan: { id: 0, fileUrl: "", file: null },
                });
              }}
            />
          </UploadedImageView>
        ) : (
          <UploadImageWrapper
            onDragOver={handleImageDrag}
            onDrop={handleFloorPlanUpload}
          >
            드래그 앤 드롭으로 이미지 추가
            <ChooseImage>
              <StyledFileInput type="file" onChange={handleFloorPlanUpload} />+
              이미지 선택
            </ChooseImage>
          </UploadImageWrapper>
        )}
      </ImageInputArea>
      <NotificationArea>
        <Notification>
          <div>※ 이미지는 1장만 업로드 해주세요.</div>
          <div>※ 권장 크기는 1280px x 1280px 입니다.</div>
          <div>※ 10MB 이내의 이미지 파일을 준비해주세요.</div>
          <div>※ 대응 파일 : JPG, PNG, GIF </div>
        </Notification>
      </NotificationArea>
    </InformationWrapper>
  );
}

export default RoomRegistrationImage;

const InformationWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 23px;
  border-bottom: 2px solid #e6e6e6;
  padding: 76px 0;
`;

const InformationTitle = styled.div`
  font-size: 25px;
  font-weight: 600;
  padding-left: 23px;
  letter-spacing: -2px;
`;

const ImageInputArea = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
`;

const UploadImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 904px;
  height: 204px;
  border-radius: 5px;
  background-color: #f7f8f9;
  border: 1px dotted #a7a7a7;
  font-size: 15px;
  gap: 15px;
`;

const StyledFileInput = styled.input`
  display: none;
`;

const ChooseImage = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 131px;
  height: 28px;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
`;

const UploadedImageView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 904px;
  height: 204px;
  border-radius: 5px;
  background-color: #f7f8f9;
  border: 1px solid black;
  font-size: 15px;
  gap: 15px;
  overflow: hidden;
`;

const PreviewImage = styled.img`
  width: 100%;
  cursor: pointer;
`;

const NotificationArea = styled.div`
  margin-top: 18px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-weight: 500;
`;

const Notification = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  padding-left: 24.5px;
  width: 904px;
  height: 159px;
  gap: 11px;
  font-size: 18px;
  background-color: #edeef1;
  border: 1px solid #a7a7a7;
`;

const Preview = styled.div`
  display: flex;
  border-radius: 5px;
  align-items: center;
  padding: 20px;
  width: 904px;
  height: 100px;
  gap: 13px;
  font-size: 18px;
  background-color: #edeef1;
  border: 1px solid #a7a7a7;
`;

const DeleteAllImageButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid black;
  background-color: #0123B4;
  color: white;
  font-size: 15px;
  cursor: pointer;
`;

const PreviewImg = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;
