import React, { useState, useEffect, ChangeEvent } from "react";
import styled from "styled-components";
import TermsAgreement, { CheckboxesState } from "./TermsAgreement";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LOGO from "../../../assets/images/logo.png"
const MoreInfo: React.FC = () => {
  const [allRequiredChecked, setAllRequiredChecked] = useState(false);
  const [userName, setUserName] = useState("");
  const [isuserNameFilled, setIsuserNameFilled] = useState(false);
  const [birthDay, setbirthDay] = useState("");
  const [isbirthDayValid, setIsbirthDayValid] = useState(false);
  const AccessToken = sessionStorage.getItem("Authorization");
  const [tosInfoList, setTosInfoList] = useState<CheckboxesState>({
    olderThen14Years: false,
    agreeToTermsOfUse: false,
    personalInformationCollectionAndUsageAgreement: false,
    agreementToUsePersonalInformationForMarketing: false,
    agreementToUseSMS: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    setIsbirthDayValid(birthDay.length === 10);
  }, [birthDay]);

  useEffect(() => {
    setIsuserNameFilled(userName.length >= 1);
  }, [userName]);

  const handleAllRequiredChecked = (checked: boolean) => {
    setAllRequiredChecked(checked);
  };

  const handlebirthDayChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const key = e.nativeEvent as KeyboardEvent;

    let dateNumText = value.replace(/[^0-9]/g, "");
    let formattedDate = dateNumText;

    if (dateNumText.length >= 4 && dateNumText.length < 6) {
      formattedDate = dateNumText.substr(0, 4) + "-" + dateNumText.substr(4);
    } else if (dateNumText.length >= 6) {
      formattedDate =
        dateNumText.substr(0, 4) +
        "-" +
        dateNumText.substr(4, 2) +
        "-" +
        dateNumText.substr(6);
    }

    setbirthDay(formattedDate);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      const { selectionStart } = e.currentTarget;
      if (selectionStart && (selectionStart === 5 || selectionStart === 8)) {
        e.preventDefault();
        setbirthDay(
          (prev) =>
            prev.slice(0, selectionStart - 1) + prev.slice(selectionStart)
        );
      }
    }
  };

  const handleUserNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setUserName(name);
  };


  const handleCheckboxChange = (updatedCheckboxes: CheckboxesState) => {
    setTosInfoList(updatedCheckboxes);
  };

  const handleSignUpOnClick = async () => {
    try {

      const response = await axios.post(
        "https://houberapp.com/auth/oauth/register",
        {
          userName,
          birthDay,
          tosInfoList
        },
        {
          headers: {
            Authorization: AccessToken
          }
        }
      );

      if (response.status === 200) {
        navigate('/')
      } else {
        alert("회원가입 실패: " + response.data.message);
      }
    } catch (error) {

      alert("회원가입 중 오류가 발생했습니다.");
    }
  };

  return (
    <SignUpContainer>
      <LogoBox>
        <img src={LOGO} style={{width:'100%', height:'100%'}}/>
      </LogoBox>
      <StyledDiv />

      <LayoutDiv>
        <Stylediv className="name">추가 정보입력</Stylediv>
        <Stylediv style={{fontWeight:'600'}}>이름</Stylediv>
        <DivInput>
          <InputField
            placeholder="이름"
            value={userName}
            onChange={handleUserNameChange}
          />
        </DivInput>

        <Stylediv style={{fontWeight:'600'}}>생년월일</Stylediv>
        <DivInput>
          <InputField
            placeholder="8자리 입력"
            maxLength={10}
            value={birthDay}
            onChange={handlebirthDayChange}
            onKeyDown={handleKeyDown}
          />
        </DivInput>

        <Stylediv style={{fontWeight:'600'}}>약관동의</Stylediv>
        <TermsAgreement
          onAllRequiredChecked={handleAllRequiredChecked}
          onCheckboxChange={handleCheckboxChange}
        />
        <Button
          disabled={
            !allRequiredChecked || !isbirthDayValid || !isuserNameFilled
          }
          onClick={handleSignUpOnClick}
        >
          회원가입하기
        </Button>
      </LayoutDiv>

      <div style={{ marginBottom: "200px" }} />
    </SignUpContainer>
  );
};

export default MoreInfo;

const Stylediv = styled.div`
  margin-bottom: 5px;
  font-size: 23px;
  font-weight: 400;
  &.name {
    font-size: 26px;
    font-weight: bold;
    padding-top: 40px;
    padding-bottom: 40px;

    @media (max-width: 500px) {
      font-size: 16px;
      font-weight: 600;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  &.nickName {
    margin-top: 30px;
  }
  @media (max-width: 500px) {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 400;
  }
  &.bottom {
    font-weight: 400;
    font-size: 21;
    text-align: center;
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
`;

const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;

`;


const LogoBox = styled.div`
  text-align: center;
  margin-top: 90px;
  width: 165px;
  height: 68.7px;
  @media (max-width: 500px) {
    margin-top: 50px;
    width: 130px;
    height: 51px;
  }
`;

const StyledDiv = styled.div`
  background-color: rgba(187, 185, 185, 0.5);
  width: 100%;
  padding: 1px;
  margin-top: 30px;
`;

const InputField = styled.input`
  border-radius: 10px;
  width: 100%;
  padding-left: 20px;
  font-weight: 500;
  font-size: 16px;
  font-family: "Noto Sans KR";
  border: 1px solid #ccc;
  height: 60px;
  outline: none;
  @media (max-width: 500px) {
    height: 34px;
  }
`;

const DivInput = styled.div`
  display: flex;
  border: none;
  border-radius: 5px;
  background-color: white;
  margin-top: 5px;
  margin-bottom: 30px;
  @media (max-width: 500px) {
    margin-bottom: 15px;
  }
`;

const LayoutDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 480px;
  border: none;


  @media (max-width: 500px) {
    width: 350px;
  }
`;

const Button = styled.button`
  background-color: ${({ disabled }) => (disabled ? "#ccc" : "#0123B4")};
  color: ${({ disabled }) => (disabled ? "#858585" : "white")};
  font-size: 20px;
  border: none;
  width: auto;
  height: 70px;
  border-radius: 5px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 20px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  transition: background-color 0.3s ease;

  &:disabled {
    cursor: not-allowed;
  }
  @media (max-width: 500px) {
    height: 35px;
    font-size: 15px;
  }
`;
