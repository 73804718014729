import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Modal from "./components/Modal";
import ResponsiveWrapper from "../../components/ResponsiveWrapper";
import face from "../../../src/assets/images/mypage/face.png";
import share from "../../../src/assets/images/mypage/share.png";
import scrap from "../../../src/assets/images/mypage/scrap.png";
import q from "../../../src/assets/images/mypage/Q.png";
import settings from "../../../src/assets/images/mypage/settings.png";
import axiosInstance from "../../services/axiosConfig";
import Background from "../../assets/images/mypage/mypageBack.jpeg";

// Media query variables
const mediaQueries = {
  tablet: "@media screen and (max-width: 1279px) and (min-width: 1024px)",
  mobile: "@media screen and (max-width: 1023px)",
};

// Styled components
const Home = styled.div`
  display: flex;
  flex-direction: column;
  margin: 70px 0;
  padding-bottom: 65px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  ${mediaQueries.tablet} {
    margin: 59px 0;
    padding-bottom: 71px;
  }
  ${mediaQueries.mobile} {
    margin: 29px 0;
    border: 0px solid #cccccc;
    padding-bottom: 0px;
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 495px;
  padding: 0px;
  margin: 0px;

  ${mediaQueries.tablet} {
    height: 395px;
  }
  ${mediaQueries.mobile} {
    height: 60px;
  }
`;

const BackgroundImg = styled.img`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  padding: 0px;
  margin: 0px;
  /* background-color: #dcdcdc; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  ${mediaQueries.tablet} {
    height: 300px;
  }
  ${mediaQueries.mobile} {
    display: none;
  }
`;

const Profile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 142px;
  padding: 0px;
  margin: 0px;

  ${mediaQueries.tablet} {
    height: 116px;
  }
  ${mediaQueries.mobile} {
    height: 60px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding: 0 50px;

  ${mediaQueries.mobile} {
    justify-content: space-between;
    align-items: center;
    padding: 0px;
  }
`;

const Avatar = styled.div`
  width: 281px;
  height: 281px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  margin-right: 50px;

  ${mediaQueries.tablet} {
    width: 237px;
    height: 237px;
  }
  ${mediaQueries.mobile} {
    width: 59px;
    height: 59px;
    margin-right: 22px;
  }
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;

  object-fit: cover; /* 이미지 비율을 유지하며 컨테이너에 맞게 조절 */
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
  ${mediaQueries.mobile} {

  margin-bottom: 0px;

  }
`;

const NickName = styled.div`
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 12px;

  ${mediaQueries.tablet} {
    font-size: 34px;
  }
  ${mediaQueries.mobile} {
    font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;

  }
`;

const Id = styled.div`
  font-size: 24px;
  font-weight: 500;

  ${mediaQueries.tablet} {
    font-size: 22px;
  }
  ${mediaQueries.mobile} {
  font-weight: 400;

    font-size: 12px;
  }
`;

const Box2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px;
  margin: 0px;
  align-items: center;
  width: calc(100% - 330px);
  ${mediaQueries.tablet} {
    width: calc(100% - 280px);
  }
  ${mediaQueries.mobile} {
    margin-bottom: 0px;
    width: calc(100% - 83px);
  }
`;

const Setting = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 163px;
  height: 54px;
  padding: 0px;
  margin: 0px;
  border-radius: 27px;
  border: 1px solid #777777;
  font-size: 24px;
  color: #333333;
  cursor: pointer;

  img {
    width: 25px;
    height: auto;

    ${mediaQueries.tablet} {
      width: 25px;
    }

    ${mediaQueries.mobile} {
      width: 19px;
    }
  }

  ${mediaQueries.tablet} {
    font-size: 22px;
  }
  ${mediaQueries.mobile} {
    flex-direction: column;
    width: 23px;
    height: 23px;
    border-radius: 0px;
    border: 0px solid #777777;
    font-size: 12px;
  }
`;

const SettingIcon = styled.img`
  margin-right: 10px;

  @media screen and (max-width: 1023px) {
    margin-right: 0;
    margin-bottom: 5px;
  }
`;

const Text = styled.span`
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

const MobileText = styled.span`
  display: none;

  @media screen and (max-width: 1023px) {
    display: inline;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 0px;
  margin-top: 105px;

  ${mediaQueries.mobile} {
    flex-direction: column-reverse;
    margin-top: 25px;
  }
`;

const LeftContanier = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-right: 1.5px solid #cccccc;

  ${mediaQueries.mobile} {
    border-right: 0px solid #ababab;
  }
`;

const Container2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 370px;
  margin: 14px 0;

  ${mediaQueries.tablet} {
    max-width: 326px;
    margin: 37px 0;
  }

  ${mediaQueries.mobile} {
    margin: 37px 0;
    max-width: 385px;
  }
`;

const Header2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;

  ${mediaQueries.mobile} {
    margin-bottom: 25px;
  }
`;

const Title = styled.h2`
  font-size: 26px;
  font-weight: 600;
  margin: 0;

  ${mediaQueries.mobile} {
    font-size: 18px;
  font-weight: 700;

  }
`;

const ShareIcon = styled.span`
  img {
    width: 31px; /* 기본 크기 */
    height: auto;

    ${mediaQueries.tablet} {
      width: 36px;
    }
    ${mediaQueries.mobile} {
      width: 19px;
      margin-right: 9px;
    }
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 16px;

  ${mediaQueries.tablet} {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  ${mediaQueries.mobile} {
    grid-template-columns: repeat(3, 1fr);
    gap: 9px;
  }
`;

const AddItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: grey;
  background-color: #f5f5f5;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }
`;

const GridItem = styled.div`
  position: relative;
  width: 180px;
  height: 180px;
  background-color: #eee;
  border-radius: 4px;
  overflow: hidden;

  ${mediaQueries.tablet} {
    width: 158px;
    height: 158px;
  }
  ${mediaQueries.mobile} {
    width: 122.5px;
    height: 122.5px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 7vw;
    color: #fff;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 26px 0;
  background-color: #0123B4;
  color: white;
  font-size: 26px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  ${mediaQueries.tablet} {
    font-size: 22px;
  }
  ${mediaQueries.mobile} {
    font-size: 15px;
    padding: 16px 0;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: stretch;
`;

const ItemsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;

  ${mediaQueries.mobile} {
    border: 1px solid #cccccc;
    border-radius: 5px;
    width: 383px;
    padding: 10px 0;
    & > *:not(:last-child) {
      border-right: 1px solid #ccc;
    }
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 9px;
  cursor: pointer;
`;

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 53px;

  ${mediaQueries.tablet} {
    height: 46px;
  }

  ${mediaQueries.mobile} {
    height: 32px;
  }

  img {
    width: 53px;
    height: auto;

    ${mediaQueries.tablet} {
      width: 46px;
    }

    ${mediaQueries.mobile} {
      width: 32px;
    }
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${mediaQueries.tablet} {
  }

  ${mediaQueries.mobile} {
    flex-direction: row;
    margin-top: 12px;
    align-items: flex-end;
  }
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 23px;
  font-weight: 500;
  color: #333;
  margin: 24px 0 8px 0;

  ${mediaQueries.tablet} {
    font-size: 22px;
  }

  ${mediaQueries.mobile} {
    font-size: 15px;
    margin: 0 6px;
  font-weight: 700;

  }
`;

const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: #0123B4;
  font-weight: bold;

  ${mediaQueries.tablet} {
    font-size: 35px;
  }

  ${mediaQueries.mobile} {
    font-size: 15px;
  }
`;

// Define the props for the Item component
interface ItemProps {
  iconSrc: string;
  label: string;
  count: string;
  onClick?: () => void; // onClick 속성 추가
}

// Reusable Item component
const Item: React.FC<ItemProps> = ({ iconSrc, label, count, onClick }) => (
  <ItemWrapper onClick={onClick}> {/* onClick 핸들러 추가 */}
    <Icon>
      <img src={iconSrc} alt={label} />
    </Icon>
    <LabelContainer>
      <Label>{label}</Label>
      <Count>{count}</Count>
    </LabelContainer>
  </ItemWrapper>
);

// User data interface
interface UserData {
  nickName: string;
  userId: string;
  scrapThumbnails: { id: number; imgSrc: string }[];
  questionCnt: number;
  scrapbookCnt: number;
  profileImageUrl: string;
}

// Default user data
const defaultUserData: UserData = {
  nickName: "기본 닉네임",
  userId: "기본 아이디",
  scrapThumbnails: [],
  questionCnt: 0,
  scrapbookCnt: 0,
  profileImageUrl: face,
};

// React component definition
const HomeView: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userData, setUserData] = useState<UserData | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get("/mypage/viewMyHome");

        // console.log("res :", response);

        // Assuming the response data has a scrapThumbnails field as string[]
        const updatedUserData = {
          ...response.data,
          scrapThumbnails: response.data.scrapThumbnails.map(
            (src: string, index: number) => ({
              id: index,
              imgSrc: src,
            })
          ),
        };

        setUserData(updatedUserData);
      } catch (error) {
        // console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const copyToClipboard = () => {
    const url = window.location.href; // 현재 페이지의 URL을 가져옴
    navigator.clipboard.writeText(url).then(() => {
      openModal(); // URL 복사 후 모달 열기
    }).catch(err => {
      // console.error('URL 복사 실패:', err); // 에러 처리
    });
  };


  const currentUserData = {
    nickName: userData?.nickName ?? defaultUserData.nickName,
    userId: userData?.userId ?? defaultUserData.userId,
    scrapThumbnails:
      userData?.scrapThumbnails ?? defaultUserData.scrapThumbnails,
    questionCnt: userData?.questionCnt ?? defaultUserData.questionCnt,
    scrapbookCnt: userData?.scrapbookCnt ?? defaultUserData.scrapbookCnt,
    profileImageUrl:
      userData?.profileImageUrl ?? defaultUserData.profileImageUrl,
  };

  const renderGridItems = () => {
    const isMobile = window.innerWidth <= 1023;
    const gridSize = isMobile ? 3 : 4;

    const lastImageSrc =
      currentUserData.scrapThumbnails[gridSize - 1]?.imgSrc ?? "";

    // Add the items from currentUserData.scrapThumbnails to the grid
    const items = currentUserData.scrapThumbnails
      .slice(0, gridSize - 1)
      .map((property) => (
        <GridItem key={property.id}>
          <img
            src={property.imgSrc}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            onError={(e) => {
              // console.error(`Image load error: ${property.imgSrc}`);
              e.currentTarget.style.display = "none";
            }}
          />
        </GridItem>
      ));

    // Add placeholders if there are not enough items to fill the grid
    while (items.length < gridSize - 1) {
      items.push(
        <GridItem key={`placeholder-${items.length}`}>
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#eeeeee",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        </GridItem>
      );
    }

    // Add the last item with the + overlay
    items.push(
      <GridItem key="add" onClick={() => navigate("/mypage/scrab/property")}>
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#eeeeee",
            position: "relative",
            backgroundImage: lastImageSrc ? `url(${lastImageSrc})` : "none",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "clamp(1rem, 7vw, 3rem)",
              color: "#fff",
              backgroundColor: lastImageSrc
                ? "rgba(0, 0, 0, 0.12)"
                : "transparent",
              width: "100%",
              height: "100%",
            }}
          >
            +
          </div>
        </div>
      </GridItem>
    );

    return items;
  };

  return (
    <ResponsiveWrapper>
      <Home>
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          클립보드에 복사되었습니다.
        </Modal>
        <ProfileContainer>
          <BackgroundImg src={Background} />
          <Profile>
            <InfoContainer>
              <Avatar>
                <AvatarImage
                  src={currentUserData.profileImageUrl}
                  alt="avatar"
                />
              </Avatar>
              <Box2>
                <Info>
                  <NickName>{currentUserData.nickName}</NickName>
                  <Id>{currentUserData.userId}</Id>
                </Info>
                <Setting onClick={() => navigate("/mypage/settings")}>
                  <SettingIcon src={settings} alt="settings icon" />
                  <Text>설정하기</Text>
                  <MobileText>설정</MobileText>
                </Setting>
              </Box2>
            </InfoContainer>
          </Profile>
        </ProfileContainer>
        <ContentContainer>
          <LeftContanier>
            <Container2>
              <Header2>
                <Title>스크랩한 매물</Title>
                <ShareIcon
                  onClick={() =>
                    copyToClipboard()
                  }
                >
                  <img src={share} alt="share icon" />
                </ShareIcon>
              </Header2>
              <GridContainer>{renderGridItems()}</GridContainer>
              <Button onClick={() => navigate("/counseling")}>
                스크랩한 매물 상담하기
              </Button>
            </Container2>
          </LeftContanier>
          <RightContainer>
            <ItemsRow>
              <Item
                iconSrc={scrap}
                label="스크랩북"
                count={currentUserData.scrapbookCnt.toString()}
                onClick={() => navigate("/mypage/scrab/all")} // 스크랩북 클릭 시 이동
              />
              <Item
                iconSrc={q}
                label="내 질문"
                count={currentUserData.questionCnt.toString()}
                onClick={() => navigate("/qna/myqna")} // 내 질문 클릭 시 이동
              />
              {/* <Item
                iconSrc={coupon}
                label="내 쿠폰"
                count={currentUserData.couponCount.toString()}
              /> */}
            </ItemsRow>
          </RightContainer>
        </ContentContainer>
      </Home>
    </ResponsiveWrapper>
  );
};

export default HomeView;
