import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";
import PrivacyPolicyContent from "../../../components/PrivacyPolicyContent";
import TermsAgreeContent from "../../../components/TermsAgreeContent";
import Modal from "../../../components/Modal";
import checkRegion from '../../../assets/images/checkRegion.png';
interface TermsAgreementProps {
  onAllRequiredChecked: (checked: boolean) => void;
  onCheckboxChange: (checkboxes: CheckboxesState) => void;
}

export interface CheckboxesState {
  olderThen14Years: boolean;
  agreeToTermsOfUse: boolean;
  personalInformationCollectionAndUsageAgreement: boolean;
  agreementToUsePersonalInformationForMarketing: boolean;
  agreementToUseSMS: boolean;
}

const TermsAgreement: React.FC<TermsAgreementProps> = ({
  onAllRequiredChecked,
  onCheckboxChange,
}) => {
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  const openPrivacyModal = () => setIsPrivacyModalOpen(true);
  const closePrivacyModal = () => setIsPrivacyModalOpen(false);
  const openTermsModal = () => setIsTermsModalOpen(true);
  const closeTermsModal = () => setIsTermsModalOpen(false);

  const [allChecked, setAllChecked] = React.useState<boolean>(false);
  const [checkboxes, setCheckboxes] = React.useState<CheckboxesState>({
    olderThen14Years: false,
    agreeToTermsOfUse: false,
    personalInformationCollectionAndUsageAgreement: false,
    agreementToUsePersonalInformationForMarketing: false,
    agreementToUseSMS: false,
  });

  React.useEffect(() => {
    onAllRequiredChecked(
      checkboxes.olderThen14Years &&
        checkboxes.agreeToTermsOfUse &&
        checkboxes.personalInformationCollectionAndUsageAgreement
    );
  }, [checkboxes]);

  const handleAllChange = () => {
    const newChecked = !allChecked;
    setAllChecked(newChecked);
    const updatedCheckboxes = {
      olderThen14Years: newChecked,
      agreeToTermsOfUse: newChecked,
      personalInformationCollectionAndUsageAgreement: newChecked,
      agreementToUsePersonalInformationForMarketing: newChecked,
      agreementToUseSMS: newChecked,
    };
    setCheckboxes(updatedCheckboxes);
    onAllRequiredChecked(newChecked);
    onCheckboxChange(updatedCheckboxes);
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    const updatedCheckboxes = {
      ...checkboxes,
      [name]: checked,
    };
    setCheckboxes(updatedCheckboxes);
    onCheckboxChange(updatedCheckboxes);
    const areAllRequiredChecked =
      updatedCheckboxes.olderThen14Years &&
      updatedCheckboxes.agreeToTermsOfUse &&
      updatedCheckboxes.personalInformationCollectionAndUsageAgreement;
    onAllRequiredChecked(areAllRequiredChecked);
  };

  return (
    <Container>
      <Modal isOpen={isPrivacyModalOpen} onClose={closePrivacyModal}>
        <PrivacyPolicyContent />
      </Modal>
      <Modal isOpen={isTermsModalOpen} onClose={closeTermsModal}>
        <TermsAgreeContent />
      </Modal>
      <Checkbox>
        <AlignDiv>
          <StyledCheckbox
            type="checkbox"
            checked={allChecked}
            onChange={handleAllChange}
          />
          <StyledLabel className="TOP">전체 동의</StyledLabel>
        </AlignDiv>
        <InfoDiv>
          * 전체 동의에는 필수 및 선택 정보에 대한 동의가 포함되어 있으며
          개별적으로 동의를 선택하실 수 있습니다.
        </InfoDiv>
      </Checkbox>

      <StyledDiv />
      <Checkbox>
        <AlignDiv>
          <StyledCheckbox
            type="checkbox"
            name="olderThen14Years"
            checked={checkboxes.olderThen14Years}
            onChange={handleCheckboxChange}
          />
          <StyledLabel>만 14세 이상입니다</StyledLabel>
          <Span>{"(필수)"}</Span>
        </AlignDiv>
      </Checkbox>
      <Checkbox>
        <AlignDiv>
          <StyledCheckbox
            type="checkbox"
            name="agreeToTermsOfUse"
            checked={checkboxes.agreeToTermsOfUse}
            onChange={handleCheckboxChange}
          />
          <StyledLabel style={{cursor:'pointer',textDecorationColor:'#a7a7a7',textDecoration:'underline',textUnderlineOffset:'3px'}} onClick={openTermsModal}>이용약관</StyledLabel>
          <Span>{"(필수)"}</Span>
        </AlignDiv>
      </Checkbox>
      <Checkbox>
        <AlignDiv>
          <StyledCheckbox
            type="checkbox"
            name="personalInformationCollectionAndUsageAgreement"
            checked={checkboxes.personalInformationCollectionAndUsageAgreement}
            onChange={handleCheckboxChange}
          />
          <StyledLabel style={{cursor:'pointer',textDecorationColor:'#a7a7a7',textDecoration:'underline',textUnderlineOffset:'3px'}} onClick={openPrivacyModal}>
            개인정보 수집 및 이용동의
          </StyledLabel>
          <Span>{"(필수)"}</Span>
        </AlignDiv>
      </Checkbox>

      <Checkbox>
        <AlignDiv>
          <StyledCheckbox
            type="checkbox"
            name="agreementToUsePersonalInformationForMarketing"
            checked={checkboxes.agreementToUsePersonalInformationForMarketing}
            onChange={handleCheckboxChange}
          />
          <StyledLabel>개인정보 마케팅 활용 동의</StyledLabel>
          <Span className="Option">{"(선택)"}</Span>
        </AlignDiv>
      </Checkbox>
      <Checkbox>
        <AlignDiv>
          <StyledCheckbox
            type="checkbox"
            name="agreementToUseSMS"
            checked={checkboxes.agreementToUseSMS}
            onChange={handleCheckboxChange}
          />
          <StyledLabel>이벤트, 쿠폰, 특가 알림 메일 및 SMS 등 수신</StyledLabel>
          <Span className="Option">{"(선택)"}</Span>
        </AlignDiv>
      </Checkbox>
    </Container>
  );
};

export default TermsAgreement;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #cdcdcd;
  border-radius: 7px;
  padding: 10px 0;
  margin-bottom: 30px;
`;

const Checkbox = styled.div`
  padding: 10px 20px;
  @media (max-width: 1024px) {
    padding: 7px 20px;
  }
  @media (max-width: 500px) {
    padding: 5px 20px;
  }
`;

const StyledDiv = styled.div`
  background-color: rgba(187, 185, 185, 0.5);
  padding: 1px;
  margin-bottom: 10px;
`;

const InfoDiv = styled.div`
  color: #949494;
  font-size: 16px;
  width: 100%;
  align-items: center;
  padding: 10px 22.5px;
  line-height: 1.4;
  @media (max-width: 1024px) {
    font-size: 13px;
  }
  @media (max-width: 500px) {
    font-size: 10px;
  }
`;

const AlignDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCheckbox = styled.input.attrs({ type: "checkbox" })`
  width: 25px;
  height: 25px;
  appearance: none;
  background-color: white;
  border: 2px solid rgba(187, 185, 185, 0.5);
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  margin-right: 8px;
  transition: all 0.2s ease-in-out;
  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 16px;
    display: none;
  }
  &:checked {
    background-color: #0123B4;
    border-color: #0123B4;
    background-image: url(${checkRegion});
    background-size: 100% 100%;
    &:before {
      display: block;
    }
  }
  @media (max-width: 1024px) {
    width: 20px;
    height: 20px;
  }
  @media (max-width: 500px) {
    width: 17px;
    height: 17px;
  }
`;

const StyledLabel = styled.label`
  font-size: 19px;
  margin-left: 7px;
  margin-right: 5px;
  &.TOP {
    margin-left: 0;
    font-size: 23px;
    font-weight: bold;
    @media (max-width: 1024px) {
      font-size: 20px;
      font-weight: bold;
    }
    @media (max-width: 500px) {
      font-size: 15px;
      font-weight: bold;
    }
  }

  @media (max-width: 1024px) {
    font-size: 16px;
  }
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const Span = styled.span`
  color: #6c90c3;

  &.Option {
    color: black;
  }

  @media (max-width: 1024px) {
    font-size: 14px;
  }
  @media (max-width: 500px) {
    font-size: 10px;
  }
`;
