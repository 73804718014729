import React, { useState } from "react";
import styled from "styled-components";
import AdminSortOpt from "./AdminSotOpt";
import searchIcon from "../../../../assets/images/ResidentReview/SearchIcon.png";

interface PRHeaderProps {
  onSaveDraft: () => void;
  onPublishPost: () => void;
}

const PRHeader: React.FC<PRHeaderProps> = ({ onSaveDraft, onPublishPost }) => {
  return (
    <HeaderContainer>
      <HeaderContainer>
        <HeaderLeft>
         <p>후기 등록하기</p>
        </HeaderLeft>
        <HeaderLeft>
        <PostReview onClick={onSaveDraft} className="Save" style={{marginRight:'15px'}}>임시저장</PostReview>
          <PostReview onClick={onPublishPost}>올리기</PostReview>
        </HeaderLeft>
      </HeaderContainer>
    </HeaderContainer>
  );
};

export default PRHeader;

const HeaderContainer = styled.div`
    margin-top: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 60px;
  div {
    margin-bottom: 0px;
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 60px;
  p{
    font-size: 50px;
    font-weight: bold;
  }
`;

const PostReview = styled.button`
  background-color: #0123B4;
  color: white;
  font-weight: bold;
  border: none;
  width: 167px;
  height: 55px;
  border-radius: 8px;
  font-size: 25px;

  &.Save{
    background-color: white;
    border: 1px solid  #0123B4;
    color: #0123B4;
  }
`;
