import React, {useEffect, useState} from 'react'
import styled from 'styled-components'

interface ToastMessageProps {
  messageString: string
  timer: number
};


const ToastMessage = ({messageString, timer}:ToastMessageProps) => {
  const [visible, setVisible] = useState(true);

  useEffect(()=>{
    setTimeout(()=>{setVisible(false)}, timer)
  },[timer])

  return visible ? (
      <ToastWrap>{messageString}</ToastWrap>
  ):null
}

export default ToastMessage

const ToastWrap = styled.div`
    position: fixed;
    width: fit-content;
    height: fit-content;
    padding: 8px 12px;
    font-family: "Noto Sans KR";
    font-weight: bold;
    font-size: 16px;
    border: 2px solid #0123B4;
    background-color: #ffffffde;
    border-radius: 8px;
    z-index: 100;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%, 0%);
    cursor: default;
`
