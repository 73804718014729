import React, { useState, useEffect, ChangeEvent } from "react";
import styled from "styled-components";
import axios from "axios";
import LOGO from "../../../assets/images/logo.png"
import { useNavigate } from "react-router-dom";
const AdminLogin: React.FC = () => {
  const [id, setId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");
  const navigate = useNavigate();

  const handleLogin = async (
    userId: string,
    userPw: string,
    userPw2: string
  ) => {
    if (id !== "" && password !== "" && password2 !== "") {
      try {
        const response = await axios.post(
          "https://houberapp.com/auth/login/aopmanageuth",
          {
            userId,
            userPw: userPw,
            secondPw:userPw2,
          }
        );
        if(response.status === 200)
        {
          navigate('/admin/mansionManagement/')
        }
        const jwtToken = response.headers["authorization"];
        sessionStorage.setItem("Authorization", jwtToken);
        const refreshToken =  response.headers["refreshtoken"];
        sessionStorage.setItem("RefreshToken", refreshToken);
      } catch (error) {
        alert('아이디 혹은 비밀번호를 오류.')
      }
    } else alert("입력창 비어있음!");
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleLogin(id, password, password2);
  };

  return (
    <LoginContainer>
      <LogoDiv><img src={LOGO} style={{width:'100%', height:"100%"}}/></LogoDiv>
      <StyledForm onSubmit={handleSubmit}>
        <InputBox>
          <StyledLabel>
            <StyledInput
              placeholder="아이디"
              value={id}
              onChange={(e) => setId(e.target.value)}
            />
          </StyledLabel>

          <StyledDiv />

          <StyledLabel>
            <StyledInput
              type="password"
              placeholder="1차 비밀번호"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </StyledLabel>
          <StyledDiv />
          <StyledLabel>
            <StyledInput
              type="password"
              placeholder="2차 비밀번호"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            />
          </StyledLabel>
        </InputBox>

        <LoginSignUpBox>
          <LoginBTN type="submit">로그인</LoginBTN>
        </LoginSignUpBox>
      </StyledForm>
    </LoginContainer>
  );
};

export default AdminLogin;

const LogoDiv = styled.div`
  font-size: 40px;
  font-weight: 700;
  margin: 180px 0 100px;
  width: 165px;
  height: 68.7px;
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
`;

const StyledForm = styled.form`
`;

const InputBox = styled.div`
  border: 2px solid rgba(187, 185, 185, 0.5);
  width: 392px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 425px) {
    width: 323px;
    height: 100px;
  }
`;

const StyledLabel = styled.label`
  display: block;
  width: 100%;
  margin: 13px;
  @media (max-width: 425px) {
    margin: 10px;
  }
`;

const StyledInput = styled.input`
  border: none;
  padding: 5px;
  padding-left: 27px;
  font-size: 23px;
  font-weight: bold;
  box-sizing: border-box;
  outline: none;
  &::placeholder {
    opacity: 0.5;
  }
  @media (max-width: 425px) {
    font-size: 16px;
  }
`;

const StyledDiv = styled.div`
  background-color: rgba(187, 185, 185, 0.5);
  width: 392px;
  padding: 1px;
  @media (max-width: 425px) {
    width: 323px;
  }
`;

const FunCBOx = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 425px) {
    font-size: 12.3px;
  }
`;

const LoginSignUpBox = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  align-items: center;
`;

const LoginBTN = styled.button`
  width: 392px;
  height: 69px;
  background-color: #0123B4;
  border-radius: 10px;
  border: none;
  font-size: 25px;
  color: white;
  font-weight: bold;
  cursor: pointer;

  @media (max-width: 425px) {
    width: 323px;
    height: 50px;
    font-size: 16px;
  }
`;
