import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { selectedStaionType } from "./RegistrationStation";

type stationInfoType = {
  id: number;
  stationKName: string;
};

function SelectStationItem({
  stationInfo,
  index,
  selectedStationState,
  getSelectedStationState,
}: {
  stationInfo: stationInfoType;
  index: number;
  selectedStationState: selectedStaionType[];
  getSelectedStationState: (newState: selectedStaionType[]) => void;
}) {
  const [timeDropdownState, setTimeDropdownState] = useState<boolean>(false);

  const time = Array.from({ length: 30 }, (_, i) => i + 1);

  const currentStation = selectedStationState[index];
  const timeConsumed = currentStation?.timeConsumed || 1;
  const selected = currentStation?.selected || false;

  const handleTimeSelection = (selectedTime: number) => {
    const updatedStations = selectedStationState.map((station, idx) =>
      idx === index ? { ...station, timeConsumed: selectedTime } : station
    );

    getSelectedStationState(updatedStations);
    setTimeDropdownState(false);
  };

  const handleSelect = () => {
    const updatedStations = selectedStationState.map((station, idx) =>
      idx === index ? { ...station, selected: !station.selected } : station
    );

    getSelectedStationState(updatedStations);
  };

  return (
    <StationItem>
      <StationItemInfo>
        <StationItemInex>{index + 1}</StationItemInex>
        {stationInfo.stationKName}
      </StationItemInfo>
      <StationItemOption>
        <TimeConsume selected={timeDropdownState}>
          <TimeConsumeItem
            onClick={() => setTimeDropdownState(!timeDropdownState)}
          >
            도보 {timeConsumed}분
          </TimeConsumeItem>
          {timeDropdownState &&
            time.map((item, index) => (
              <Fragment>
                {selectedStationState && item !== timeConsumed && (
                  <TimeConsumeItem onClick={() => handleTimeSelection(item)}>
                    도보 {item}분
                  </TimeConsumeItem>
                )}
              </Fragment>
            ))}
        </TimeConsume>
        <SelectButton selected={selected} onClick={() => handleSelect()}>
          선택
        </SelectButton>
      </StationItemOption>
    </StationItem>
  );
}

export default SelectStationItem;

const StationItem = styled.div`
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 15px;
  min-height: 90px;
  font-size: 25px;
  border-bottom: 1px solid #e6e6e6;
  gap: 20px;
`;

const StationItemInfo = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StationItemInex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 100%;
`;

const StationItemOption = styled.div`
  position: relative;
  display: flex;
  gap: 20.5px;
  font-size: 20px;
`;

const TimeConsume = styled.div<{ selected: boolean }>`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  right: 120px;
  width: 150px;
  gap: 16px;
  padding: 17.5px 2px;
  border: 1px solid #8e8e8e;
  border-radius: 30px;
  cursor: pointer;
  max-height: 130px;
  overflow: auto;
  background-color: white;
  z-index: ${(props) => props.selected && "1000"};
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TimeConsumeItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 20px;
`;

const SelectButton = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding: 17.5px;
  border: 1px solid #8e8e8e;
  border-radius: 30px;
  cursor: pointer;
  color: ${(prpos) => prpos.selected && "white"};
  background-color: ${(prpos) => prpos.selected && "#0123B4"};
  border-color: ${(prpos) => prpos.selected && "#0123B4"};
`;
