import styled from "styled-components";
import MansionContentsItem from "./MansionContentsItem";
import { adminMansionListType } from "../AdminMansionManagement";
import { useState } from "react";
import { useAdminSelectedMansionState } from "../../../recoil/mansionManagement/adminSelelctedMansionState";
import { useAdminSelectedRoomState } from "../../../recoil/mansionManagement/adminSelectedRoomState";
import { useAdminPendingDeleteMansionState } from "../../../recoil/mansionManagement/adminPendingDeleteMansionState";
import { useAdminPendingDeleteRoomState } from "../../../recoil/mansionManagement/adminPendingDeleteRoomState";
import axiosInstance from "../../../services/axiosConfig";
import { useAdminMansionPropertyState } from "../../../recoil/mansionManagement/adminMansionPropertyStateAtom";
import { useNavigate } from "react-router-dom";

function MansionManagememtContents({
  mansionList,
}: {
  mansionList?: adminMansionListType;
}) {
  const [pagenationState, setPagenationState] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  ]);

  const navigate = useNavigate();
  const { adminMansionPropertyState, setAdminMansionPropertyState } =
    useAdminMansionPropertyState();
  const { adminSelectedMansionState } = useAdminSelectedMansionState();
  const { adminSelectedRoomState } = useAdminSelectedRoomState();
  const { adminPendingDeleteMansionState, setAdminPendingDeleteMansionState } =
    useAdminPendingDeleteMansionState();
  const { adminPendingDeleteRoomState, setAdminPendingDeleteRoomState } =
    useAdminPendingDeleteRoomState();

  const fetchDeleteMansion = () => {
    const promises = adminPendingDeleteMansionState.map((item) => {
      return axiosInstance.delete(`/admin/property/${item}`);
    });

    return Promise.all(promises);
  };

  const fetchDeleteRoom = () => {
    const promises = adminPendingDeleteRoomState.map((item) => {
      return axiosInstance.delete(`/admin/property/room/${item}`);
    });

    return Promise.all(promises);
  };

  const handlePendingDelete = () => {
    if (!adminSelectedMansionState.length && !adminSelectedRoomState.length) {
      alert("선택된 매물 혹은 호실이 없습니다.");
    } else {
      if (window.confirm("정말 삭제하시겠습니까?")) {
        setAdminPendingDeleteMansionState([...adminSelectedMansionState]);
        setAdminPendingDeleteRoomState([...adminSelectedRoomState]);
      }
    }
  };

  const handleDeleteSave = async () => {
    if (
      !adminPendingDeleteMansionState.length &&
      !adminPendingDeleteRoomState.length
    ) {
      alert("변경사항이 없습니다.");
    } else {
      if (
        window.confirm(
          "정말 저장하시겠습니까? 매물 삭제 시 매물에 포함된 방 또한 모두 삭제됩니다."
        )
      ) {
        try {
          await Promise.all([fetchDeleteMansion(), fetchDeleteRoom()]);

          alert("삭제가 완료되었습니다.");

          window.location.reload();
        } catch (error: any) {
          if (error.response && error.response.status === 403) {
            alert("삭제할 권한이 없습니다.");
          } else {
            console.error(error);
            alert("삭제 중 오류가 발생했습니다. 다시 시도해주세요.");
          }
        }
      }
    }
  };

  const handlePageChange = (page: number) => {
    setAdminMansionPropertyState({ ...adminMansionPropertyState, page: page });
  };

  const handleOnClickPrev = () => {
    const newState = pagenationState.map((item) => item - 10);
    setPagenationState(newState);
    setAdminMansionPropertyState({
      ...adminMansionPropertyState,
      page: adminMansionPropertyState.page - 10,
    });
  };

  const handleOnClickNext = () => {
    const newState = pagenationState.map((item) => item + 10);
    setPagenationState(newState);
    setAdminMansionPropertyState({
      ...adminMansionPropertyState,
      page: adminMansionPropertyState.page + 10,
    });
  };

  const handleAddRoom = () => {
    if (adminSelectedMansionState.length === 1) {
      navigate(
        `/admin/registrationRoom?mansionId=${adminSelectedMansionState[0]}&type=add`
      );
    } else if (adminSelectedMansionState.length === 0) {
      alert("선택된 매물이 없습니다.");
    } else {
      alert("2개 이상의 매물이 선택되었습니다.");
    }
  };
  return (
    <ContentsWrapper>
      <ContentsNameDiv>
        <NameBoxSequence>순서</NameBoxSequence>
        <NameBoxMansionName>매물명</NameBoxMansionName>
        <NameBoxManagementCompany>관리회사</NameBoxManagementCompany>
        <NameBoxMansionState>공개상태</NameBoxMansionState>
      </ContentsNameDiv>
      {mansionList && mansionList.content.length > 0 ? (
        mansionList.content.map((item, index) => (
          <MansionContentsItem
            mansionInfo={item}
            sequence={(adminMansionPropertyState.page - 1) * 10 + index + 1}
            key={index}
          />
        ))
      ) : (
        <NoItemAlert>조건에 해당하는 매물이 없습니다.</NoItemAlert>
      )}
      <MansionContentsBottom>
        <MansionContentsPagenation>
          {mansionList &&
            mansionList?.totalPages > 10 &&
            pagenationState[0] > 1 && (
              <PagenationItem onClick={() => handleOnClickPrev()}>
                {"<"}
              </PagenationItem>
            )}
          {pagenationState.map((item, index) => (
            <div key={index}>
              {mansionList && mansionList?.totalPages >= item && (
                <PagenationItem onClick={() => handlePageChange(item)}>
                  {item}
                </PagenationItem>
              )}
            </div>
          ))}
          {mansionList &&
            mansionList?.totalPages > 10 &&
            pagenationState[9] < mansionList.totalPages && (
              <PagenationItem onClick={() => handleOnClickNext()}>
                {">"}
              </PagenationItem>
            )}
        </MansionContentsPagenation>
        <ButtonDiv>
          <Button onClick={() => handleAddRoom()}>호실추가</Button>
          <Button onClick={() => handlePendingDelete()}>삭제</Button>
          <Button onClick={() => handleDeleteSave()}>저장</Button>
        </ButtonDiv>
      </MansionContentsBottom>
    </ContentsWrapper>
  );
}

export default MansionManagememtContents;

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: 'Noto Sans JP';
`;

const ContentsNameDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 37px;
  width: 100%;
  border-bottom: 2px solid #cdcdcd;
  font-size: 15px;
  color: #929292;
`;

const NameBoxSequence = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 55px;
  height: 100%;
  width: 77px;
`;

const NameBoxMansionName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 7px;
  height: 100%;
  width: 620px;
`;

const NameBoxManagementCompany = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 134px;
`;

const NameBoxMansionState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 164px;
`;

const MansionContentsBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 150px;
  width: 100%;
  padding-bottom: 70px;
`;

const MansionContentsPagenation = styled.div`
  margin-left: 50px;
  display: flex;
  align-items: center;
`;

const PagenationItem = styled.div<{ $selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 38px;
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => (props.$selected ? "#0123B4" : "#9BA2AE")};
  font-size: 18px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  font-size: 18px;
  font-family: "Noto Sans KR";
  width: 92px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #e6e6e6;
  color: #333333;
  cursor: pointer;
  font-weight: 500;
`;

const NoItemAlert = styled.div`
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999898;
  font-size: 30px;
`;
