import styled from "styled-components";
import { propertyOptionDataType } from "../../../recoil/mansionPage/propertyOptionsAtom";
import {
  MansionOptionsType,
  useAdminRegistrationState,
} from "../../../recoil/mansionManagement/adminRegistrationStateAtom";

function RegistrationOptions() {
  const { registrationDataState, updateRegistrationDataOptions } =
    useAdminRegistrationState();

  const OPTIONS = [
    { name: "인터넷 무료", propertyName: "freeInternet" },
    { name: "2인 이상 입주", propertyName: "morePeople" },
    { name: "복층 맨션", propertyName: "duplexMention" },
    { name: "인덕션", propertyName: "induction" },
    { name: "올 전기 맨션", propertyName: "allElectricMansion" },
    { name: "실내 세탁판", propertyName: "indoorWashingBoard" },
    { name: "비데", propertyName: "bidet" },
    { name: "유닛베스", propertyName: "unitBath" },
    { name: "냉난방", propertyName: "airConditioning" },
    { name: "24시 쓰레기 배출", propertyName: "trashDisposal24H" },
    { name: "인터폰", propertyName: "intercom" },
    { name: "자동차 주차장", propertyName: "carParking" },
    { name: "자전거 주차장", propertyName: "bicycleParking" },
    { name: "여성 전용 맨션", propertyName: "womenOnly" },
    { name: "오토록", propertyName: "autoLock" },
    { name: "외국인 가능", propertyName: "foreignerAllowed" },
    { name: "가구옵션", propertyName: "furnitureOptions" },
    { name: "펫가능", propertyName: "petsAllowed" },
    { name: "디자이너 맨션", propertyName: "designerMention" },
    { name: "역세권", propertyName: "closeToStation" },
    { name: "가스레인지", propertyName: "gasRange" },
    { name: "도시가스", propertyName: "cityGas" },
    { name: "실외 세탁판", propertyName: "outdoorWashingBoard" },
    { name: "독립형 화장실", propertyName: "standAloneToilet" },
    { name: "욕실 건조기", propertyName: "bathroomDryer" },
    { name: "수납공간", propertyName: "storageSpace" },
    { name: "쓰레기 보관소", propertyName: "garbageStorage" },
    { name: "택배박스", propertyName: "deliveryBox" },
    { name: "엘레베이터", propertyName: "elevator" },
    { name: "바이크 주차장", propertyName: "bikeParking" },
    { name: "남성 전용 맨션", propertyName: "mensOnly" },
    { name: "CCTV", propertyName: "cctv" },
    { name: "해외심사 가능", propertyName: "overseasScreeningAvailable" },
  ];

  const handleOptionsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    updateRegistrationDataOptions(name as keyof MansionOptionsType, checked);
  };

  return (
    <OptionsWrapper>
      <OptionsTitle>옵션</OptionsTitle>
      <OptionsItemWrapper>
        <InnerWrapper>
          {OPTIONS.slice(0, 10).map((item, index) => (
            <CheckBoxWrapper key={index}>
              <CheckBox
                type="checkbox"
                name={item.propertyName}
                checked={
                  registrationDataState.mansionOptionsState[
                    item.propertyName as keyof propertyOptionDataType
                  ]
                }
                onChange={handleOptionsChange}
              />
              {item.name}
            </CheckBoxWrapper>
          ))}
        </InnerWrapper>
        <InnerWrapper>
          {OPTIONS.slice(10, 20).map((item, index) => (
            <CheckBoxWrapper key={index}>
              <CheckBox
                type="checkbox"
                name={item.propertyName}
                checked={
                  registrationDataState.mansionOptionsState[
                    item.propertyName as keyof propertyOptionDataType
                  ]
                }
                onChange={handleOptionsChange}
              />
              {item.name}
            </CheckBoxWrapper>
          ))}
        </InnerWrapper>
        <InnerWrapper>
          {OPTIONS.slice(20, 30).map((item, index) => (
            <CheckBoxWrapper key={index}>
              <CheckBox
                type="checkbox"
                name={item.propertyName}
                checked={
                  registrationDataState.mansionOptionsState[
                    item.propertyName as keyof propertyOptionDataType
                  ]
                }
                onChange={handleOptionsChange}
              />
              {item.name}
            </CheckBoxWrapper>
          ))}
        </InnerWrapper>
        <InnerWrapper>
          {OPTIONS.slice(30, 33).map((item, index) => (
            <CheckBoxWrapper key={index}>
              <CheckBox
                type="checkbox"
                name={item.propertyName}
                checked={
                  registrationDataState.mansionOptionsState[
                    item.propertyName as keyof propertyOptionDataType
                  ]
                }
                onChange={handleOptionsChange}
              />
              {item.name}
            </CheckBoxWrapper>
          ))}
        </InnerWrapper>
      </OptionsItemWrapper>
    </OptionsWrapper>
  );
}

export default RegistrationOptions;

const OptionsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-top: 106px;
  width: 100%;
  height: 744px;
  padding-left: 23px;
`;

const OptionsTitle = styled.div`
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -2px;
`;

const OptionsItemWrapper = styled.div`
  display: flex;
  width: 904px;
  height: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 198px;
  gap: 25px;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  gap: 10px;
  font-weight: 500;
`;

const CheckBox = styled.input`
  display: flex;
  accent-color: #0123B4;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  cursor: pointer;
`;
