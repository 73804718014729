import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Dropdown from "./Dropdown";
import DropdownContainer from "./DropdownContainer";
import { PropertyScrap, FormData } from "./types";


// Option 타입 정의
interface Option {
  id: number;
  label: string;
}

const Form = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  padding: 0 146px;
  padding-bottom: 117px;
  border-radius: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
    padding: 0 105px;
    padding-bottom: 124px;
  }
  @media screen and (max-width: 1023px) {
    padding: 0 1px;
    border: 0px solid #ccc;
  }
`;

const P2 = styled.div`
  font-size: 23px;
  color: #0123B4;
  margin-top: 73px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 14px;
    margin-top: 0px;
  }
`;

const Label = styled.div`
  font-size: 23px;
  margin-top: 46px;

  margin-left: 2px;
 
  letter-spacing: -0.025em;
  font-weight: 500;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
    margin-top: 34px;
  }
`;

const Input = styled.input`
  flex: 1;
  padding: 12px 20px;
  margin-top: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 23px;

  
  letter-spacing: -0.025em;
  font-weight: 500;

  &::placeholder {
    color: #bbbbbb;
    font-weight: 500;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
    padding: 13px;
  }
`;

const Button = styled.button`
font-weight: 500;
  padding: 22px 110px;
  background-color: #eeeeee;
  color: #a6a6a6;
  border: none;
  border-radius: 10px;
  text-align: center;
  cursor: not-allowed;
  margin-top: 102px;
  font-size: 23px;
  margin-bottom: 120px;

  transition: background-color 0.3s, color 0.3s, cursor 0.3s;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 18px;
    padding: 11px 83px;
    margin-top: 36px;
  margin-bottom: 50px;

  }

  &:disabled {
    background-color: #eeeeee;
    color: #a6a6a6;
    cursor: not-allowed;
  }

  &:not(:disabled) {
    background-color: #0123B4;
    color: #ffffff;
    cursor: pointer;
  }
`;

const contactOptions: Option[] = [
  { id: 1, label: "일본인 보증인" },
  { id: 2, label: "일본인 단순긴급연락처" },
  { id: 3, label: "일본내 한국인 긴급연락처" },
  { id: 4, label: "없음" },
];

interface Form02Props {
  onNext: () => void;
  setFormData: (updatedData: Partial<FormData>) => void;
}

const Form02: React.FC<Form02Props> = ({ onNext,setFormData }) => {
  const [currentCountry, setCurrentCountry] = useState("");
  const [visaType, setVisaType] = useState("");
  const [japaneseSchool, setJapaneseSchool] = useState("");
  const [contactOption, setContactOption] = useState<Option | null>(null);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const isValidForm = Boolean(
      currentCountry && visaType && japaneseSchool && contactOption
    );

    setIsFormValid(isValidForm);
  }, [currentCountry, visaType, japaneseSchool, contactOption]);

  const handleNextClick = () => {
    if (isFormValid) {
      setFormData({
        currentCountry,
        visaTypeAndDuration: visaType,
        jpSchoolAdmission: japaneseSchool,
        guarantorOrContact: (() => {
          switch(contactOption?.label) {
            case "일본인 보증인":
              return "jp";
            case "일본인 단순긴급연락처":
              return "contact";
            case "일본내 한국인 긴급연락처":
              return "krInJpContact";
            case "없음":
            default:
              return "none";
          }
        })(), // 전송 시 변환
      });
      onNext();
    }
  };

  return (
    <>
      <Form>
        <P2>* 상담 연결을 위해 정확한 정보를 입력해주세요</P2>
        <Label>현재 거주국</Label>
        <Input
          type="text"
          placeholder="예시) 대한민국"
          value={currentCountry}
          onChange={(e) => setCurrentCountry(e.target.value)}
        />
        <Label>비자 종류 및 기간</Label>
        <Input
          type="text"
          placeholder="예시) 취업비자, 2년"
          value={visaType}
          onChange={(e) => setVisaType(e.target.value)}
        />
        <Label>일본어 학교 입학 여부</Label>
        <Input
          type="text"
          placeholder="예시) 오사카 메릭 일본어 학교 입학"
          value={japaneseSchool}
          onChange={(e) => setJapaneseSchool(e.target.value)}
        />
        <Label>일본인 보증인 및 긴급 연락처용 단순 지인 유무</Label>
        <DropdownContainer isForm02>
          <Dropdown
            options={contactOptions}
            selectedOption={contactOption}
            onOptionSelect={setContactOption}
            placeholder="선택해주세요"
          />
        </DropdownContainer>
      </Form>
      <Button onClick={handleNextClick} disabled={!isFormValid}>
        다음
      </Button>
    </>
  );
};

export default Form02;
