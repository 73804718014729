import styled from "styled-components";
import axiosInstance from "../../../services/axiosConfig";
import { useEffect, useState } from "react";
import MansionContentsRoomItem from "./MansionContentsRoomItem";
import { useNavigate } from "react-router-dom";
import { useAdminSelectedMansionState } from "../../../recoil/mansionManagement/adminSelelctedMansionState";
import { useAdminPendingDeleteMansionState } from "../../../recoil/mansionManagement/adminPendingDeleteMansionState";
import arrowIcon from "../../../assets/images/ResidentReview/DownIcon.png";

export type roomInfoType = {
  id: number;
  roomNum: number;
  status: "Public" | "Private" | "Temp";
};

function MansionContentsItem({
  mansionInfo,
  sequence,
}: {
  mansionInfo: {
    id: number;
    thumbnailImg: {
      id: number;
      fileType: string;
      url: string;
    };
    name: string;
    agency: string;
    status: "Public" | "Private" | "Temp";
  };
  sequence: number;
}) {
  const { adminPendingDeleteMansionState } =
    useAdminPendingDeleteMansionState();
  const navigate = useNavigate();
  const { adminSelectedMansionState, setAdminSelectedMansionState } =
    useAdminSelectedMansionState();

  const [roomDataState, setRoomDataState] = useState<roomInfoType[]>();
  const [mansionStatusState, setMansionStatusState] = useState<
    "Public" | "Private" | "Temp"
  >(mansionInfo.status);

  const fetchRooms = async () => {
    try {
      const response: { data: roomInfoType[] } = await axiosInstance.get(
        `/admin/property/${mansionInfo.id}/room`
      );

      setRoomDataState(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetcMansionState = async () => {
    if (mansionStatusState !== "Temp") {
      try {
        const response = await axiosInstance.patch("admin/property/status", {
          targetId: mansionInfo.id,
          changeStatus: mansionStatusState === "Public" ? "Private" : "Public",
        });

        if (response.status === 200) {
          setMansionStatusState(
            mansionStatusState === "Public" ? "Private" : "Public"
          );
          if (roomDataState?.length) {
            fetchRooms();
          }
        }
      } catch (error) {
        alert("공개상태 변경에 실패했습니다.");
      }
    }
  };

  const handleSelectMansion = () => {
    if (adminSelectedMansionState.includes(mansionInfo.id)) {
      const newSelectedMansion = [...adminSelectedMansionState];
      const index = newSelectedMansion.indexOf(mansionInfo.id);
      if (index > -1) {
        newSelectedMansion.splice(index, 1);
      }
      setAdminSelectedMansionState(newSelectedMansion);
    } else {
      setAdminSelectedMansionState([
        ...adminSelectedMansionState,
        mansionInfo.id,
      ]);
    }
  };

  const isPendingDeleted = adminPendingDeleteMansionState.includes(
    mansionInfo.id
  );

  useEffect(() => {
    setMansionStatusState(mansionInfo.status);
    setRoomDataState([]);
  }, [mansionInfo]);

  useEffect(() => {}, []);

  return (
    <>
      <ItemWrapper>
        <CheckboxDiv>
          <CheckBox
            type="checkBox"
            checked={adminSelectedMansionState.includes(mansionInfo.id)}
            onChange={() => handleSelectMansion()}
          ></CheckBox>
        </CheckboxDiv>
        <Sequence $pendingdeleted={isPendingDeleted}>{sequence}</Sequence>
        <MansionInfo
          $pendingdeleted={isPendingDeleted}
          onClick={() => {
            if (roomDataState?.length) {
              setRoomDataState([]);
            } else {
              fetchRooms();
            }
          }}
        >
          <MnansionThumnail
            src={mansionInfo.thumbnailImg.url}
          ></MnansionThumnail>
          {mansionInfo.name}
        </MansionInfo>
        <ManagementCompany $pendingdeleted={isPendingDeleted}>
          {mansionInfo.agency}
        </ManagementCompany>
        <MansionState>
          <MansionStateItem
            onClick={() => fetcMansionState()}
            $mansionstatus={mansionStatusState}
          >
            {mansionStatusState === "Temp"
              ? "임시저장"
              : mansionStatusState === "Private"
              ? "비공개"
              : "공개"}
          </MansionStateItem>
        </MansionState>
        <ModifyButtonDiv>
          <ModifyButton
            onClick={() =>
              navigate(
                `/admin/registration?type=modify&mansionId=${mansionInfo.id}`
              )
            }
          >
            수정
          </ModifyButton>
        </ModifyButtonDiv>
        <MoreInfoButtonDiv
          onClick={() => {
            if (roomDataState?.length) {
              setRoomDataState([]);
            } else {
              fetchRooms();
            }
          }}
        >
          <StyledArrowImage src={arrowIcon}></StyledArrowImage>
        </MoreInfoButtonDiv>
      </ItemWrapper>
      <RoomListArea>
        {roomDataState &&
          roomDataState.map((item, index) => (
            <MansionContentsRoomItem
              key={index}
              roomInfo={item}
              agency={mansionInfo.agency}
              mansionId={mansionInfo.id}
              mansionName={mansionInfo.name}
              mansionStatusState={mansionStatusState}
            />
          ))}
      </RoomListArea>
    </>
  );
}

export default MansionContentsItem;

const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 85px;
  font-size: 18px;
  border-bottom: 1px solid #e6e6e6;
`;

const CheckboxDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 55px;
  height: 100%;
`;
const CheckBox = styled.input`
  display: flex;
  accent-color: #0123B4;
  width: 26px;
  height: 26px;
`;

const Sequence = styled.div<{ $pendingdeleted: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 77px;
  color: ${(props) => props.$pendingdeleted && "#ccc"};
  font-weight: 500;
`;

const MansionInfo = styled.div<{ $pendingdeleted: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 7px;
  height: 100%;
  width: 620px;
  gap: 35px;
  font-weight: 500;
  cursor: pointer;
  color: ${(props) => props.$pendingdeleted && "#ccc"};
`;

const MnansionThumnail = styled.img`
  width: 51px;
  height: 51px;
  background-color: #dadada;
`;

const ManagementCompany = styled.div<{ $pendingdeleted: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 134px;
  font-weight: 500;
  color: ${(props) => props.$pendingdeleted && "#ccc"};
`;

const MansionState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 164px;
`;

const MansionStateItem = styled.div<{ $mansionstatus: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 106px;
  height: 28px;
  background-color: ${(props) =>
    props.$mansionstatus === "Temp"
      ? "white"
      : props.$mansionstatus === "Private"
      ? "black"
      : "#00A8EC"};
  color: ${(props) => (props.$mansionstatus === "Temp" ? "#0123B4" : "white")};
  border: ${(props) => props.$mansionstatus === "Temp" && "1px solid #99393a"};
  border-radius: 20px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
`;

const ModifyButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 101px;
  cursor: pointer;
`;

const ModifyButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 59px;
  height: 28px;
  border: 1px solid #a7a7a7;
  background-color: white;
  border-radius: 20px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
`;

const MoreInfoButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 55px;
  cursor: pointer;
`;

const RoomListArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 255px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: none;
  }
`;

const StyledArrowImage = styled.img`
  width: 15px;
  height: 8px;
`;
