import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ResponsiveWrapper from "../../components/ResponsiveWrapper";
import Form01 from "./Form01";
import Form02 from "./Form02";
import Form03 from "./Form03";
import { UserInfo, PropertyScrap, FormData } from "./types";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../services/axiosConfig";
import {Helmet} from "react-helmet";

// 스타일드 컴포넌트 정의
const Container = styled.div`
  display: flex;
  padding: 0 48px;
  padding-bottom: 115px;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 0px;
  }
  @media screen and (max-width: 1023px) {
    padding: 0 5px;
  }
`;

const Title = styled.div`
  font-size: 38px;
  margin-top: 83px;
  width: 100%;

  letter-spacing: -0.025em;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 73px;

    font-size: 37px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 43px;

    font-size: 25px;
  }
`;

const P = styled.div`
  font-weight: bold;
  font-size: 38px;
  margin-top: 10px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 36px;
  }
  @media screen and (max-width: 1023px) {
  
    font-size: 26px;
    margin-top: 5px;
  }
`;

const StepContainer = styled.div`
  display: flex;
  width: 100%;
  height: 90px;
  justify-content: space-between;
  margin: 100px 0 20px 0;
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin: 60px 0 20px 0;
  }
  @media screen and (max-width: 1023px) {
    margin: 25px 0 20px 0;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
  }
`;

const Step = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  font-size: 23px;
  color: ${(props) => (props.isActive ? "#0123B4" : "#333")};
  text-align: center;
  flex: 1;

  justify-content: space-around;
  align-items: flex-start;
  padding: 0 20px;
  @media screen and (min-width: 1280px) {
    border-left: 1px solid #ccc;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
    border-left: 1px solid #ccc;
  }
  @media screen and (max-width: 1023px) {
    font-size: 16px;
    padding: 0px;

    &:not(:first-child) {
      border-left: 1px solid #ccc;
      padding-left: 20px;
    }

    &:not(:last-child) {
      padding-right: 20px;
    }
  }
`;

const StepText = styled.div`
  font-size: 23px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }

  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

const StepTextMobile = styled.div`
  display: none;
  font-size: 16px;
  white-space: nowrap;
  margin-top: 5px;
  @media screen and (max-width: 1023px) {
    display: flex;
  }
`;

const Counseling: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [formData, setFormData] = useState<FormData>({
    userName: "",
    userEngName: "",
    phoneNum: "",
    birth: "",
    email: "",
    kakaoId: "",
    gender: "",
    currentCountry: "",
    visaTypeAndDuration: "",
    jpSchoolAdmission: "",
    guarantorOrContact: "",
    desiredMoveInDate: "",
    desiredMoveInArea: "",
    desiredRentCost: "",
    otherPreferences: "",
  });

  const navigate = useNavigate();

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axiosInstance.get("/normal/consult/userInfo");

        setUserInfo(response.data);
        setFormData((prevFormData) => ({
          ...prevFormData,
          userName: response.data.userName,
          birth: response.data.birth,
          phoneNum: response.data.phoneNum || "",
          // propertyScrapbook: response.data.propertyScrapbook,
        }));
      } catch (error) {
        // console.error("Error fetching user info:", error);
        alert("정보 불러오기 실패")
      }
    };

    fetchUserInfo();
  }, []);

  const handleFormDataChange = (updatedData: Partial<FormData>) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...updatedData,
    }));
  };

  const handleSubmit = async (step3Data: Partial<FormData>) => {
    const completeFormData = { ...formData, ...step3Data };
    // console.log(completeFormData);

    try {
      const respon = await axiosInstance.post(
        "/normal/consult/submit",
        completeFormData
      );
      navigate(-1);
    } catch (error) {
      // console.error("Error submitting form:", error);
      alert("등록 실패")
    }
  };

  return (
      <>
        <Helmet>
          <meta name="robots" content="noindex,nofollow"/>
        </Helmet>

    <ResponsiveWrapper>
      <Container>
        <Title>
          막막한 일본에서 집 찾기
          <P>하우버에 문의하세요!</P>
        </Title>
        <StepContainer>
          <Step isActive={currentStep === 1}>
            <P>01.</P>
            <StepText>인적사항 기입</StepText>
            <StepTextMobile>인적사항</StepTextMobile>
          </Step>
          <Step isActive={currentStep === 2}>
            <P>02.</P>
            <StepText>비자 및 기타 정보</StepText>
            <StepTextMobile>비자 · 기타 정보</StepTextMobile>
          </Step>
          <Step isActive={currentStep === 3}>
            <P>03.</P>
            <StepText>입주 희망사항</StepText>
            <StepTextMobile>희망사항</StepTextMobile>
          </Step>
        </StepContainer>
        {currentStep === 1 && (
          <Form01
            onNext={handleNextStep}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            setFormData={handleFormDataChange}
          />
        )}
        {currentStep === 2 && (
          <Form02 onNext={handleNextStep} setFormData={handleFormDataChange} />
        )}
        {currentStep === 3 && (
          <Form03
            onNext={handleSubmit}
            scrapProperties={userInfo?.roomScrapbook || []}
            setFormData={handleFormDataChange}
          />
        )}
      </Container>
    </ResponsiveWrapper>
        </>
  );
};

export default Counseling;
