import React, { useState, useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import floating from "../../../src/assets/images/mypage/floating.png";
import OffScrap from "../../assets/images/mypage/Shape 3 copy 2.png";
import { useScrapCount } from "../../context/ScrapCountContext";
import axiosInstance from '../../services/axiosConfig';

// 공통 스타일
const commonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #eee;
`;

const ContentContainer = styled.div`
  ${commonStyles}
  flex-direction: column;
  height: 100%;
  color: #ccc;
  font-size: 23px;
  padding: 0 20px;
  background-color: #ffffff;
  position: relative; /* 상대적 위치 설정 */
  width:100%;
  margin-bottom: 100px;

`;

const NoScrab = styled.div`
  font-size: 23px;
  font-weight: bold;
  margin-top: 150px;
  margin-bottom: 290px;
  display: flex;
  justify-content: center;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 18px;
  margin-bottom: 16px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    grid-template-columns: repeat(4, 221px);
    gap: 15px;
  }

  @media screen and (max-width: 1023px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 7px;
  }
`;

const GridItem = styled.div<{ thumbnail: string }>`
  width: 270px;
  height: 270px;
  background-color: #eee;
  border-radius: 4px;
  background-image: url("${(props) => props.thumbnail}");
  background-size: cover;
  background-position: center;
  position: relative;
  cursor: pointer;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 221px;
    height: 221px;
  }

  @media screen and (max-width: 1023px) {
    width: 122px;
    height: 122px;
  }
`;

const Button = styled.button`
  height: 54px;
  padding: 12px 35px;
  margin: 98px;
  background-color: #0123B4;
  color: white;
  font-size: 23px;
  font-weight: bold;
  border: none;
  border-radius: 27px;
  cursor: pointer;

  @media screen and (max-width: 1023px) {
    width: 100%;
    padding: 11px 20px;
    margin: 20px;
    border-radius: 5px;
    box-sizing: border-box;
  }
`;

const FloatingButtonContainer = styled.div<{ isButtonVisible: boolean }>`
  display: none;

  @media screen and (max-width: 1023px) {
    position: absolute;
    bottom: ${({ isButtonVisible }) => (isButtonVisible ? "123px" : "20px")};
    right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 1000;

    img {
      width: 45px;
      height: auto;
    }
  }
`;

const FloatingButton = styled.button`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  position: relative;
`;

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(20px);
  }
`;

const TextBox = styled.div<{ isVisible: boolean }>`
  display: flex;
  align-items: center;
  background-color: white;
  color: #0123B4;
  padding: 12px 25px 12px 12px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 25px; /* Button width + margin */
  font-size: 15px;
  font-weight: bold;
  animation: ${({ isVisible }) => (isVisible ? slideIn : slideOut)} 0.3s
    ease-out;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  white-space: nowrap;
`;

const OverlayImage = styled.img`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 25px;
  height: 25px;

  @media screen and (max-width: 1023px) {
    width: 19px;
    height: 19px;
  }
`;

const Property: React.FC = () => {
  const [items, setItems] = useState<any[]>([]);
  const [visibleItems, setVisibleItems] = useState<any[]>([]);
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(
    window.innerWidth <= 1023
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const navigate = useNavigate();
  const { setCounts } = useScrapCount();

  const itemsPerPage = 10;

  const fetchItems = async (page: number, limit: number) => {


    try {
      const response = await axiosInstance.get(
        `/mypage/rooms`,
        {
          params: { page, limit },

        }
      );

      // setCounts((prev) => ({
      //   ...prev,
      //   property: response.data.length,
      //   all: prev.room + response.data.length,
      // }));

      return response.data;
    } catch (error) {
      // console.error("Failed to fetch items:", error);
      return [];
    }
  };

  useEffect(() => {


    const fetchInitialItems = async () => {
      const fetchedItems = await fetchItems(0, itemsPerPage);
      setItems(fetchedItems);
      setVisibleItems(isSmallScreen ? fetchedItems.slice(0, 9) : fetchedItems);
      setIsButtonVisible(isSmallScreen && fetchedItems.length > 9);
    };

fetchInitialItems();

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1023);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [itemsPerPage]);

  const loadMore = () => {
    setVisibleItems(items);
    setIsButtonVisible(false);
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleTextBoxClick = () => {
    navigate("/counseling");
  };

  const handleOverlayClick = async (id: number) => {
    const confirmDelete = window.confirm("정말 삭제하시겠습니까?");

    if (confirmDelete) {
      try {
        const response = await axiosInstance.post(
          `/normal/user/scrap`,
          {
            category: 'room',
            entityId: id,
          },
        );

        // console.log("Delete response:", response.data);

        setVisibleItems((prevVisibleItems) =>
          prevVisibleItems.filter((item) => item.roomId !== id)
        );

        // 리뷰 스크랩 개수와 전체 스크랩 개수 업데이트
        setCounts(prev => ({
          ...prev,
          room: prev.room - 1,
          all: prev.all - 1
        }));
      } catch (error) {
        // console.error("Failed to delete item:", error);
      }
    }

  };

  const handleItemClick = (item: any) => {
    navigate(`/detail?roomId=${item.roomId}`);
  };

  const renderGridItems = () =>
    visibleItems.map((item) => (
      <GridItem
        key={item.scrapId}
        thumbnail={item.thumbnailUrl}
        onClick={() => handleItemClick(item)}
      >
        <OverlayImage
          src={OffScrap}
          alt="Overlay Image"
          onClick={(e) => {
            e.stopPropagation(); // 클릭 이벤트 버블링 방지
            handleOverlayClick(item.roomId);
          }}
        />
      </GridItem>
    ));

  return (
    <ContentContainer>
      {items.length === 0 ? (
        <NoScrab>저장하신 스크랩이 없습니다</NoScrab>
      ) : (
        <>
          <GridContainer>{renderGridItems()}</GridContainer>
          {isSmallScreen &&
          visibleItems.length < items.length &&
          isButtonVisible ? (
            <Button onClick={loadMore}>더보기</Button>
          ) : (
            !isSmallScreen && (
              <Button onClick={handleTextBoxClick}>
                스크랩한 매물 상담하기
              </Button>
            )
          )}
        </>
      )}
      <FloatingButtonContainer isButtonVisible={isButtonVisible}>
        <TextBox isVisible={isOpen} onClick={handleTextBoxClick}>
          스크랩한 맨션 상담
        </TextBox>
        <FloatingButton onClick={toggleOpen}>
          <img src={floating} alt="floating icon" />
        </FloatingButton>
      </FloatingButtonContainer>
    </ContentContainer>
  );
};

export default Property;
