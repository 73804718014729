import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import homeIcon from "../../src/assets/images/adminHeader/home-icon-silhouette_69524.png";
import designIcon from "../../src/assets/images/adminHeader/photo_13306572.png";
import qnaIcon from "../../src/assets/images/adminHeader/chat_10320442_2.png";
import counselingIcon from "../../src/assets/images/adminHeader/note_4280813.png";
import memberIcon from "../../src/assets/images/adminHeader/profile_12183908.png";
import reviewIcon from "../../src/assets/images/adminHeader/review_11213197.png";
import logoIcon from "../assets/images/logo.png";
import { useEffect, useState } from "react";
import axiosInstance from "../services/axiosConfig";

const StyledImage = styled.img`
  width: 100%;
`;

const AdminHeader = () => {
  const token = window.sessionStorage.getItem("Authorization");

  const navigate = useNavigate();

  const [profileImageUrl, setProfileImageUrl] = useState(memberIcon); // Default to memberIcon

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const response = await axiosInstance.get("/normal/home/userImg");
        const fetchedImageUrl = response.data;

        if (fetchedImageUrl) {
          setProfileImageUrl(fetchedImageUrl);
        }
      } catch (error) {
        // Keep the default memberIcon if fetching fails
      }
    };

    fetchProfileImage();
  }, []);

  return (
    <Con>
      <HeaderContainer>
        <Wrapper>
          <Logo onClick={() => navigate("/")}>
            <StyledImage src={logoIcon} alt="Logo" />
          </Logo>

          <Nav>
            <Box>
              <NavItem to="/admin/mansionManagement">
                <img src={homeIcon} alt="매물관리" />
                매물관리
              </NavItem>
              <NavItem to="/admin/MainDesign">
                <img src={designIcon} alt="디자인" />
                디자인
              </NavItem>
              <NavItem to="/admin/qna">
                <img src={qnaIcon} alt="Q&A" />
                Q&A
              </NavItem>
              <NavItem to="/admin/counseling">
                <img src={counselingIcon} alt="상담양식" />
                상담양식
              </NavItem>
              <NavItem to="/admin/review">
                <img src={reviewIcon} alt="입주후기" />
                입주후기
              </NavItem>
            </Box>
            <StyledNavItem
              onClick={() => {
                if (token) {
                  let logoutConfirm = window.confirm("로그아웃하시겠습니까?");
                  if (logoutConfirm) {
                    window.sessionStorage.removeItem("Authorization");
                    window.sessionStorage.removeItem("RefreshToken");
                    alert("로그아웃 되었습니다.");
                    navigate("/");
                  }
                } else {
                  navigate("/admin/login");
                }
              }}
            >
              <img
                style={{ borderRadius: "50%", margin: "0" }}
                src={profileImageUrl || memberIcon}
                alt="프로필"
              />
            </StyledNavItem>
          </Nav>
        </Wrapper>
      </HeaderContainer>
    </Con>
  );
};

export default AdminHeader;

const StyledNavItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  text-decoration: none;
  color: #333;
  font-size: 20px;

  img {
    height: 65px;
    margin-bottom: 5px;
  }

  &:hover {
    color: #007bff;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1920px;
`;

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1323px;
  margin: 0 auto; // This will center the nav
  font-family: "Noto Sans KR";
  font-weight: 500;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f8f8;
  border-bottom: 2px solid #e0e0e0;
  width: 100%;
  height: 175px;
  max-width: 1920px;
`;

const Box = styled.div`
  display: flex;
`;

const Con = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f8f8;
  border-bottom: 2px solid #e0e0e0;
  width: 100%;
  height: 175px;
`;

const Logo = styled.div`
  max-width: 250px;
  text-decoration: none;
  overflow: hidden;
  max-height: 100px;
  cursor: pointer;
  margin-right: 30px;

  @media screen and (min-width: 1280px) {
    font-size: 40px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 25px;
  }
  @media screen and (max-width: 1023px) {
    /* width: 15px; */
  }
`;

const NavItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 60px;
  text-decoration: none;
  color: #333;
  font-size: 20px;
  white-space: nowrap;

  img {
    height: 36px;
    margin-bottom: 5px;
  }

  &:hover {
    color: #007bff;
  }

  &:last-child {
    margin-right: 0;
  }
`;
