import styled from "styled-components";
import useDeviceSize from "../../../hooks/useDeviceSize";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MultiRangeSlider from "multi-range-slider-react";
import usePropertyState from "../../../hooks/usePropertyState";
import useChoiceAreaModalState from "../../../store/modal/choiceArea";
import useCheckItems from "../../../store/modal/checkItems";
import ChoiceArea from "../../../components/ChoiceArea";
import { useSelectedAreas } from "../../../recoil/mansionPage/selectedMansionAndDistrictStateAtom";
import checkRegion from '../../../assets/images/checkRegion.png';

interface MultiRangeSliderEvent {
  minValue: number;
  maxValue: number;
}
export const convertedCategoryId = (categoryid?: number) => {
  switch (categoryid) {
    case 1:
      return "임대 > 맨션";
    case 2:
      return "임대 > 주택";
    case 3:
      return "임대 > 단층맨션";
    case 4:
      return "임대 > 기타";
    case 5:
      return "매매 > 토지";
    case 6:
      return "매매 > 맨션";
    case 7:
      return "매매 > 점포";
    case 8:
      return "매매 > 주택";
    case 9:
      return "매매 > 기타";
    case 10:
      return "점포 > 사무실";
    case 11:
      return "점포 > 창고";
    case 12:
      return "점포 > 점포";
    case 13:
      return "점포 > 기타";
    case 14:
      return "하우버맨션";
  }
};

function MansionSidebarTop() {
  const [selectedButtonState, setSelectedButtonState] = useState<
    "subway" | "location" | undefined
  >();
  const {
    selectedDistrictListState,
    setSelectedDistrictListState,
    clearDistrictListState,
    selectedSubwayListState,
    setSelectedSubwayListState,
    clearSubwayListState,
  } = useSelectedAreas();

  const { filters, pagination, updateFilters } = usePropertyState();
  const { isPC } = useDeviceSize();
  const location = useLocation();

  const { open, choiceAreaOpen } = useChoiceAreaModalState();
  const { items } = useCheckItems();

  const handleRangeInput = (e: MultiRangeSliderEvent) => {
    if (filters.minPrice !== e.minValue || filters.maxPrice !== e.maxValue) {
      updateFilters({ minPrice: e.minValue });
      updateFilters({ maxPrice: e.maxValue });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    updateFilters({ [name]: checked });
  };

  const handleRoomStructureChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = e.target;

    const newRoomStructure = [...filters.roomStructureList];

    if (checked) {
      if (!newRoomStructure.includes(name)) {
        newRoomStructure.push(name);
      }
    } else {
      const index = newRoomStructure.indexOf(name);
      if (index > -1) {
        newRoomStructure.splice(index, 1);
      }
    }

    updateFilters({ roomStructureList: newRoomStructure });
  };

  useEffect(() => {
    if (items.length > 0) {
      const ids = items.map((item) => item.id);
      if (selectedButtonState === "location") {
        updateFilters({ districtIdList: [...ids] });
        setSelectedDistrictListState([...items]);
      } else if (selectedButtonState === "subway") {
        updateFilters({ subwayIdList: [...ids] });
        setSelectedSubwayListState([...items]);
      }
    }
  }, [items]);

  return (
    <>
      {choiceAreaOpen && <ChoiceArea />}
      <ContentsDiv $marginTop={isPC ? 43 : 36}>
        <CategoryDiv>
          <Title>카테고리</Title>
          {convertedCategoryId(
            pagination.detailCategoryId
              ? pagination.detailCategoryId
              : location.state
          )}
        </CategoryDiv>
      </ContentsDiv>
      <ContentsDiv $marginTop={isPC ? 42 : 32}>
        <RouteDiv>
          <ContentsTop>
            <Title>지역</Title>
            {!selectedDistrictListState ||
            selectedDistrictListState.length < 1 ? (
              <SidebarButton
                onClick={() => {
                  setSelectedButtonState("location");
                  open("location");
                }}
              >
                지역 선택
              </SidebarButton>
            ) : (
              <SidebarButton
                onClick={() => {
                  updateFilters({ districtIdList: [] });
                  clearDistrictListState();
                }}
              >
                선택 해제
              </SidebarButton>
            )}
          </ContentsTop>
          <>
            {(!selectedDistrictListState ||
              selectedDistrictListState.length < 1) &&
              "선택된 지역이 없습니다."}
          </>
          <>
            {selectedDistrictListState &&
              selectedDistrictListState?.length > 0 &&
              selectedDistrictListState[0].name}
            {selectedDistrictListState &&
              selectedDistrictListState.length > 1 &&
              ` 외 ${selectedDistrictListState.length - 1}지역`}
          </>
        </RouteDiv>
      </ContentsDiv>
      <ContentsDiv $marginTop={isPC ? 52 : 38}>
        <RouteDiv>
          <ContentsTop>
            <Title>노선</Title>
            {!selectedSubwayListState || selectedSubwayListState.length < 1 ? (
              <SidebarButton
                onClick={() => {
                  setSelectedButtonState("subway");
                  open("subway");
                }}
              >
                노선 선택
              </SidebarButton>
            ) : (
              <SidebarButton
                onClick={() => {
                  updateFilters({ subwayIdList: [] });
                  clearSubwayListState();
                }}
              >
                선택 해제
              </SidebarButton>
            )}
          </ContentsTop>
          {(!selectedSubwayListState || selectedSubwayListState.length < 1) &&
            "선택된 노선이 없습니다."}
          <>
            {selectedSubwayListState &&
              selectedSubwayListState?.length > 0 &&
              selectedSubwayListState[0].name}
            {selectedSubwayListState &&
              selectedSubwayListState.length > 1 &&
              ` 외 ${selectedSubwayListState.length - 1}개 역`}
          </>
        </RouteDiv>
      </ContentsDiv>
      <ContentsDiv $marginTop={isPC ? 69 : 58}>
        <Title>가격</Title>
        <PriceDiv>
          <PriceTop>
            <PriceDetailWrapper>
              <PriceDetail>{filters.minPrice}円</PriceDetail>
              <Bar></Bar>
              <PriceDetail>{filters.maxPrice}円</PriceDetail>
            </PriceDetailWrapper>
            <StyledSlider
              min={0}
              max={1000000}
              step={100}
              stepOnly={true}
              minValue={filters.minPrice}
              maxValue={filters.maxPrice}
              ruler={false}
              label={false}
              barInnerColor={"#0123B4"}
              barLeftColor={"#ffffff"}
              barRightColor={"#ffffff"}
              onInput={(e) => {
                handleRangeInput(e);
              }}
            />
          </PriceTop>
          <PriceBottom>
            <CheckBoxWrapper>
              <CheckBox
                type="checkbox"
                name="includeManageFee"
                checked={filters.includeManageFee}
                onChange={handleInputChange}
              />
              관리비 공익비 포함
            </CheckBoxWrapper>
            <CheckBoxWrapper>
              <CheckBox
                type="checkbox"
                name="nonDeposit"
                checked={filters.nonDeposit}
                onChange={handleInputChange}
              />
              시키킹 없음
            </CheckBoxWrapper>
            <CheckBoxWrapper>
              <CheckBox
                type="checkbox"
                name="nonGratuity"
                checked={filters.nonGratuity}
                onChange={handleInputChange}
              />
              레이킹 없음
            </CheckBoxWrapper>
          </PriceBottom>
        </PriceDiv>
      </ContentsDiv>
      <ContentsDiv $marginTop={isPC ? 56 : 40}>
        <Title>구조</Title>
        <StructureDiv>
          <SturctureLeft>
            <CheckBoxWrapper>
              <CheckBox
                type="checkbox"
                name="woodcarving"
                checked={filters.roomStructureList.includes("woodcarving")}
                onChange={handleRoomStructureChange}
              />
              목조
            </CheckBoxWrapper>
            <CheckBoxWrapper>
              <CheckBox
                type="checkbox"
                name="ironFrame"
                checked={filters.roomStructureList.includes("ironFrame")}
                onChange={handleRoomStructureChange}
              />
              철골구조
            </CheckBoxWrapper>
          </SturctureLeft>
          <SturcturerRight>
            <CheckBoxWrapper>
              <CheckBox
                type="checkbox"
                name="rebar"
                checked={filters.roomStructureList.includes("rebar")}
                onChange={handleRoomStructureChange}
              />
              철근구조
            </CheckBoxWrapper>
            <CheckBoxWrapper>
              <CheckBox
                type="checkbox"
                name="other"
                checked={filters.roomStructureList.includes("other")}
                onChange={handleRoomStructureChange}
              />
              그외
            </CheckBoxWrapper>
          </SturcturerRight>
        </StructureDiv>
      </ContentsDiv>
    </>
  );
}

export default MansionSidebarTop;

const Title = styled.div`
  font-weight: 600;
  @media screen and (min-width: 1280px) {
    font-size: 17px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 14px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 17px;
  }
`;

const ContentsDiv = styled.div<{ $marginTop: number }>`
  margin-top: ${(props) => `${props.$marginTop}px`};
  width: 100%;
  letter-spacing: -40%;
  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 13px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 16px;
  }
`;

const CategoryDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (min-width: 1280px) {
    gap: 10px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 8px;
  }
  @media screen and (max-width: 1023px) {
    gap: 10px;
  }
`;

const RouteDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (min-width: 1280px) {
    gap: 13px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 9px;
  }
  @media screen and (max-width: 1023px) {
    gap: 13px;
  }
`;
const ContentsTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SidebarButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c0c0c0;
  background-color: white;
  cursor: pointer;
  font-family: "Noto Sans KR";
  color: #333333;
  @media screen and (min-width: 1280px) {
    min-width: 97px;
    min-eight: 29px;
    font-size: 16px;
    border-radius: 14.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    min-width: 81.5px;
    min-height: 30px;
    font-size: 13px;
    border-radius: 15px;
  }
  @media screen and (max-width: 1023px) {
    min-width: 97px;
    min-height: 29px;
    font-size: 16px;
    border-radius: 14.5px;
  }
`;

const PriceDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1280px) {
    gap: 22px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 24px;
  }
  @media screen and (max-width: 1023px) {
    gap: 22px;
  }
`;

const PriceTop = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1280px) {
    margin-top: 16px;
    gap: 14px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 14px;
    gap: 11px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 16px;
    gap: 14px;
  }
`;

const PriceBottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const PriceDetailWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3px;
`;
const Bar = styled.div`
  height: 1px;
  border: 1px solid black;
  @media screen and (min-width: 1280px) {
    width: 15px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 12px;
  }
  @media screen and (max-width: 1023px) {
    width: 15px;
  }
`;
const PriceDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c0c0c0;
  background-color: white;
  @media screen and (min-width: 1280px) {
    min-width: 114px;
    min-height: 44.5px;
    font-size: 16px;
    border-radius: 22px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    min-width: 94.5px;
    min-height: 37px;
    font-size: 13px;
    border-radius: 17px;
  }
  @media screen and (max-width: 1023px) {
    min-width: 130px;
    min-height: 50px;
    font-size: 16px;
    border-radius: 22px;
  }
`;

const StructureDiv = styled.div`
  display: flex;
  @media screen and (min-width: 1280px) {
    margin-top: 21px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 15px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 21px;
  }
`;

const SturctureLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  @media screen and (min-width: 1280px) {
    width: 137px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 116px;
  }
  @media screen and (max-width: 1023px) {
    width: 137px;
  }
`;

const SturcturerRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  flex: 1;
`;

const StyledSlider = styled(MultiRangeSlider)`
  &.multi-range-slider {
    border: none;
    box-shadow: none;
  }
  &.multi-range-slider .bar-left {
    box-shadow: none;
    background-color: #ffffff;
    border: 1px solid black;
  }
  &.multi-range-slider .bar-right {
    box-shadow: none;
    background-color: #ffffff;
    border: 1px solid black;
  }
  &.multi-range-slider .thumb .caption {
    display: none;
  }
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and (min-width: 1280px) {
    font-size: 15px;
    gap: 6px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 12px;
    gap: 5px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
    gap: 6px;
  }
`;

const CheckBox = styled.input`
  appearance: none; /* Hide the default checkbox */
  display: flex;
  background-color: #fff; /* Background color when unchecked */
  border: 1px solid #757575; /* Optional border */
  border-radius: 3px;
  cursor: pointer;

  @media screen and (min-width: 1280px) {
    width: 19.5px;
    height: 19.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 20px;
    height: 20px;
  }
  @media screen and (max-width: 1023px) {
    width: 19.5px;
    height: 19.5px;
  }

  &:checked {
    background-size: cover;
    background-image: url(${checkRegion}); /* Set the checked image */
    background-repeat: no-repeat; /* Prevent repeating */
    background-position: center; /* Center the image */
    background-color: #fff; /* Ensure the background is visible */
  }
`;
