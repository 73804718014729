import React, { useState } from 'react';
import styled from 'styled-components';
import AdminSortOpt from './AdminSotOpt';
import searchIcon from '../../../../assets/images/ResidentReview/SearchIcon.png';
import { Link } from 'react-router-dom';
interface AdminRHeaderProps {
  selectedOption: string;
  onSortSelect: (option: string) => void;
  onsearchKeyword: (keywordState: string) => void;
  keyword: string;
}

const AdminRHeader: React.FC<AdminRHeaderProps> = ({
  selectedOption,
  onSortSelect,
  onsearchKeyword,
  keyword,
}) => {
  const [searchKeyword, setSearchKeyWord] = useState<string>('');

  return (
    <HeaderContainer>
      <HeaderContainer>
        <HeaderLeft>
          <p style={{ fontSize: '50px', fontWeight: 'bold'}}>입주후기</p>
          <AdminSortOpt
            selectedoption={selectedOption}
            onSelect={onSortSelect}
          />
        </HeaderLeft>
        <HeaderLeft>
          <SearchContainer>
            <SearchInput
            placeholder='제목/닉네임으로 검색'
              value={searchKeyword}
              onChange={(e) => {
                setSearchKeyWord(e.target.value);
              }}
            />
            <SearchIcon>
              <SearchBTN
                onClick={() => {
                  onsearchKeyword(searchKeyword);
                }}
              >
                <img src={searchIcon} />
              </SearchBTN>
            </SearchIcon>
          </SearchContainer>
          <Link to="/admin/postreview">
            <PostReview>+후기 등록하기</PostReview>
          </Link>
        </HeaderLeft>
      </HeaderContainer>
    </HeaderContainer>
  );
};

export default AdminRHeader;

const HeaderContainer = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 30px;
  div {
    margin-bottom: 0px;
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  gap:15px;
`;

const PostReview = styled.button`
  background-color: #0123B4;
  color: white;
  font-weight: bold;
  border: none;
  width: 269px;
  height: 55px;
  border-radius: 8px;
  font-size: 25px;
`;

const SearchBTN = styled.button`
  border: none;
  background-color: transparent;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  width: 370px;
  padding: 0px 20px;
  border: 1px solid #ccc;
  border-radius: 50px;
  outline: none;
`;

const SearchInput = styled.input`
  width: 100%;
  height: 50px;
  border: 0px solid #ccc;
  outline: none;
`;

const SearchIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 20px;
  }
`;
