import styled from "styled-components";
import useDeviceSize from "../../../hooks/useDeviceSize";
import MansionSidebarTop from "./MansionSidebarTop";
import MansionSidebarbottom from "./MansionSidebarbottom";
import { useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { mansionPropertyType } from "../Mansion";
import { propertyOptionDataStateAtom } from "../../../recoil/mansionPage/propertyOptionsAtom";
import { filterDataStateAtom } from "../../../recoil/mansionPage/filterStateAtom";
import { mansionPropertyDataStateAtom } from "../../../recoil/mansionPage/mansionPropertyState";
import mansionPageMapIcon from "../../../assets/images/mansionPage/mansionPageMapIcon.png";
import { useNavigate } from "react-router-dom";

function MansionSidebar() {
  const { isPC, isLaptop } = useDeviceSize();
  const [mansionPropertyState, setMansionPropertyState] = useRecoilState(
    mansionPropertyDataStateAtom
  );
  const propertyStateValue = useRecoilValue(propertyOptionDataStateAtom);
  const filterStateValue = useRecoilValue(filterDataStateAtom);
  const navigate = useNavigate();

  return (
    <MansionSidebarWrapper>
      <SidebarOptionsWrapper>
        <Option>조건</Option>
        <MansionSidebarTop />
        <MansionSidebarbottom />
      </SidebarOptionsWrapper>
      <SearchMansionButton
        onClick={() => {
          setMansionPropertyState({
            ...mansionPropertyState,
            ...filterStateValue,
            propertyOptionRequest: propertyStateValue,
          });
        }}
      >
        매물검색
      </SearchMansionButton>
      <ViewOnMapButton
        onClick={() => {
          setMansionPropertyState({
            ...mansionPropertyState,
            ...filterStateValue,
            propertyOptionRequest: propertyStateValue,
          });
          navigate("/map");
        }}
      >
        <StyledMapIcon src={mansionPageMapIcon} />
        지도로 보기
      </ViewOnMapButton>
    </MansionSidebarWrapper>
  );
}

export default MansionSidebar;

const MansionSidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  @media screen and (min-width: 1280px) {
    margin-top: 8px;
    height: 1005px;
    width: 339px;
    gap: 17px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 7px;
    height: 843px;
    width: 275px;
    gap: 15px;
  }
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

const SidebarOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #eeeeee;
  background-color: #f5f5f5;
  border-radius: 5px;
  overflow-y: auto;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  @media screen and (min-width: 1280px) {
    padding: 36px 23px 36px;
    height: 829px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 29px 25px 29px;
    height: 759px;
  }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

const Option = styled.div`
  font-weight: bold;
  @media screen and (min-width: 1280px) {
    font-size: 23px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 19px;
  }
`;

const SearchMansionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  color: white;
  background-color: #0123B4;
  font-family: "Noto Sans KR";
  font-weight: 600;
  cursor: pointer;
  border: none;
  @media screen and (min-width: 1280px) {
    height: 71px;
    font-size: 23px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 60px;
    font-size: 19px;
  }
`;

const ViewOnMapButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  color: #0123B4;
  border: 1px solid #0123B4;
  font-family: "Noto Sans KR";
  font-weight: 600;
  background-color: white;
  cursor: pointer;
  @media screen and (min-width: 1280px) {
    height: 71px;
    font-size: 23px;
    gap: 12px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 60px;
    font-size: 19px;
    gap: 8px;
  }
`;

const StyledMapIcon = styled.img`
  @media screen and (min-width: 1280px) {
    width: 32px;
    height: 28px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 27px;
    height: 25px;
  }
`;
