import styled from "styled-components";

function CloneHeaderBottom() {
  const NAMEANDPATH = [
    { name: "임대", path: "/" },
    { name: "매매", path: "/" },
    { name: "점포", path: "/" },
    { name: "하우버맨션", path: "/houberMansion" },
    { name: "입주후기", path: "/" },
    { name: "Q&A", path: "/" },
  ];

  return (
    <HeaderBottomWrapper>
      <HeaderBottomInner>
        <NavigationDiv>
          {NAMEANDPATH.map((item, index) => (
            <NavigtionItem key={index}>{item.name}</NavigtionItem>
          ))}
        </NavigationDiv>
        <CounselingButton>상담 양식</CounselingButton>
      </HeaderBottomInner>
    </HeaderBottomWrapper>
  );
}

export default CloneHeaderBottom;

const HeaderBottomWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #a4a4a4;
  border-top: 1px solid #a4a4a4;
  height: 78px;
`;
const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 1280px;
  padding: 0 73px;
`;

const HeaderBottomInner = styled(HeaderBox)`
  justify-content: space-between;
`;

const NavigationDiv = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 37px;
`;

const NavigtionItem = styled.div`
  font-weight: bold;
  text-decoration: none;
  color: #333333;
  font-size: 23px;
`;

const CounselingButton = styled.button`
  color: #ffffff;
  background-color: #0123B4;
  cursor: pointer;
  font-weight: bold;
  font-family: "Noto Sans KR";
  border: none;
  width: 198px;
  height: 53px;
  font-size: 23px;
  border-radius: 25px;
`;
