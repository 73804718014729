import React, { useEffect, useState } from "react";
import styled from "styled-components";
import excel from "../../../src/assets/images/mypage/excel.png";
import searchIcon from "../../../src/assets/images/mypage/free-icon-font-search-3917132.png";
import Dropdown from "../../components/Dropdown";
import DateRangeSearch from "./DateRangeSearch";
import { useNavigate } from "react-router-dom";

import axiosInstance from "../../services/axiosConfig";
import {Helmet} from "react-helmet";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1325px;
  padding: 41px;
  margin: auto;
  background-color: white;
  padding-bottom: 160px;
  margin-top: 105px;
  border-radius: 5px;
  margin-bottom: 105px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 76px;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 17px;
`;

const Title = styled.h1`
  font-size: 50px;
  font-weight: bold;
  margin-left: 20px;
  margin-right: 16px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 370px; /* 원하는 너비 설정 */

  padding: 0px 20px;
  border: 1px solid #ccc;
  border-radius: 50px; /* 둥근 모서리 */
  outline: none;
  font-size: 16px;
`;

const SearchInput = styled.input`
  width: 100%;
  height: 50px;
  border: 0px solid #ccc;
  outline: none;
  font-size: 16px;

  &::placeholder {
    color: #aaa; /* 플레이스홀더 색상 */
  }
`;

const SearchIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 18px;
  }
`;

const TableContainer = styled.div`
  flex-grow: 1; /* 가능한 공간을 차지 */
  display: grid;
  grid-template-columns: 70px 70px 1fr 150px 150px 160px;
  margin-bottom: 20px;
  font-weight: 500;
`;

const TableHeader = styled.div`
  display: contents;
`;

const TableCell = styled.div`
  padding: 20px;
  height: 80px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  white-space: nowrap;
`;

const TableHeaderCell = styled(TableCell)`
  color: #929292;
`;

const TitleTableCell = styled(TableCell)`
  justify-content: flex-start;
  cursor: pointer; /* 클릭 가능한 커서 추가 */
`;

const TableRow = styled.div`
  display: contents;
`;

const CheckBox = styled.input.attrs({ type: "checkbox" })`
  cursor: pointer;
  width: 26px;
  height: 26px;
  border-radius: 5px;
  border: 1px solid #ccc;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  position: relative;
  transition: background-color 0.3s;
  margin: 0;

  &:checked::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border: solid #0123B4;
    border-width: 0 3px 3px 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

const StatusButton = styled.button<{ confirmed: boolean }>`
  width: 108px;
  height: 28px;
  padding: 4px 8px;
  border: none;
  border-radius: 14px;
  background-color: ${(props) => (props.confirmed ? "#0123B4" : "#9A9A9A")};
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
`;

const BottomBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  width: 400px; /* 숫자 10개가 들어갈 정도의 너비를 고정 */
  justify-content: space-between;
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;

  flex: 1;
`;

const PageButton = styled.button<{ selected?: boolean }>`
  width: 30px; /* 버튼의 너비를 고정하여 간격을 일정하게 */
  padding: 8px 0;
  border: 0px solid #ccc;
  cursor: pointer;
  background-color: transparent;
  text-align: center;
  color: ${(props) => (props.selected ? "#0123B4" : "#9BA2AE")};
  font-size: 18px;
  font-weight: bold;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const ArrowButton = styled.button<{ disabled?: boolean }>`
  margin: 0 5px;
  padding: 8px 12px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => (props.disabled ? "transparent" : "inherit")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  opacity: ${(props) => (props.disabled ? 0 : 1)};
`;

const ActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ActionButton = styled.button`
  margin-left: 10px;
  padding: 9px 29px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #e6e6e6;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;

  &:hover {
    background-color: #f0f0f0;
  }

  img {
    width: 23px;
  }
`;

const Notification = styled.div<NotificationProps>`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  display: ${(props) => (props.show ? "block" : "none")};
`;

const DeletedRow = styled(TableRow)`
  background-color: #f0f0f0;
  pointer-events: none;
  color: #ccc;
`;

interface NotificationProps {
  show: boolean;
}

interface Consultation {
  id: number;
  userName: string;
  createAt: string;
  nickName: string;
  checked: boolean;
}

const ITEMS_PER_PAGE = 10;

const options = [
  { id: 1, label: "최신순" },
  { id: 2, label: "과거순" },
  { id: 3, label: "확인" },
  { id: 4, label: "미확인" },
];

const CounselingAdmin: React.FC = () => {
  const [data, setData] = useState<Consultation[]>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [notification, setNotification] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRange, setPageRange] = useState([1, 10]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortAsc, setSortAsc] = useState<boolean | null>(false);
  const [isChecked, setIsChecked] = useState<boolean | null>(null);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [pendingDeletionItems, setPendingDeletionItems] = useState<number[]>(
    []
  );

  const navigate = useNavigate();

  const handleTitleClick = (id: number) => {
    navigate(`/admin/form/${id}`);
  };

  const handleOptionSelect = (option: (typeof options)[0]) => {
    setSearchQuery("");
    setIsChecked(null);
    setSelectedOption(option);
    // 업데이트된 정렬 및 필터 옵션에 따라 상태를 업데이트
    if (option.label === "최신순") {
      setSortAsc(false);
    } else if (option.label === "과거순") {
      setSortAsc(true);
    } else if (option.label === "확인") {
      setIsChecked(true);
    } else if (option.label === "미확인") {
      setIsChecked(false);
    }
  };

  const fetchData = async (page: number) => {
    try {
      const response = await axiosInstance.get("/admin/consult/list", {
        params: {
          page: page,
          sortAsc: sortAsc,
          isChecked: isChecked,
        },
      });
      // console.log("조회결과:", response.data); // 응답 데이터 확인
      setTotalPage(response.data.totalElements);
      const formattedData = response.data.content.map((item: Consultation) => ({
        ...item,
        createAt: formatDate(item.createAt),
      }));
      setData(formattedData);
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };

  const searchData = async (page: number) => {
    try {
      const response = await axiosInstance.get("/admin/consult/search", {
        params: {
          keyword: searchQuery,
          page: page,
        },
      });
      // console.log("검색결과:", response.data); // 응답 데이터 확인
      setTotalPage(response.data.totalElements);
      const formattedData = response.data.content.map((item: Consultation) => ({
        ...item,
        createAt: formatDate(item.createAt),
      }));
      setData(formattedData);
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };

  const fetchByDate = async (
    startDate: string,
    endDate: string,
    page: number
  ) => {
    try {
      const response = await axiosInstance.get("/admin/consult/list/date", {
        params: {
          startDate,
          endDate,
          page,
        },
      });
      // console.log("날짜조회결과:", response.data); // 응답 데이터 확인
      setTotalPage(response.data.totalElements);
      const formattedData = response.data.content.map((item: Consultation) => ({
        ...item,
        createAt: formatDate(item.createAt),
      }));
      setData(formattedData);
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (searchQuery.trim() === "") {
      fetchData(currentPage);
    } else {
      searchData(currentPage);
    }
  }, [currentPage, sortAsc, isChecked]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = String(date.getFullYear()).slice(2);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSelectItem = (id: number) => {
    setSelectedItems((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const downloadExcel = async () => {
    if (selectedItems.length > 0) {
      try {
        const requestData = {
          consultIds: selectedItems,
        };

        // console.log(requestData);
        const response = await axiosInstance.post(
          "/admin/consult/list/excel",
          requestData,
          { responseType: "blob" }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "consultations.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      } catch (error) {
        // console.error("Error downloading excel:", error);
        alert("다운로드 실패");
      }
    } else {
      alert("선택된 상담양식이 없습니다.");
    }
  };

  const handleDelete = () => {
    if (selectedItems.length === 0) {
      alert("삭제할 항목을 선택하세요.");
      return;
    }
    const confirmDelete = window.confirm("선택한 항목을 삭제하시겠습니까?");
    if (confirmDelete) {
      setPendingDeletionItems([...pendingDeletionItems, ...selectedItems]);
      setSelectedItems([]); // 선택된 항목 초기화
    }
  };

  // const handleSave = async () => {
  //   try {
  //     // 서버에 삭제 요청 보내기
  //     await axiosInstance.delete("/admin/consult/list/delete", {
  //       data: { consultIds: pendingDeletionItems },
  //     });

  //     // 서버 요청이 성공하면, 프론트엔드 상태 업데이트
  //     const updatedData = data.filter(
  //       (item) => !pendingDeletionItems.includes(item.id)
  //     );
  //     setData(updatedData);
  //     setPendingDeletionItems([]);

  //     // 변경 사항 저장 알림 표시
  //     setNotification(true);
  //     setTimeout(() => setNotification(false), 2000);
  //   } catch (error) {
  //     // console.error("Error saving data:", error);
  //     alert("삭제하는 중 오류가 발생했습니다.");
  //   }
  // };

  const handleSave = async () => {
    try {
      // 서버에 삭제 요청 보내기
      await axiosInstance.delete("/admin/consult/list/delete", {
        data: { consultIds: pendingDeletionItems },
      });

      // 서버 요청이 성공하면, 프론트엔드 상태 업데이트
      const updatedData = data.filter(
        (item) => !pendingDeletionItems.includes(item.id)
      );
      setData(updatedData);
      setPendingDeletionItems([]);

      // 변경 사항 저장 알림 표시
      setNotification(true);
      setTimeout(() => setNotification(false), 2000);
    } catch (error:any) {
      if (error.response && error.response.status === 403) {
        // 403 에러 처리
        alert("상위 권한이 필요합니다.");
      } else {
        // 그 외의 에러 처리
        alert("삭제하는 중 오류가 발생했습니다.");
      }
    }
  };




  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePreviousPageRange = () => {
    if (pageRange[0] > 1) {
      setPageRange([pageRange[0] - 10, pageRange[1] - 10]);
    }
  };

  const handleNextPageRange = () => {
    if (pageRange[1] < totalPages) {
      setPageRange([pageRange[0] + 10, pageRange[1] + 10]);
    }
  };

  const totalPages = Math.ceil(totalPage / ITEMS_PER_PAGE);

  return (
      <>
        <Helmet>
          <meta name="robots" content="noindex,nofollow"/>
        </Helmet>
    <Container>
      <Header>
        <TitleContainer>
          <Title>상담양식</Title>
          <DateRangeSearch fetchByDate={fetchByDate} />
        </TitleContainer>
        <SearchContainer>
          <SearchInput
            placeholder="이름 또는 닉네임"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                searchData(1);
              }
            }}
          />
          <SearchIcon onClick={() => searchData(1)}>
            <img src={searchIcon} />
          </SearchIcon>
        </SearchContainer>
      </Header>
      <DropdownContainer>
        <Dropdown
          selectedOption={selectedOption}
          onOptionSelect={handleOptionSelect}
        />
      </DropdownContainer>
      <TableContainer>
        <TableHeader>
          <TableHeaderCell></TableHeaderCell>
          <TableHeaderCell>순서</TableHeaderCell>
          <TableHeaderCell>제목</TableHeaderCell>
          <TableHeaderCell>양식작성일</TableHeaderCell>
          <TableHeaderCell>닉네임</TableHeaderCell>
          <TableHeaderCell>확인여부</TableHeaderCell>
        </TableHeader>

        {data.map((item, index) => (
          <React.Fragment key={item.id}>
            {pendingDeletionItems.includes(item.id) ? (
              <DeletedRow>
                <TableCell>
                  <CheckBox
                    checked={selectedItems.includes(item.id)}
                    onChange={() => handleSelectItem(item.id)}
                  />
                </TableCell>
                <TableCell>
                  {(currentPage - 1) * ITEMS_PER_PAGE + index + 1}
                </TableCell>
                <TitleTableCell onClick={() => handleTitleClick(item.id)}>
                  {item.userName} 님의 상담양식이 도착했습니다
                </TitleTableCell>
                <TableCell>{item.createAt}</TableCell>
                <TableCell>{item.nickName}</TableCell>
                <TableCell>
                  <StatusButton confirmed={item.checked}>
                    {item.checked ? "확인" : "미확인"}
                  </StatusButton>
                </TableCell>
              </DeletedRow>
            ) : (
              <TableRow>
                <TableCell>
                  <CheckBox
                    checked={selectedItems.includes(item.id)}
                    onChange={() => handleSelectItem(item.id)}
                  />
                </TableCell>
                <TableCell>
                  {(currentPage - 1) * ITEMS_PER_PAGE + index + 1}
                </TableCell>
                <TitleTableCell onClick={() => handleTitleClick(item.id)}>
                  {item.userName} 님의 상담양식이 도착했습니다
                </TitleTableCell>
                <TableCell>{item.createAt}</TableCell>
                <TableCell>{item.nickName}</TableCell>
                <TableCell>
                  <StatusButton confirmed={item.checked}>
                    {item.checked ? "확인" : "미확인"}
                  </StatusButton>
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        ))}
      </TableContainer>

      <BottomBar>
        <PaginationContainer>
          <ArrowButton
            onClick={handlePreviousPageRange}
            disabled={pageRange[0] === 1}
          >
            &lt;
          </ArrowButton>
          <Pagination>
            {Array.from(
              { length: Math.min(10, totalPages - pageRange[0] + 1) },
              (_, index) => (
                <PageButton
                  key={index}
                  selected={currentPage === pageRange[0] + index}
                  onClick={() => handlePageChange(pageRange[0] + index)}
                >
                  {pageRange[0] + index}
                </PageButton>
              )
            )}
          </Pagination>
          <ArrowButton
            onClick={handleNextPageRange}
            disabled={pageRange[1] >= totalPages}
          >
            &gt;
          </ArrowButton>
        </PaginationContainer>
        <ActionButtonContainer>
          <ActionButton onClick={downloadExcel}>
            <img src={excel} />
            엑셀 다운로드
          </ActionButton>
          <ActionButton onClick={handleDelete}>삭제</ActionButton>
          <ActionButton onClick={handleSave}>저장</ActionButton>
        </ActionButtonContainer>
      </BottomBar>
      <Notification show={notification}>
        변경사항이 저장 되었습니다.
      </Notification>
    </Container>
        </>
  );
};

export default CounselingAdmin;
