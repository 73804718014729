import { useEffect, useState } from "react";
import styled from "styled-components";
import searchIcon from "../../../assets/images/header/search-black.png";
import axiosInstance from "../../../services/axiosConfig";
import SelectStationItem from "./SelectStationItem";
import xIcon from "../../../assets/images/mainPage/x-icon.png";
import { useAdminRegistrationState } from "../../../recoil/mansionManagement/adminRegistrationStateAtom";

type stationInfoType = {
  id: number;
  stationKName: string;
};

export type selectedStaionType = {
  stationId: number;
  stationKName: string;
  timeConsumed: number;
  selected: boolean;
};

function RegistrationStation() {
  const { registrationDataState, setRegistrationDataState } =
    useAdminRegistrationState();

  const [selectStationAreaState, setSelectStationAreaState] =
    useState<boolean>(false);

  const [selectStationInputState, setSelectStationInputState] =
    useState<string>("");

  const [searchStationResultState, setSearchStationResultState] =
    useState<stationInfoType[]>();

  const [selectedStationState, setSelectedStationState] = useState<
    selectedStaionType[]
  >([]);

  const getSelectedStationState = (newState: selectedStaionType[]) => {
    setSelectedStationState(newState);
  };

  const fetchSearchStation = async () => {
    try {
      const response = await axiosInstance.get(`/map/search/station`, {
        params: {
          key: selectStationInputState,
        },
      });

      setSearchStationResultState(response.data);
    } catch (error) {
      alert("역 검색에 실패했습니다.");
    }
  };

  const handleCompleteButtonClick = () => {
    const existingStationIds = new Set(
      registrationDataState.closeStation.map((station) => station.stationId)
    );

    const selectedStations = selectedStationState
      .filter((station) => station.selected)
      .filter((station) => !existingStationIds.has(station.stationId))
      .map((station) => ({
        stationId: station.stationId,
        timeConsumed: station.timeConsumed,
        stationKName: station.stationKName,
      }));

    setRegistrationDataState({
      ...registrationDataState,
      closeStation: [
        ...registrationDataState.closeStation,
        ...selectedStations,
      ],
    });
    setSelectStationAreaState(false);
    setSelectStationInputState("");
    setSearchStationResultState([]);
  };

  const handleDeleteStation = (index: number) => {
    const updatedStations = registrationDataState.closeStation.filter(
      (station, stationIndex) => stationIndex !== index
    );
    setRegistrationDataState({
      ...registrationDataState,
      closeStation: updatedStations,
    });
  };

  useEffect(() => {
    if (searchStationResultState) {
      const newSelectedStations = searchStationResultState.map((station) => ({
        stationId: station.id,
        timeConsumed: 1,
        selected: false,
        stationKName: station.stationKName,
      }));
      setSelectedStationState(newSelectedStations);
    }
  }, [searchStationResultState]);

  return (
    <StationWrapper>
      {selectStationAreaState ? (
        <SelectStationArea>
          <CloseButton onClick={() => setSelectStationAreaState(false)}>
            X
          </CloseButton>
          <SearchStationTop>
            <SearchStationInputDiv>
              <SearchStationInput
                placeholder="역 이름을 입력해주세요"
                value={selectStationInputState}
                onChange={(e) => setSelectStationInputState(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    fetchSearchStation();
                  }
                }}
              ></SearchStationInput>
              <SearchIcon
                src={searchIcon}
                onClick={() => fetchSearchStation()}
              />
            </SearchStationInputDiv>
          </SearchStationTop>
          <StationItemArea>
            {searchStationResultState &&
              searchStationResultState.map((item, index) => (
                <SelectStationItem
                  stationInfo={item}
                  index={index}
                  selectedStationState={selectedStationState}
                  getSelectedStationState={getSelectedStationState}
                ></SelectStationItem>
              ))}
          </StationItemArea>
          <SearchStationBottom>
            <CompleteButton onClick={handleCompleteButtonClick}>
              등록하기
            </CompleteButton>
          </SearchStationBottom>
        </SelectStationArea>
      ) : (
        <>
          <StationInputArea>
            <StationTitle>역 선택</StationTitle>
            <StationInputWrapper>
              맨션에서 가까운 역을 선택해주세요
              <ChooseStation onClick={() => setSelectStationAreaState(true)}>
                + 역 선택
              </ChooseStation>
            </StationInputWrapper>
          </StationInputArea>

          <StationInputArea style={{ marginTop: 20 }}>
            <StationTitle></StationTitle>
            <StationInputWrapper>
              <SelectedItemDiv>
                {registrationDataState.closeStation.map((item, index) => (
                  <SelectedItem key={index}>
                    {item.stationKName} 도보{item.timeConsumed}분
                    <DeletSelectButton
                      src={xIcon}
                      onClick={() => handleDeleteStation(index)}
                    />
                  </SelectedItem>
                ))}
              </SelectedItemDiv>
            </StationInputWrapper>
          </StationInputArea>
        </>
      )}
    </StationWrapper>
  );
}

export default RegistrationStation;

const StationWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-left: 23px;
  border-bottom: 2px solid #e6e6e6;
  padding: 100px 0;
`;

const StationTitle = styled.div`
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -2px;
`;

const StationInputArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StationInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 904px;
  height: 204px;
  border-radius: 5px;
  background-color: #f7f8f9;
  border: 1px dotted #a7a7a7;
  font-size: 15px;
  gap: 15px;
  font-weight: 500;
`;

const ChooseStation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 131px;
  height: 28px;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
`;

const SelectStationArea = styled.div`
  position: relative;
  width: 100%;
  height: 620px;
  overflow: auto;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  padding: 0 50px;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 9.5px;
  right: 32px;
  color: #c1c1c1;
  font-size: 30px;
  cursor: pointer;
`;

const SearchStationTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
  width: 100%;
`;

const SearchStationInputDiv = styled.div`
  display: flex;
  align-items: center;
  width: 371px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid #cdcdcd;
  padding: 0 13px;
  gap: 10px;
`;

const SearchStationInput = styled.input`
  display: flex;
  flex: 1;
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 50px;
  font-family: "Noto Sans KR";
  font-weight: 500;
`;

const SearchIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const StationItemArea = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  height: 353px;
  &::-webkit-scrollbar {
    width: 4px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: none;
  }
`;

const SearchStationBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
  width: 100%;
`;

const CompleteButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 269px;
  height: 55px;
  font-size: 25px;
  font-weight: 600;
  background-color: #0123B4;
  color: white;
  border-radius: 10px;
  cursor: pointer;
`;

const SelectedItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: auto;
  padding: 30px 0;
  &::-webkit-scrollbar {
    width: 4px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: none;
  }
`;

const SelectedItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
`;

const DeletSelectButton = styled.img`
  width: 10px;
  height: 10px;
  cursor: pointer;
`;
