import styled from "styled-components";
import useDeviceSize from "../../../hooks/useDeviceSize";
import { useNavigate } from "react-router-dom";

function MoreInfoButton({
  moreInfoText,
  linkTo,
}: {
  moreInfoText?: string;
  linkTo: string;
}) {
  const navigate = useNavigate();
  const { isMobile } = useDeviceSize();
  return isMobile ? (
    <MobileButton
      onClick={() => {
        navigate(linkTo);
      }}
    >
      {moreInfoText}
      {" >"}
    </MobileButton>
  ) : (
    <Button
      onClick={() => {
        navigate(linkTo);
      }}
    >
      더보기
    </Button>
  );
}

export default MoreInfoButton;

const Button = styled.div`
  color: #0123B4;
  font-weight: bold;
  cursor: pointer;
  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 15px;
  }
`;

const MobileButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border: 1px solid #333333;
  font-family: "Noto Sans KR";
  font-size: 15px;
  font-weight: bold;
  color: #383838;
  background-color: #ffffff;
  // border-ratius를 대략적인 값으로 잡아놓음 나중에 정확한 값 찾아오기
  border-radius: 7px;
`;
