import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { PropertyScrap, FormData } from "./types";

const Form = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  padding: 0 146px;
  padding-bottom: 96px;
  border-radius: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
    padding: 0 105px;
    padding-bottom: 105px;
  }
  @media screen and (max-width: 1023px) {
    padding: 0 1px;
    border: 0px solid #ccc;
  }
`;

const P2 = styled.div`
  font-size: 23px;
  color: #0123B4;
  margin-top: 73px;


  letter-spacing: -0.025em;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 14px;
    margin-top: 0px;
  }
`;

const Label = styled.div`
  font-size: 23px;
  margin-top: 46px;

  margin-left: 2px;
 
  letter-spacing: -0.025em;
  font-weight: 500;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
    margin-top: 34px;
  }
`;

const Input = styled.input`
  flex: 1;
  padding: 12px 20px;
  margin-top: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 23px;

  &::placeholder {
    color: #bbbbbb; /* 원하는 색상으로 변경 */
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
    padding: 13px;
  }
`;
const TextArea = styled.textarea`
  flex: 1;
  padding: 18px;
  margin-top: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 23px;
  height: auto;
  min-height: 162px;
  max-height: 192px;
  resize: none;

  font-family: 'Noto Sans', sans-serif;
  
  &::placeholder {
    color: #bbbbbb; /* 원하는 색상으로 변경 */
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
    padding: 13px;
  }
`;

const Button = styled.button`      
font-weight: 500;
  padding: 22px 110px;
  background-color: #eeeeee;
  color: #a6a6a6;
  border: none;
  border-radius: 10px;
  text-align: center;
  cursor: not-allowed;
  margin-top: 102px;
  font-size: 23px;
  margin-bottom: 120px;

  transition: background-color 0.3s, color 0.3s, cursor 0.3s;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 18px;
    padding: 11px 83px;
    margin-top: 36px;
  margin-bottom: 50px;

  }

  &:disabled {
    background-color: #eeeeee;
    color: #a6a6a6;
    cursor: not-allowed;
  }

  &:not(:disabled) {
    background-color: #0123B4;
    color: #ffffff;
    cursor: pointer;
  }
`;

const ScrapList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px;
  margin-top: 10px;
  overflow-y: auto;
  min-height: 162px;
  max-height: 192px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 23px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 18px;
    padding: 13px;

    margin-top: 36px;
  }

  a {
    color: #007bff;

    margin-bottom: 5px;
  }

  a:hover {
    text-decoration: underline;
  }
`;

interface Form03Props {
  onNext: (formData: any) => void;
  scrapProperties: PropertyScrap[];
  setFormData: (updatedData: Partial<FormData>) => void;
}

const Form03: React.FC<Form03Props> = ({
  onNext,
  scrapProperties,
  setFormData,
}) => {
  const [desiredMoveInDate, setDesiredMoveInDate] = useState("");
  const [desiredArea, setDesiredArea] = useState("");
  const [desiredRent, setDesiredRent] = useState("");
  const [otherRequirements, setOtherRequirements] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const isValidForm = Boolean(
      desiredMoveInDate && desiredArea && desiredRent && otherRequirements
    );

    setIsFormValid(isValidForm);
  }, [desiredMoveInDate, desiredArea, desiredRent, otherRequirements]);

  const handleNextClick = () => {
    if (isFormValid) {
      setFormData({
        desiredMoveInDate,
        desiredMoveInArea: desiredArea,
        desiredRentCost: desiredRent,
        otherPreferences: otherRequirements,
      });
      onNext({
        desiredMoveInDate,
        desiredMoveInArea: desiredArea,
        desiredRentCost: desiredRent,
        otherPreferences: otherRequirements,
      });
    }
  };

  return (
    <>
      <Form>
        <P2>* 상담 연결을 위해 정확한 정보를 입력해주세요</P2>
        <Label>입주 희망일</Label>
        <Input
          type="text"
          placeholder="예시) 4월 15일 이후 희망"
          value={desiredMoveInDate}
          onChange={(e) => setDesiredMoveInDate(e.target.value)}
        />
        <Label>입주 희망 지역</Label>
        <Input
          type="text"
          placeholder="예시) 오사카 이쿠노구, 추오구"
          value={desiredArea}
          onChange={(e) => setDesiredArea(e.target.value)}
        />
        <Label>희망 야칭 및 초기비용</Label>
        <Input
          type="text"
          placeholder="예시) 7만엔, 20만엔 이하"
          value={desiredRent}
          onChange={(e) => setDesiredRent(e.target.value)}
        />
        <Label>기타 희망사항</Label>
        <TextArea
          placeholder="예시) 어학원까지 환승 없이 30분 이내, 치안 좋은 동네"
          value={otherRequirements}
          onChange={(e) => setOtherRequirements(e.target.value)}
        />
        <Label>스크랩한 매물</Label>
        {scrapProperties.length > 0 ? (
          <ScrapList>
          {scrapProperties.map((property) => (
            <a
              href={`/detail?roomId=${property.roomId}`}
              key={property.roomId}
              target="_blank"
              rel="noopener noreferrer"
            >
              {property.propertyName}
            </a>
          ))}
        </ScrapList>
        ) : (
          <TextArea
            placeholder="스크랩한 매물이 자동으로 등록됩니다"
            value=""
            readOnly
          />
        )}
      </Form>
      <Button onClick={handleNextClick} disabled={!isFormValid}>
        제출
      </Button>
    </>
  );
};

export default Form03;
