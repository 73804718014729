import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../services/axiosConfig";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ResponsiveWrapper from "../../components/ResponsiveWrapper";
import face from "../../../src/assets/images/mypage/face.png";
import camera from "../../../src/assets/images/mypage/카메라 아이콘.png";

const mediaQueries = {
  tablet: "@media screen and (max-width: 1279px) and (min-width: 1024px)",
  mobile: "@media screen and (max-width: 1023px)",
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  width: 100%;
  font-size: 16px;
  margin: 55px 0px;
  text-align: left;
  color: #777777;
  ${mediaQueries.tablet} {
    font-size: 13px;
    margin: 46px 0px;
  }
  ${mediaQueries.mobile} {
    display: none;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 462px;
  height: 100%;

  ${mediaQueries.mobile} {
    width: 325px;
    padding: 0 0px;
    margin-top: 64px;
  }
`;

const ProfileSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 66px;
`;

const Avatar = styled.div`
  width: 165px;
  height: 165px;
  border-radius: 50%;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 32px;
  /* overflow: hidden; */
  ${mediaQueries.tablet} {
    width: 134px;
    height: 134px;
    margin-right: 24px;
  }
  ${mediaQueries.mobile} {
    width: 61px;
    height: 61px;
    margin-right: 21px;
  }
`;

const ChangeImg = styled.img`
  position: absolute;
  width: 48px;
  height: 48px;
  bottom: 8%;
  right: -5%;
  cursor: pointer;
  margin-bottom: 10px;

  ${mediaQueries.tablet} {
    width: 40px;
    height: 40px;
  }
  ${mediaQueries.mobile} {
    width: 18px;
    height: 18px;
  }
`;

const DelProfile = styled.div`
  margin-top: 10px;
  cursor: pointer;
  color: #777777;
  font-size: 16px;
  text-decoration-line: underline;
  white-space: nowrap;

  ${mediaQueries.tablet} {
    font-size: 15px;
  }
  ${mediaQueries.mobile} {
    font-size: 12px;
  }
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover; /* 이미지 비율을 유지하며 컨테이너에 맞게 조절 */
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
`;

const Nickname = styled.div`
  font-size: 38px;
  font-weight: bold;
  margin-bottom: 8px;

  ${mediaQueries.tablet} {
    font-size: 34px;
  }
  ${mediaQueries.mobile} {
    font-size: 20px;
  }
`;

const UserId = styled.div`
  font-size: 23px;
  color: #777777;

  ${mediaQueries.tablet} {
    font-size: 22px;
  }
  ${mediaQueries.mobile} {
    font-size: 12px;
  }
`;

const FormSection = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

const FormGroup = styled.div`
  margin-bottom: 46px;
`;

const Label = styled.div`
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 14px;
  color: #333333;
  margin-left: 2px;
letter-spacing: -0.040em;
  ${mediaQueries.tablet} {
    font-size: 22px;
  }
  ${mediaQueries.mobile} {
    font-size: 15px;
  font-weight: 500;

  }
`;

const P = styled.div`
  font-size: 16px;
  margin-bottom: 14px;
  color: #777777;
  margin-left: 3px;
  letter-spacing: -0.040em;

  ${mediaQueries.tablet} {
    font-size: 15px;
  }
  ${mediaQueries.mobile} {
    font-size: 11px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1.5px solid #cdcdcd;
  border-radius: 10px;
  font-size: 19px;
  color: #bbbbbb;
  padding: 22px;

  ${mediaQueries.tablet} {
    padding: 22px;
  }
  ${mediaQueries.mobile} {
    padding: 12px;
  }
`;

const Input = styled.input`
  font-size: 19px;
  color: #333333;
  border: none;
  flex: 1;
  outline: none;
  &::placeholder {
    color: #bbbbbb;
  }
  font-weight:500;

  ${mediaQueries.tablet} {
    font-size: 19px;
  }
  ${mediaQueries.mobile} {
    font-size: 12px;
  font-weight:400;

  }
`;

const Email = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1.5px solid #cdcdcd;
  border-radius: 10px;
  font-size: 19px;
  color: #333333;
  padding: 22px;

  font-weight:500;

  ${mediaQueries.tablet} {
    font-size: 19px;
  }
  ${mediaQueries.mobile} {
    font-size: 14px;
    padding: 12px;
    font-weight:400;
  }
`;

const WithdrawalLink = styled.div`
  width: 100%;
  text-align: left;
  margin: 85px 0 102px 0;
  color: #777777;
  font-size: 16px;
  cursor: pointer;
  ${mediaQueries.tablet} {
    font-size: 13px;
    margin: 67px 0 83px 0;
  }
  ${mediaQueries.mobile} {
    font-size: 14px;
    margin: 55px 0 72px 0;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 23px;
  background-color: #0123B4;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 23px;
  font-weight: 600;
  cursor: pointer;

  ${mediaQueries.tablet} {
    font-size: 22px;
    padding: 16px;
  }
  ${mediaQueries.mobile} {
    font-size: 15px;
    padding: 10px;
  font-weight: 700;

  }
`;

const ErrorMessage = styled.div`
  font-size: 14px;
  color: red;
  margin-top: 8px;
`;

const MobileHeader = styled.div`
  display: none;

  @media screen and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 20px;
  }
`;

const Header1 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 37px 0 21px 0;
  font-size: 20px;
  width: 100%;
  text-align: left;
`;

const Header2 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 19px 0;
  font-size: 14px;
  width: 100%;
  text-align: left;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

interface ToggleProps {
  active: boolean;
}

const ToggleButtonContainer = styled.div<ToggleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.active ? "#0123B4" : "transparent")};
  border: 2px solid #0123B4;
  border-radius: 15px;
  padding: 5px;
  cursor: pointer;
  position: relative;
  width: 57px;
  height: 27px;
  ${mediaQueries.tablet} {
  }
  ${mediaQueries.mobile} {
    width: 35px;
    height: 17px;


  }
`;

const ToggleText = styled.div<ToggleProps>`
  display: flex;
  justify-content: center;
  color: ${(props) => (props.active ? "white" : "#99393a")};
  font-size: 16px;
  margin-bottom: 2px;
  margin-left: ${(props) => (props.active ? "-15px" : "17px")};

  ${mediaQueries.tablet} {
  }
  ${mediaQueries.mobile} {
    font-size: 8px;
    margin-left: ${(props) => (props.active ? "-9px" : "10px")};
  margin-bottom: 0px;

  }
`;

const ToggleCircle = styled.div<ToggleProps>`
  width: 14.5px;
  height: 14.5px;
  background-color: ${(props) => (props.active ? "white" : "#b0b0b0")};
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: ${(props) => (props.active ? "calc(100% - 19.5px)" : "4px")};
  transform: translateY(-50%);
  transition: left 0.3s ease;

  ${mediaQueries.tablet} {
  }
  ${mediaQueries.mobile} {
    left: ${(props) => (props.active ? "calc(100% - 10.5px)" : "2px")};
    width: 9px;
    height: 9px;
  }
`;

const Line = styled.div`
  display: none;

  ${mediaQueries.mobile} {
    display: flex;
    width: 100%;
    height: 1px;
    background-color: #ddd;
  }
`;

interface ToggleButtonProps {
  onClick: () => void;
  active: boolean;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ onClick, active }) => {
  return (
    <ToggleButtonContainer onClick={onClick} active={active}>
      <ToggleText active={active}>{active ? "ON" : "OFF"}</ToggleText>
      <ToggleCircle active={active} />
    </ToggleButtonContainer>
  );
};

const Settings: React.FC = () => {
  const [nickname, setNickname] = useState("");
  const [nicknameError, setNicknameError] = useState("");
  const [emailAlerts, setEmailAlerts] = useState<boolean>(true);
  const [userData, setUserData] = useState({
    nickName: "",
    userId: "",
    profileImageUrl: face,
    isAgreement: true, // 이메일 수신 여부 초기값 추가
  });
  const [isResetProfile, setIsResetProfile] = useState(false);


  const fileInputRef = useRef<HTMLInputElement>(null);
  const [profileImage, setProfileImage] = useState<File | null>(null);

  useEffect(() => {
    fetchUserData();
  }, []);

  const navigate = useNavigate();
  const [isNicknameValid, setIsNicknameValid] = useState(true);

  const validateNickname = (value: string) => {
    if (!/^[a-zA-Z0-9가-힣ぁ-んァ-ン一-龥]{2,10}$/.test(value)) {
      setIsNicknameValid(false);
      return "닉네임은 공백 없이 2~10자의 한글, 일본어, 영어 또는 숫자로 입력해주세요.";
    }
    setIsNicknameValid(true); // 닉네임이 유효한 경우
    return "";
  };

  const handleNicknameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.length <= 10) {
      setNickname(value);
      setNicknameError(validateNickname(value));
    }
  };

  const delProfile = async () => {
    setIsResetProfile(true);

    // You can also reset the local profile image state
    setProfileImage(null);

    // Update userData to show the default image in the UI
    setUserData((prevState) => ({
      ...prevState,
      profileImageUrl: face,
    }));
  };

  const handleSubmit = async () => {
    // 닉네임이 유효한지 확인
    if (!isNicknameValid) {
      alert("닉네임을 올바르게 입력해주세요.");
      return;
    }

    const formData = new FormData();
    formData.append("nickName", nickname);
    formData.append("agreementToUseSMS", JSON.stringify(emailAlerts));

    if (profileImage) {
      formData.append("profileImage", profileImage);
    }

    // Include the isResetProfile value in the form data
    formData.append("isResetProfile", JSON.stringify(isResetProfile));

    try {
      const response = await axiosInstance.patch("/mypage/settings", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      navigate("/mypage/home");
    } catch (error: any) {
      // console.error("Error updating user data:", error);

      if (error.response && error.response.status === 400) {
        const errorMessage = error.response.data.errorMessage;

        if (errorMessage === "Nickname already in use.") {
          setIsNicknameValid(false);
          setNicknameError("중복된 닉네임입니다. 다른 닉네임을 입력해주세요.");
        } else {
          setNicknameError(
            "알 수 없는 오류가 발생했습니다. 다시 시도해주세요."
          );
        }
      } else {
        setNicknameError("닉네임을 올바르게 입력해주세요.");
      }
    }
  };


  const fetchUserData = async () => {
    try {
      const response = await axiosInstance.get("/mypage/viewMyHome");

      const profileImageUrl = response.data.profileImageUrl || face;

      setUserData({
        nickName: response.data.nickName,
        userId: response.data.userId,
        profileImageUrl: profileImageUrl,
        isAgreement: response.data.agreementToUseSMS, // 이메일 수신 여부 설정
      });

      setNickname(response.data.nickName); // 초기 닉네임 설정
      setEmailAlerts(response.data.agreementToUseSMS); // 초기 이메일 수신 여부 설정

    } catch (error) {
      // console.error("Error fetching user data:", error);
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // 이미지 파일의 크기가 5MB 이하인지 확인
      if (file.size < 5 * 1024 * 1024) {
        setProfileImage(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setUserData((prevState) => ({
            ...prevState,
            profileImageUrl: reader.result as string, // 미리보기 이미지 업데이트
          }));
        };
        reader.readAsDataURL(file);
      } else {
        alert("5MB 이하의 이미지만 업로드할 수 있습니다.");
      }
    }

    // 동일한 이미지를 다시 업로드할 수 있도록 input 초기화
    event.target.value = '';
  };


  // useEffect(() => {
  //   console.log("profileImage updated:", profileImage);
  // }, [profileImage]);

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <ResponsiveWrapper>
      <Container>
        <Header>마이페이지 &gt; 설정하기</Header>
        <MobileHeader>
          <Header1>마이페이지</Header1>
          <Header2>마이페이지 &gt; 설정하기</Header2>
        </MobileHeader>
        <Line />
        <Box>
          <ProfileSection>
            <Avatar>
              <AvatarImage src={userData.profileImageUrl} alt="avatar" />
              <ChangeImg src={camera} alt="camera" onClick={handleClick} />
              <HiddenFileInput
                type="file"
                ref={fileInputRef}
                onChange={handleImageUpload}
                accept="image/jpeg, image/png"
              />
              <DelProfile onClick={delProfile}>기본 이미지 적용</DelProfile>
            </Avatar>
            <Info>
              <Nickname>{userData.nickName}</Nickname>
              <UserId>{userData.userId}</UserId>
            </Info>
          </ProfileSection>
          <FormSection>
            <FormGroup>
              <Label>닉네임</Label>
              <P>
                * 공백없이 한글, 일본어, 영어, 숫자 10글자 이내 입력 가능합니다.
              </P>
              <InputContainer>
                <Input
                  type="text"
                  placeholder="닉네임 (2~10자)"
                  value={nickname}
                  onChange={handleNicknameChange}
                />
              </InputContainer>
              {nicknameError && <ErrorMessage>{nicknameError}</ErrorMessage>}
            </FormGroup>
            <FormGroup>
              <Label>이벤트 및 혜택 알림</Label>
              <P>
                * 알림 수신 동의와 함께 개인정보 마케팅 활용에 동의하시게
                됩니다.
              </P>
              <Email>
                이메일
                <ToggleButton
                  onClick={() => setEmailAlerts(!emailAlerts)}
                  active={emailAlerts}
                />
              </Email>
            </FormGroup>
            <WithdrawalLink
              onClick={() => navigate("/mypage/settings/withdrawal")}
            >
              탈퇴하기 &gt;
            </WithdrawalLink>
            <SubmitButton onClick={handleSubmit}>완료</SubmitButton>
          </FormSection>
        </Box>
      </Container>
    </ResponsiveWrapper>
  );
};

export default Settings;
