import styled from "styled-components";
import { mansionListDataType } from "../Mansion";
import { useRecoilState } from "recoil";
import { mansionPropertyDataStateAtom } from "../../../recoil/mansionPage/mansionPropertyState";
import { useState } from "react";

function MansionContentsBottom({
  mansionState,
}: {
  mansionState?: mansionListDataType;
}) {


  const [paginationState, setPagenationState] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  ]);
  const [mansionPropertyState, setMnasinoPropertyState] = useRecoilState(
    mansionPropertyDataStateAtom
  );

  return (
    <ContentsBottomWrapper>
      <PagenationWrapper>
        <Pagenation>
          <PagenationButton
            invisible={mansionState?.first || mansionState?.empty}
            onClick={() => {
              if (mansionPropertyState.page % 10 === 1) {
                const newState: number[] = [];
                paginationState.map((item) => {
                  newState.push(item - 10);
                });
                setPagenationState(newState);
              }

              setMnasinoPropertyState({
                ...mansionPropertyState,
                page: mansionPropertyState.page - 1,
              });
            }}
          >
            {"<"}
          </PagenationButton>
          {paginationState.map((item, index) => (
            <>
              {mansionState && mansionState?.totalPages >= item && (
                <PagenationButton
                  key={item}
                  invisible={mansionState?.empty}
                  selected={mansionPropertyState.page === item}
                  onClick={() =>
                    setMnasinoPropertyState({
                      ...mansionPropertyState,
                      page: item,
                    })
                  }
                >
                  {item}
                </PagenationButton>
              )}
            </>
          ))}
          <PagenationButton
            invisible={mansionState?.last}
            onClick={() => {
              if (mansionPropertyState.page % 10 === 0) {
                const newState: number[] = [];
                paginationState.map((item) => {
                  newState.push(item + 10);
                });
                setPagenationState(newState);
              }

              setMnasinoPropertyState({
                ...mansionPropertyState,
                page: mansionPropertyState.page + 1,
              });
            }}
          >
            {">"}
          </PagenationButton>
        </Pagenation>
      </PagenationWrapper>
    </ContentsBottomWrapper>
  );
}

export default MansionContentsBottom;

const ContentsBottomWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 221px;
`;

const PagenationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 156px;
`;

const Pagenation = styled.div`
  display: flex;
  align-items: center;
  @media screen and (min-width: 1280px) {
    gap: 23px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 17px;
  }
  @media screen and (max-width: 1023px) {
    gap: 21px;
  }
`;

const PagenationButton = styled.div<{
  invisible?: boolean;
  selected?: boolean;
}>`
  display: ${(props) => props.invisible && "none"};
  font-weight: ${(props) => props.selected && "bold"};
  color: ${(props) => props.selected && "#0123B4"};
  cursor: pointer;
  @media screen and (min-width: 1280px) {
    font-size: 19px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 15px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 18px;
  }
`;
