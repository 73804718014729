import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledSpinner = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid rgba(150, 150, 150, 0.3);
  border-top: 5px solid #0123B4;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

const SpinnerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  backdrop-filter: blur(5px); /* Optional: Adds a blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top of everything */
`;


interface LoadingOverlayProps {
  isSubmitting: boolean;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ isSubmitting }) => {
  if (!isSubmitting) return null;

  return (
    <SpinnerWrapper>
      <StyledSpinner />
    </SpinnerWrapper>
  );
};

export default LoadingOverlay;

// <LoadingOverlay isSubmitting={isSubmitting} />
