import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import axiosInstance from "../../../services/axiosConfig";

interface Props {
  title: string;
  detail: string[];
  top: number;
  left: number;
  id: number;
  onClickIem: () => void;
}

function AdminChoiceAreaOption({
  title,
  top,
  left,
  detail,
  id,
  onClickIem,
}: Props) {
  //킨키, 칸토, 규슈/오키나와만

  return (
    <OverMap>
      <ChoiceAreaOptionWrapper $top={top} $left={left} onClick={onClickIem}>
        <OptionTitle>{title}</OptionTitle>
        <OptionDetailDiv>
          {detail.map((item, index) => (
            <OptionDetail key={index}>{item}</OptionDetail>
          ))}
        </OptionDetailDiv>
      </ChoiceAreaOptionWrapper>
    </OverMap>
  );
}

export default AdminChoiceAreaOption;

const OverMap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 10px;
  @media screen and (min-width: 1280px) {
    width: 800px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 522px;
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

const ChoiceAreaOptionWrapper = styled.div<{ $top: number; $left: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-color: white;
  border: 1px solid #00000050;
  z-index: 20;
  color: #000000a0;
  top: ${(props) => `${props.$top}%`};
  left: ${(props) => `${props.$left}%`};
  &:hover {
    background-color: #0123B4;
    color: white;
    cursor: pointer;
  }
  @media screen and (min-width: 1280px) {
    padding: 15px 13px;
    min-width: 214px;
    max-width: 240px;
    height: 150px;
    gap: 10px;
    border-radius: 10px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 10px 13px;
    min-width: 168px;
    max-width: 200px;
    height: 118px;
    gap: 7px;
    border-radius: 7px;
  }
  @media screen and (max-width: 1023px) {
    padding: 10px;
    max-width: 120px;
    gap: 3px;
    border-radius: 7px;
  }
`;

const OptionTitle = styled.div`
  @media screen and (min-width: 1280px) {
    font-size: 23px;
    font-weight: bold;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 19px;
    font-weight: bold;
  }
  @media screen and (max-width: 1023px) {
    font-size: 13px;
    font-weight: bold;
  }
`;

const OptionDetailDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: 1280px) {
    gap: 7px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 5px;
  }
  @media screen and (max-width: 1023px) {
    gap: 3px;
  }
`;

const OptionDetail = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (min-width: 1280px) {
    font-size: 19px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 15px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 9px;
  }
`;
