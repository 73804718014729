import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Dropdown from "./Dropdown";
import DropdownContainer from "./DropdownContainer";
import { UserInfo, PropertyScrap, FormData } from "./types";

const Form = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  padding: 0 146px;
  border-radius: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
    padding: 0 105px;
  }
  @media screen and (max-width: 1023px) {
    padding: 0 1px;
    border: 0px solid #ccc;
  }
`;

const P2 = styled.div`
  font-size: 23px;
  color: #0123B4;
  margin-top: 73px;


  line-height: 31.44px;
  letter-spacing: -0.025em;
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 14px;
    margin-top: 0px;
    margin-left: 3px;
  }
`;

const Label = styled.div`
  font-size: 23px;
  margin-top: 46px;

  margin-left: 2px;

  letter-spacing: -0.025em;
  font-weight: 500;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
    margin-top: 34px;
  }
`;

const P3 = styled.div`
  font-size: 16px;
  margin-top: 18px;


  letter-spacing: -0.025em;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 15px;
  }
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

const P3Mobile = styled.div`
  display: none;

  @media screen and (max-width: 1023px) {
    display: block;
    font-size: 12px;
    margin-top: 18px;
    line-height: 18px;
    margin-left: 3px;
  }
`;

const EmailContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  font-size: 18px;
  color: #bbbbbb;
`;

const Input = styled.input`
  flex: 1;
  padding: 12px 20px;
  margin-top: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 23px;


  letter-spacing: -0.025em;
  font-weight: 500;

  &::placeholder {
    color: #bbbbbb;
    font-weight: 500;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
    padding: 13px;
  }
`;

const AtSymbol = styled.span`
  margin: 0 10px;

  @media screen and (max-width: 1023px) {
    margin: 0 7px;
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  max-width: 336px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
    max-width: 500px;
  }
`;

const SubContainer2 = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 18px;
  margin-bottom: 116px;
  align-items: baseline;
  justify-content: space-between;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
`;

const Button = styled.button`
font-weight: 500;
  padding: 22px 110px;
  background-color: #eeeeee;
  color: #a6a6a6;
  border: none;
  border-radius: 10px;
  text-align: center;
  cursor: not-allowed;
  margin-top: 102px;
  font-size: 23px;
  transition: background-color 0.3s, color 0.3s, cursor 0.3s;
  margin-bottom: 120px;
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 18px;
    padding: 11px 83px;
    margin-top: 36px;
  margin-bottom: 50px;

  }

  &:disabled {
    background-color: #eeeeee;
    color: #a6a6a6;
    cursor: not-allowed;
  }

  &:not(:disabled) {
    background-color: #0123B4;
    color: #ffffff;
    cursor: pointer;
  }
`;

interface Option {
  id: number;
  label: string;
}

const emailOptions: Option[] = [
  { id: 1, label: "gmail.com" },
  { id: 2, label: "naver.com" },
];

const genderOptions: Option[] = [
  { id: 1, label: "남성" },
  { id: 2, label: "여성" },
];

interface Form01Props {
  onNext: () => void;
  userInfo: UserInfo | null;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo | null>>;
  setFormData: (updatedData: Partial<FormData>) => void;
}

const Form01: React.FC<Form01Props> = ({
  onNext,
  userInfo,
  setUserInfo,
  setFormData,
}) => {
  const [date, setDate] = useState(userInfo?.birth || "");
  const [englishName, setEnglishName] = useState("");
  const [email, setEmail] = useState("");
  const [emailDomain, setEmailDomain] = useState<Option | null>(
    emailOptions[0]
  );
  const [kakaoId, setKakaoId] = useState("");
  const [gender, setGender] = useState<Option | null>(null);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setDate(userInfo?.birth || "");
  }, [userInfo?.birth]);

  useEffect(() => {
    const fullEmail = `${email}@${emailDomain?.label}`;

    const isValidForm = Boolean(
      userInfo?.userName &&
        /^[A-Z\s]+$/.test(englishName) &&
        /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(fullEmail) &&
        kakaoId &&
        date.length === 10 &&
        gender &&
        userInfo?.phoneNum.startsWith("+") // + 기호 확인
    );

    setIsFormValid(isValidForm);
  }, [
    userInfo?.userName,
    englishName,
    email,
    emailDomain,
    kakaoId,
    date,
    gender,
    userInfo?.phoneNum,
  ]);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (value.length < date.length) {
      setDate(value);
    } else {
      value = value.replace(/\D/g, "");

      if (value.length > 8) {
        value = value.slice(0, 8); // 최대 8자리 숫자
      }
      if (value.length >= 4) {
        value = `${value.slice(0, 4)}-${value.slice(4)}`;
      }
      if (value.length >= 7) {
        value = `${value.slice(0, 7)}-${value.slice(7)}`;
      }

      setDate(value);
    }
  };

  const handleNextClick = () => {
    if (isFormValid) {
      setFormData({
        userName: userInfo?.userName,  // 변경된 이름을 포함
        userEngName: englishName,
        birth: date,
        email: `${email}@${emailDomain?.label}`,
        kakaoId: kakaoId,
        gender: gender?.label === "남성" ? "male" : "female",
        phoneNum: userInfo?.phoneNum,
      });
      onNext();
    }
  };


  const handleEmailDomainChange = (option: Option) => {
    setEmailDomain(option);
  };

  const handleGenderChange = (option: Option) => {
    setGender(option);
  };

  const handleUserInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "phoneNum") {
      let formattedValue = value.replace(/\D/g, ""); // 숫자만 남김
      setUserInfo((prevState) =>
        prevState
          ? {
              ...prevState,
              [name]: "+" + formattedValue,
            }
          : null
      );
    } else if (name === "userName") {
      let formattedValue = value; // 모든 입력을 그대로 허용
      setUserInfo((prevState) =>
        prevState
          ? {
              ...prevState,
              [name]: formattedValue,
            }
          : null
      );
    } else {
      setUserInfo((prevState) =>
        prevState
          ? {
              ...prevState,
              [name]: value,
            }
          : null
      );
    }
  };

  return (
    <>
      <Form>
        <P2>* 상담 연결을 위해 정확한 정보를 입력해주세요</P2>
        <Label>이름</Label>
        <Input
          type="text"
          placeholder="이름을 입력해주세요"
          name="userName"
          value={userInfo?.userName || ""}
          onChange={handleUserInfoChange}
        />
        <Input
          type="text"
          placeholder="영어 이름을 입력해주세요"
          value={englishName}
          onChange={(e) => setEnglishName(e.target.value.toUpperCase())}
          pattern="[A-Z\s]+"
        />
        <Label>연락처</Label>
        <Input
          type="text"
          placeholder="+8201012345678"
          name="phoneNum"
          value={userInfo?.phoneNum || ""}
          onChange={(e) => {
            const value = e.target.value;
            if (value.startsWith("+")) {
              handleUserInfoChange(e);
            } else {
              handleUserInfoChange({
                target: {
                  name: e.target.name,
                  value: "+" + value,
                },
              } as any);
            }
          }}
        />
        <Label>메일 주소</Label>
        <EmailContainer>
          <SubContainer>
            <Input
              type="email"
              placeholder="이메일"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </SubContainer>
          <AtSymbol>@</AtSymbol>
          <DropdownContainer>
            <Dropdown
              options={emailOptions}
              selectedOption={emailDomain}
              onOptionSelect={handleEmailDomainChange}
            />
          </DropdownContainer>
        </EmailContainer>

        <Label>카카오톡 아이디</Label>
        <Input
          type="text"
          placeholder="카카오톡 아이디를 입력해주세요"
          value={kakaoId}
          onChange={(e) => setKakaoId(e.target.value)}
        />
        <P3>
          * 상담 연결을 위한 카카오톡 아이디를 적어주세요. (오타 확인, 아이디
          검색 허용 체크 부탁드립니다.)
        </P3>
        <P3Mobile>
          * 상담 연결을 위한 카카오톡 아이디를 적어주세요.
          <br />
          (오타 확인, 아이디 검색 허용 체크 부탁드립니다.)
        </P3Mobile>
        <SubContainer2>
          <SubContainer>
            <Label>성별</Label>
            <DropdownContainer>
              <Dropdown
                options={genderOptions}
                selectedOption={gender}
                onOptionSelect={handleGenderChange}
              />
            </DropdownContainer>
          </SubContainer>
          <SubContainer>
            <Label>생년월일</Label>
            <Input
              type="text"
              value={date}
              placeholder="1954-05-05"
              onChange={handleDateChange}
              pattern="\d{4}\-\d{2}\-\d{2}"
            />
          </SubContainer>
        </SubContainer2>
      </Form>
      <Button onClick={handleNextClick} disabled={!isFormValid}>
        다음
      </Button>
    </>
  );
};

export default Form01;
