import styled from "styled-components";

interface defaultStyleProps {
  $width?: string;
  $direction?: "row" | "column";
  $height?: string;
  $bgColor?: string;
  $padding?: string;
  $margin?: string;
  $justifyContent?: string;
  $alignItems?: string;
  $color?: string;
  $fontSize?: string;
  $fontWeight?: string;
  $rounded?: string;
  $border?: string;
  $cursor?: string;
  $textAlign?: string;
}

interface imgStyleProps {
  $width?: string;
  $height?: string;
  $rotate?: boolean;
  $padding?: string;
  $rotateValue?: number;
  $margin?: string;
  $pointer?: boolean;
}
interface arrowProps {
  $showFilterList?: boolean;
  $hidden?: boolean;
}
interface isShow {
  $show: boolean;
}

interface imgUrlProps {
  $bgUrl: string;
  $width?: string;
  $height?: string;
  $borderRadius?: string;
  $border?: string;
}

interface mapMobilePinShow{
  $mobilePinShow?:boolean
}

export const Wrapper = styled.div`
  position: relative;
  @media screen and (min-width: 1280px) {
    width: 1280px;
    padding: 0 60px;
    margin-top: 40px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 1024px;
    padding: 0 45px;
    margin-top: 40px;
  }
  @media screen and (max-width: 1023px) {
    width: 425px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const MapWrap = styled.div`
  width: 100%;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  margin: 8px 0;
  @media screen and (min-width: 1280px) {
    height: 80vh;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 80vh;
  }
  @media screen and (max-width: 1023px) {
    height: calc(100vh - 106px);
    width: 425px;
    border: 1px solid #cdcdcd;
    border-radius: 0;
    margin: 0;
  }
`;

export const MapContentsWrap = styled.div<mapMobilePinShow>`
  width: 100%;
  border-radius: 5px;
  @media screen and (min-width: 1280px) {
    height: 80vh;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 80vh;
  }
  @media screen and (max-width: 1023px) {
    height: calc(100vh - 106px);
    width: 425px;
    border: 1px solid #cdcdcd;
    border-radius: 0;
    margin: 0;
    
    ${({ $mobilePinShow }) => $mobilePinShow && `height: calc(100vh - 106px - 71px);`}

  }
`;

export const TopWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: end;
  flex-direction: column;
`;

export const MobileComponents = styled.div`
  width: 100%;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

export const WebComponents = styled.div`
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

export const WebFilterList = styled.div<isShow>`
  position: absolute;
  height: 80vh;
  top: 24px;

  ${({ $show }) => !$show && `display:none`}

  @media screen and (min-width: 1280px) {
    left: 60px;
  }
  @media screen and (max-width: 1023px) {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 24px; */
    width: 100%;
    height: calc(100% - 60px);
    top: 60px;
  }
`;

export const FilterListWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 5px;
  @media screen and (max-width: 1023px) {
    width: 90%;
    height: 80%;
    z-index: 20;
  }
`;

export const MylocationWrap = styled.div<mapMobilePinShow>`
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 120px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  @media screen and (min-width: 1280px) {
    right: 71px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    right: 56px;
  }
  @media screen and (max-width: 1023px) {
    bottom: 112px;
    right: 10px;
    ${({ $mobilePinShow }) => $mobilePinShow && `bottom: 180px;`}
  }
`;

export const SearchMansionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  border-top: 1px solid #cdcdcd;
  border-radius: 0 0 0 5px;
  color: white;
  background-color: #0123B4;
  font-family: "Noto Sans KR";
  font-weight: bold;
  @media screen and (min-width: 1280px) {
    min-height: 71px;
    font-size: 23px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    min-height: 60px;
    font-size: 19px;
  }
  @media screen and (max-width: 1023px) {
    min-height: 56px;
    font-size: 18px;
    border-radius: 0 0 5px 5px;
    z-index: 2;
  }
`;

export const SidebarOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #eeeeee;
  border-bottom: none;
  background-color: #ffffff;
  border-radius: 5px 0 0 5px;
  overflow-y: auto;
  z-index: 2;

  @media screen and (min-width: 1280px) {
    padding: 36px 23px 36px;
    height: 100%;
    width: 310px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 29px 25px 29px;
    width: 278px;
  }
  @media screen and (max-width: 1023px) {
    padding: 29px 25px 29px;
    border-radius: 5px;
  }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: none;
  }
`;

export const MapPinDetailWrap = styled.div<isShow>`
  position: absolute;
  height: 80vh;

  @media screen and (min-width: 1280px) {
    top: 24px;
    left: 60px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    top: 24px;
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
    height: 80vh;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: end;
    ${({ $show }) => !$show && `height: fit-content;`}
  }
`;
export const ContentsListWrap = styled(FilterListWrap)<isShow>`
  border-radius: 5px 0 0 5px;
  border: 1px solid #eeeeee;

  z-index: 4;
  @media screen and (max-width: 1023px) {
    width: 100%;
    ${({ $show }) => !$show && `height: fit-content;`}
  }
`;

export const SidebarPinDetailWrapper = styled(SidebarOptionsWrapper)<isShow>`
  z-index: 4;
  display: flex;
  flex-direction: column;
  border-radius: 5px 0 0 5px;

  overflow-y: hidden;
  @media screen and (min-width: 1024px) {
    padding: 24px 18px 0 18px;
    width: fit-content;
    ${({ $show }) => !$show && `padding:0;`}
  }
  @media screen and (max-width: 1023px) {
    align-items: center;
    justify-content: center;
    padding: 12px 20px 0 20px;
  }
`;

export const MapPinContentsListWrap = styled.div<isShow>`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media screen and (max-width: 1023px) {
    height: calc(100% - 50px);
  }

  ${({ $show }) => !$show && `display: none;`}
`;

export const ListWrap = styled.div`
  margin-top: 16px;
  flex-grow: 1; /* 추가: 아래쪽 공간을 모두 차지 */
  overflow-y: auto; /* 스크롤은 여기서만 발생하도록 설정 */
  padding-bottom: 50px;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: none;
  }
`;

export const ListScroll = styled.div`
  &::-webkit-scrollbar {
    width: 4px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: none;
  }
`;

export const RoomListItemWrap = styled.div`
  margin-bottom: 16px;
  display: flex;
`;

export const RoomThumbnailWrap = styled.div<imgUrlProps>`
  cursor: pointer;
  ${({ $bgUrl }) => `background-image: url("${$bgUrl}")`};
  position: relative;
  background-color: white;
  background-size: cover;
  background-position: center;
  margin-right: 16px;
  border-radius: 8px;
  border: 1px solid #eeeeee;

  @media screen and (min-width: 1024px) {
    color: white;
    padding: 24px;
    width: 140px;
    height: 104px;
  }
  @media screen and (max-width: 1023px) {
    width: 180px;
    height: 130px;
  }
`;

export const MapPinContentsTop = styled.div<isShow>`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1024px) {
    align-items: start;
    justify-content: center;
    ${({ $show }) => !$show && `display:none`}
  }
  @media screen and (max-width: 1023px) {
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    padding-bottom: 12px;
    margin-top: 0;
    margin-bottom: 0;
  }
`;
export const PinListTitle = styled.div`
  width: 52px;
  height: 30px;
  background-color: #0123B4;
  color: white;
  border-radius: 40px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 1024px) {
    margin: 0 0 16px 0;
  }
`;

export const PinThumbnailWrap = styled.div<imgUrlProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  

  @media screen and (min-width: 1024px) {
    background-color: white;
    border-radius: 8px;
    border: 1px solid #eeeeee;
    color: white;
    padding: 24px;
    min-width: 274px;
    width: 100%;
    height: 112px;
    min-height: 112px;
    ${({ $bgUrl }) => `background-image: url("${$bgUrl}")`};
    background-size: cover;
    background-position: center;
    position: relative;
    isolation: isolate;
    ::after {
      content: "";
      position: absolute;
      background: #000000;
      z-index: -1;
      inset: 0;
      opacity: 0.5;
    }
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
    color: black;
    border-bottom: 1px solid black;
    padding-bottom: 12px;
  }
`;

export const ScrapButton = styled.img`
  display: block;
  position: absolute;
  filter: drop-shadow(1.5px 1.5px 2px #9b9b9b7b); /*그림자*/
  width: 20px;
  height: 22px;
  right: 8px;
  bottom: 6px;

  @media screen and (max-width: 1023px) {
    width: 22px;
    height: 24px;
    right: 12px;
    bottom: 10px;
  }
`;

export const Option = styled.div`
  font-weight: bold;
  @media screen and (min-width: 1280px) {
    font-size: 23px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 19px;
  }
`;

export const Text = styled.div<defaultStyleProps>`
  font-family: "Noto Sans KR";
  font-size: ${({ $fontSize }) => $fontSize};
  font-weight: ${({ $fontWeight }) => $fontWeight};
  color: ${({ $color }) => $color};
  padding: ${({ $padding }) => $padding};
  margin: ${({ $margin }) => $margin};
  ${({ $width }) => $width && `width: ${$width}`};
  cursor: ${({ $cursor }) => $cursor};
  text-align: ${({ $textAlign }) => $textAlign};
`;

export const Box = styled.div<defaultStyleProps>`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};

  display: flex;
  flex-direction: ${({ $direction }) => $direction};
  justify-content: ${({ $justifyContent }) => $justifyContent};
  align-items: ${({ $alignItems }) => $alignItems};

  background-color: ${({ $bgColor }) => $bgColor};

  border: ${({ $border }) => $border};
  border-radius: ${({ $rounded }) => $rounded};

  padding: ${({ $padding }) => $padding};
  margin: ${({ $margin }) => $margin};

  font-size: ${({ $fontSize }) => $fontSize};
`;

export const ExitBtnWeb = styled.div`
  @media screen and (min-width: 1024px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

export const ExitBtnMobile = styled.div`
  @media screen and (min-width: 1024px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

export const IconWrap = styled.img<imgStyleProps>`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  ${({ $rotate }) => $rotate && `transform: rotate(-90deg);`};
  padding: ${({ $padding }) => $padding};

  ${({ $pointer }) => $pointer && `cursor: pointer;`};
`;

export const ArrowIconWrap = styled.img<imgStyleProps>`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  padding: ${({ $padding }) => $padding};
  margin: ${({ $margin }) => $margin};
  ${({ $rotateValue }) =>
    $rotateValue && `transform: rotate(${$rotateValue}deg);`};
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

export const WebArrowIcon = styled.img<imgStyleProps>`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  padding: ${({ $padding }) => $padding};
  margin: ${({ $margin }) => $margin};
  ${({ $rotateValue }) =>
    $rotateValue && `transform: rotate(${$rotateValue}deg);`};
`;

export const WebArrowIconWrap = styled.div<arrowProps>`
  width: 16px;
  height: 90px;
  position: absolute;
  right: -8px;
  top: 47%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1.5px solid #cecece;
  border-radius: 8px;
  ${({ $showFilterList }) => $showFilterList && `display:none`};
  ${({ $hidden }) => $hidden && `right:-16px`};

  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

export const FilterBtn = styled.button<isShow>`
  font-family: "Noto Sans KR";
  border: 2px solid #b5b5b5;
  border-radius: 8px;
  background-color: white;
  color: #111111;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;

  position: absolute;
  top: 40px;

  @media screen and (min-width: 1280px) {
    left: 24px;
    width: 96px;
    height: 52px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    left: 16px;
    width: 92px;
    height: 48px;
  }

  ${({ $show }) => !$show && `display:none`}
`;
