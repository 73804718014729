import React, { useState, useEffect, ChangeEvent } from 'react';
import styled from 'styled-components';
import { saveCredentials, loadCredentials } from './services/auth';
import KakaoLogin from './components/KakaoLogin';
import GoogleLogin from './components/GoogleLogin';
import LoginForm from './components/LoginForm';
// @ts-ignore
import GoogleIMG from "./IMG/GoogleIMG.png";
// @ts-ignore
import 카카오 from "./IMG/카카오톡.png";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import LOGO from "../../assets/images/logo.png"

const Login: React.FC = () => {
  const [autoLogin, setAutoLogin] = useState<boolean>(false);
  const [id, setId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const credentials = loadCredentials();
    if (credentials) {
      setId(credentials.id);
      setAutoLogin(true);
    }
  }, []);

  const handleAutoLoginChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAutoLogin(e.target.checked);
    if (!e.target.checked) {
      localStorage.removeItem('credentials');
    }
  };

  const handleLogin = async(userId: string,userPw:string ) => {
    if (autoLogin) {
      saveCredentials(id);
    }
    if (id !== '' && password !== '') {
      try {
        const response = await axios.post(
          "https://houberapp.com/auth/login",
          {
            userId,
            userPw,
          }
        );
        if(response.status === 200){
          navigate('/')
        }
        const jwtToken = response.headers["authorization"];
          sessionStorage.setItem("Authorization", jwtToken);
          const refreshToken =  response.headers["refreshtoken"];
          sessionStorage.setItem("RefreshToken", refreshToken);
    } catch(error){
      setShowErrorMessage(true);
      setTimeout(() => {
        setShowErrorMessage(false);
      }, 5000);

    } }
    else if (id === '' || password === '') {
    alert('입력창 비어있음.');
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleLogin(id, password);

  };

  return (
    <LoginContainer>
      <Link style={{textDecoration: 'none'}} to="/">
      <LogoBox>
        <img src={LOGO} style={{width:'100%', height:'100%'}}/>
      </LogoBox>
      </Link>
      <LoginForm
        id={id}
        password={password}
        setId={setId}
        setPassword={setPassword}
        autoLogin={autoLogin}
        handleAutoLoginChange={handleAutoLoginChange}
        onSubmit={handleSubmit}
        handleLogin={handleLogin}
      />
      {showErrorMessage && (
        <ErrorMessage>가입하지 않은 아이디거나 잘못된 비밀번호입니다</ErrorMessage>
      )}
      <InfoDiv>SNS 계정으로 간편 로그인/회원가입</InfoDiv>
      <SocialLoginContainer>
        <GoogleLogin icon={GoogleIMG} />
        <KakaoLogin icon={카카오} />
      </SocialLoginContainer>
    </LoginContainer>
  );
};

export default Login;


const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoBox = styled.div`
  text-align: center;
  margin-top: 150px;
  width: 165px;
  height: 68.7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
    @media (max-width:1024px){
    margin-top: 80px;
  }
  @media (max-width: 500px) {
    width: 130px;
    height: 54.1px;
    margin-top: 45px;
  }

`;



const InfoDiv = styled.div`
  padding-top: 25px;
  height: auto;
  font-size: 18px;
  @media (max-width: 425px) {
    font-size: 12px;
    padding-top: 25px;
  }
`;

const SocialLoginContainer = styled.div`
  justify-content: space-between;
  display: flex;
  width: auto;
  height: auto;
  align-items: center;
  padding-top: 25px;

  @media (max-width: 425px) {
    width: 300px;
    padding-top: 20px;
    justify-content: center;
  }
`;

const ErrorMessage = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 476px;
  height: 136px;
  transform: translate(-50%, -50%);
  border: 2px solid rgba(187, 185, 185, 0.5);
  background-color: #efefef;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;

    @media (max-width: 1023px) {
   width: 350px;
  height: 100px;
  font-size: 14px;
  }
`;
