import { useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';
import LOGO from "../../../assets/images/logo.png"
const Welcome = () => {
  const location = useLocation();
  const data = location.state?.data;

  return (
    <Container>
      <LogoBox>
        <img src={LOGO} style={{width:'100%', height:'100%'}}/>
      </LogoBox>
      <StyledDiv />
      <InfoDiv>
        <InfoText>회원가입</InfoText>
        <InfoText className="secondInfo">
          <InfoText className="IDsection">{data}</InfoText>
          님 회원가입이 완료되었습니다.
        </InfoText>
        <Link to="/login" style={{ textDecoration: 'none', display:'flex',justifyContent:'center' }}>
          <Button>로그인하러 가기</Button>
        </Link>
      </InfoDiv>
    </Container>
  );
};

export default Welcome;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const LogoBox = styled.div`
  text-align: center;
  margin-top: 90px;
    width: 130px;
    height: 51px;

  @media (max-width: 1024px) {
      margin-top: 50px;
      width: 130px;
      height: 51px;
  }

  @media (max-width: 425px) {
    width: 240px;
    height: 100px;
    margin-top: 50px;
  }
`;

const StyledDiv = styled.div`
  background-color: rgba(187, 185, 185, 0.5);
  width: 100%;
  padding: 1px;
  margin-top: 65px;

  @media (max-width: 1024px) {
    margin-top: 50px;
  }

  @media (max-width: 425px) {
    margin-top: 30px;
  }
`;

const InfoDiv = styled.div`
  padding-top: 50px;
  text-align: center;
  width: 545px;

  @media (max-width: 1024px) {
    width: 65%;
    padding-top: 40px;
  }

  @media (max-width: 425px) {
    width: 70%;
    padding-top: 30px;
  }
`;

const InfoText = styled.p`
  font-size: 25px;
  font-weight: 700;

  &.secondInfo {
    margin-top: 50px;
    font-size: 23px;
    font-weight: 400;

    @media (max-width: 1024px) {
      font-size: 20px;
    }

    @media (max-width: 425px) {
      margin-top: 30px;
      font-size: 18px;
    }
  }

  &.IDsection {
    display: contents;
    font-size: 27px;
    font-weight: 700;

    @media (max-width: 1024px) {
      font-size: 24px;
    }

    @media (max-width: 425px) {
      font-size: 20px;
    }
  }

  &.last {
    color: #777777;
    font-size: 18px;
    font-weight: 400;

    @media (max-width: 1024px) {
      font-size: 16px;
    }

    @media (max-width: 425px) {
      font-size: 14px;
    }
  }
`;

const Button = styled.div`
width: 100%;
  margin-top: 50px;
  height: 70px;
  align-items: center;
  justify-content: center;
  background-color: #0123B4;
  color: white;
  font-size: 23px;
  border: none;
  display: flex;
  border-radius: 5px;
  @media (max-width: 1024px) {
    width:475px;
    height: 50px;
    font-size: 18px;
  }

  @media (max-width: 425px) {
    height: 40px;
    font-size: 14px;
  }
`;
