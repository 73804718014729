import styled from "styled-components";
import { roomDetailType } from "../Detail";
import locationIcon from "../../../assets/images/detail/locationIcon.png";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

function DetailLocation({
  roomDetailState,
}: {
  roomDetailState?: roomDetailType;
}) {
  const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const center = {
    lat: Number(roomDetailState?.latitude),
    lng: Number(roomDetailState?.longitude),
  };

  return (
    <DetailLocationWrapper>
      <Information>
        <LocationIcon src={locationIcon} />
        <PostalCode>{roomDetailState?.zipCode}</PostalCode>
        <Address>
          {roomDetailState?.address + " "}
          {roomDetailState?.additionalAddress}
        </Address>
        <Path>
          {roomDetailState?.stationInfo.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </Path>
      </Information>

      <Map>
        <LoadScript googleMapsApiKey={`${API_KEY}`}>
          <GoogleMap
            center={center}
            zoom={19}
            mapContainerStyle={containerStyle}
            options={{ disableDefaultUI: true, zoomControl: true }}
          >
            <Marker position={center} />
          </GoogleMap>
        </LoadScript>
      </Map>
    </DetailLocationWrapper>
  );
}

export default DetailLocation;

const DetailLocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  @media screen and (min-width: 1280px) {
    margin-top: 55px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 52px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 32.5px;
  }
`;

const Information = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  @media screen and (min-width: 1280px) {
    gap: 9px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 9px;
  }
  @media screen and (max-width: 1023px) {
    gap: 8.5px;
  }
`;

const LocationIcon = styled.img`
  background-color: #0123B4;
  border-radius: 50px;
  @media screen and (min-width: 1280px) {
    width: 54.5px;
    height: 54.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 51px;
    height: 51px;
  }
  @media screen and (max-width: 1023px) {
    width: 53.5px;
    height: 53.5px;
  }
`;

const PostalCode = styled.div`
  font-weight: bold;
  text-align: center;
  @media screen and (min-width: 1280px) {
    font-size: 26px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 26px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 20px;
  }
`;

const Address = styled.div`
  letter-spacing: 0;
  text-align: center;
  @media screen and (min-width: 1280px) {
    font-size: 19px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 19px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 15px;
  }
`;

const Path = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(1, 35, 180, 0.3);
  text-align: center;
  font-weight: 500;
  border-radius: 5px;
  @media screen and (min-width: 1280px) {
    padding: 5px 21px;
    font-size: 19px;
    gap: 8px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 4px 15px;
    font-size: 19px;
    gap: 7px;
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
    padding: 3px 0;
    font-size: 15px;
    gap: 6px;
  }
`;

const Map = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 1280px) {
    margin-top: 31px;
    height: 182px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-top: 30px;
    height: 184px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 12px;
    height: 147px;
  }
`;
