import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../services/axiosConfig";
import back from "../../assets/images/qna/back_admin.png";
import face from "../../assets/images/mypage/face.png"; // 경로 수정
import imageIcon from "../../assets/images/qna/image_5632107.png"; // 경로 수정
import LoadingOverlay from "../../components/LoadingOverlay";
import { v4 as uuidv4 } from "uuid";
import FileIcon from "../../assets/images/qna/dd.png";
import CheckImg from "../../assets/images/qna/check.png";
import {Helmet} from "react-helmet";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  min-height: 100vh;
  width: 100%;
`;

const FormContainer = styled.div`
  width: 1323px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const FormWrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 0 43px;
  padding-bottom: 100px;
`;

const BackButtonContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 47px 62px;
  border: none;
  background: none;
  align-items: center;
  font-size: 30px;
  color: #333333;
`;

const BackButton = styled.img`
  display: flex;
  height: 30px;
  font-size: 18px;
  border: none;
  background: none;
  cursor: pointer;
  align-items: center;
  margin-right: 12px;
`;

const HeaderSection = styled.div`
  width: 100%;
  padding: 0 59px;
  margin-bottom: 20px;
`;

const Header = styled.h1`
  font-size: 50px;
  font-weight: bold;
  margin-top: 76px;
  margin-bottom: 50px;
`;

const SubHeader = styled.h2`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 24px;
  color:#333333;
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const Stats = styled.div`
  font-size: 16px;
  color: #999; 
  text-align: right;
  margin-bottom: 5px;
  font-weight: 500;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.div<{ src: string }>`
  width: 56px;
  height: 56px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 20px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
`;

const ProfileInfo = styled.div`
  font-size: 18px;
  color: #999;
  margin-bottom: 5px;
`;

const Date = styled.div`
  font-size: 18px;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #ccc;
  margin: 0 43px;
`;

const TextAreaContainer = styled.div`
  padding: 43px 20px;
  font-size: 25px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
font-weight: 500;
  width: 100%;
  /* overflow-y: auto; */
  white-space: pre-line;
  line-height: 1.5;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 12px 56px;
  font-size: 25px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 269px;
  height: 55px;
  white-space: nowrap;
`;

const DefaultButton = styled(Button)`
  background-color: #0123B4;
  color: white;
  font-weight: bold;
  font-size: 25px;
  display: flex;
    align-items: center;
    justify-content: center;
`;

const Keyword = styled.div`
  display: inline-block;
  font-size: 23px;
  margin-right: 36px;
  color: #333333;
`;

const KeywordContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  margin-bottom: 33px;
  font-weight: 500;
  margin-left: 20px;
`;

const AnswerInputContainer = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  background-color: #f7f8f9;
`;

const AnswerInput = styled.textarea`
  width: 100%;
  font-size: 18px;
  padding: 0px;
  resize: none;
  outline: none;
  box-sizing: border-box;
  min-height: 50px;
  border: none;
  background-color: #f7f8f9;
  font-family: 'Noto Sans', sans-serif;

  font-weight: 500;

  &::placeholder {
    color: #aaa;
    font-family: 'Noto Sans', sans-serif;
  font-weight: 500;


  }


`;

const ImageUploadIcon = styled.img`
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const UploadedImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* 자식 요소를 여러 줄로 배치 */
  overflow-x: scroll;
  margin-top: 20px;
  gap: 10px;

  /* overflow: auto; */
scrollbar-width: none; /* Firefox용 */
-ms-overflow-style: none; /* IE 및 Edge용 */

&::-webkit-scrollbar { 
  display: none; /* Chrome, Safari, Opera용 */
}
`;

const AnswerImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
`;

const DeleteButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

const UploadedItemContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 600px;
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  margin-bottom: 16px;
  overflow: hidden;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-bottom: 14px;
  }

  @media screen and (max-width: 1023px) {
    margin-bottom: 12px;
  }
`;

const QuestionImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 600px;
  margin-bottom: 20px;

  object-fit: contain; /* 이미지 전체를 보여주기 위해 contain으로 변경 */
`;

const UploadedItemFileContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-height: 600px;
  border: 0px solid #ccc;
  border-radius: 10px;
  margin-bottom: 16px;
  overflow: hidden;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    margin-bottom: 14px;
  }

  @media screen and (max-width: 1023px) {
    margin-bottom: 12px;
  }
`;

const UploadedFile = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 30px 42px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 16px;
  height: 93px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 25px 36px;

    height: 85px;
  }

  @media screen and (max-width: 1023px) {
    padding: 20px 30px;

    height: 75px;
  }
`;

const FileIconImg = styled.img`
  width: 34px;
  margin-right: 22px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 30px;
    margin-right: 18px;
  }

  @media screen and (max-width: 1023px) {
    width: 26px;
    margin-right: 14px;
  }
`;

const Check = styled.img`
  width: 32px;
  margin-left: 22px;

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 28px;
    margin-left: 18px;
  }

  @media screen and (max-width: 1023px) {
    width: 24px;
    margin-left: 14px;
  }
`;

type ImageAndFile = {
  id: string | null;
  url: string;
  file?: File; // 이미지 파일을 저장할 수 있는 선택적 속성 추가
  fileName?: string;
};

type Answer = {
  id: number;
  content: string;
  images: ImageAndFile[];
};

type Question = {
  title: string;
  content: string;
  nickName: string;
  userProfileImage: string;
  createAt: string;
  imageAndFile: ImageAndFile[];
  link: string[];
  keywords: string[];
  views: number;
  recommends: number;
  answers: Answer[];
};

const QuestionDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [question, setQuestion] = useState<Question | null>(null);
  const [answers, setAnswers] = useState<Answer[]>([]);

  const [editMode, setEditMode] = useState(false);
  const [deletedImageIds, setDeletedImageIds] = useState<string[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const response = await axiosInstance.get(`/admin/qna/detail/${id}`);
        // console.log(response);
        const responseData: Question = response.data;
        setQuestion(responseData);

        // Set answers including images
        setAnswers(responseData.answers || []);
      } catch (error) {
        // console.error("Error fetching question:", error);
      }
    };

    fetchQuestion();
  }, [id]);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);
      const validFiles = selectedFiles.filter(
        (file) =>
          ["image/jpeg", "image/png", "image/gif"].includes(file.type) &&
          file.size < 5 * 1024 * 1024 // 5MB 이하의 이미지 필터링
      );

      setAnswers((prevAnswers) => {
        const updatedAnswers = [...prevAnswers];
        const totalImages =
          updatedAnswers[0]?.images.length + validFiles.length;

        if (totalImages > 15) {
          const remainingSlots = 15 - updatedAnswers[0]?.images.length;
          alert(
            `최대 15개의 이미지만 업로드할 수 있습니다. ${remainingSlots}개만 추가됩니다.`
          );
          updatedAnswers[0].images.push(
            ...validFiles.slice(0, remainingSlots).map((file) => ({
              id: `local-${uuidv4()}`, // 로컬에서만 사용할 고유 ID 부여
              url: URL.createObjectURL(file),
              file: file, // file 속성을 추가하여 실제 파일 데이터를 저장
            }))
          );
        } else {
          updatedAnswers[0].images.push(
            ...validFiles.map((file) => ({
              id: `local-${uuidv4()}`, // 로컬에서만 사용할 고유 ID 부여
              url: URL.createObjectURL(file),
              file: file, // file 속성을 추가하여 실제 파일 데이터를 저장
            }))
          );
        }

        return updatedAnswers;
      });
    }
  };

  const handleImageDelete = (imageId: string | null) => {
    const isConfirmed = window.confirm("정말로 이 이미지를 삭제하시겠습니까?");

    if (!isConfirmed) {
      return; // 사용자가 삭제를 취소한 경우 함수 종료
    }

    // console.log("삭제할 이미지 ID:", imageId);

    // 서버에서 받아온 이미지 (ID가 존재하고, 로컬 ID 형식이 아닌 경우)
    if (imageId && !imageId.startsWith("local-")) {
      setDeletedImageIds((prevIds) => {
        if (!prevIds.includes(imageId)) {
          const updatedIds = [...prevIds, imageId];
          // console.log("삭제된 이미지 ID 목록:", updatedIds);
          return updatedIds;
        }
        return prevIds;
      });
    }

    // UI에서 이미지 제거 (로컬 ID를 포함한 모든 이미지)
    setAnswers((prevAnswers) => {
      const updatedAnswers = prevAnswers.map((answer) => {
        const updatedImages = answer.images.filter(
          (image) => image.id !== imageId
        );
        // console.log("UI에서 제거된 후 남은 이미지 목록:", updatedImages);
        return {
          ...answer,
          images: updatedImages,
        };
      });
      return updatedAnswers;
    });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAnswerSubmit = async () => {
    if (!answers[0]?.content.trim()) {
      alert("답변을 입력해주세요.");
      return;
    }

    try {
      setIsSubmitting(true); // Start spinner

      const formData = new FormData();
      formData.append("contents", answers[0].content);

      let totalSize = 0;

      answers[0].images.forEach((image) => {
        if (image.file) {
          totalSize += image.file.size;
          formData.append("images", image.file);
        }
      });

      // 삭제된 이미지 ID 배열을 전송
      deletedImageIds.forEach((id) => formData.append("deleteImageIds", id));

      // Body 사이즈가 50MB를 초과하면 경고 메시지 표시
      if (totalSize > 50 * 1024 * 1024) {
        // 50MB
        alert("업로드할 파일의 용량이 너무 큽니다. 총 50MB 이하로 줄여주세요.");
        setIsSubmitting(false); // Stop spinner
        return;
      }

      const endpoint = answers[0].id
        ? `/admin/qna/update/${answers[0].id}`
        : `/admin/qna/answer/${id}`;

      const response = await axiosInstance.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        alert("답변이 등록되었습니다.");
        setEditMode(false);
        window.location.reload();
      } else {
        throw new Error("등록 실패");
      }
    } catch (error) {
      // console.error("Error submitting answer:", error);
      alert("등록 중 오류가 발생했습니다. 다시 시도해주세요.");
    } finally {
      setIsSubmitting(false); // Stop spinner
    }
  };

  const handleImageClick = (src: string) => {
    window.open(src, "_blank");
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow"/>
      </Helmet>
      <LoadingOverlay isSubmitting={isSubmitting} />

      <Container>
        <BackButtonContainer onClick={() => navigate("/admin/qna")}>
          <BackButton src={back} />
          뒤로가기
        </BackButtonContainer>
        <FormContainer>
          <HeaderSection>
            <Header>Q&A</Header>
            <SubHeader>{question?.title}</SubHeader>
            <Box>
              <ProfileContainer>
                <Avatar src={question?.userProfileImage || face} />
                <div>
                  <ProfileInfo>{question?.nickName}</ProfileInfo>
                  <Date>{question?.createAt.split("T")[0]}</Date>
                </div>
              </ProfileContainer>
              <Stats>
                조회 {question?.views} • 추천 {question?.recommends}
              </Stats>
            </Box>
          </HeaderSection>
          <Divider />
          <FormWrapper>
            <TextAreaContainer>{question?.content}</TextAreaContainer>

            {question?.imageAndFile.map((item: ImageAndFile, index: number) => {
              // 이미지인지 파일인지 구분
              if (item.url && !item.fileName?.endsWith(".pdf")) {
                return (
                  <UploadedItemContainer key={item.id || index}>
                    <QuestionImage
                      src={item.url}
                      alt={`uploaded ${index}`}
                      onClick={() => handleImageClick(item.url)}
                    />
                  </UploadedItemContainer>
                );
              } else if (item.fileName?.endsWith(".pdf")) {
                return (
                  <UploadedItemFileContainer key={item.id || index}>
                  <a href={item.url} target="_blank" rel="noopener noreferrer" download={item.fileName}>
                    <UploadedFile>
                      <FileIconImg src={FileIcon} />
                      <div>{item.fileName}</div>
                      <Check src={CheckImg} />
                    </UploadedFile>
                  </a>
                </UploadedItemFileContainer>

                );
              } else {
                return null; // 이미지도 파일도 아닌 경우 처리하지 않음
              }
            })}

            <KeywordContainer>
              {question?.keywords.map((keyword: string, index: number) => (
                <Keyword key={index}>#{keyword}</Keyword>
              ))}
            </KeywordContainer>

            <AnswerInputContainer>
              <AnswerInput
                placeholder="답변을 적어주세요."
                value={answers[0]?.content || ""}
                onChange={(e) => {
                  if (answers.length === 0) {
                    // answers 배열이 비어 있으면 새로운 답변 객체를 추가합니다.
                    setAnswers([
                      { id: 0, content: e.target.value, images: [] },
                    ]);
                  } else {
                    // 기존의 첫 번째 답변 객체의 content를 업데이트합니다.
                    setAnswers((prev) =>
                      prev.map((answer, i) =>
                        i === 0
                          ? { ...answer, content: e.target.value }
                          : answer
                      )
                    );
                  }
                }}
                disabled={!editMode}
              />

              {editMode && (
                <>
                  <input
                    type="file"
                    accept="image/jpeg, image/png, image/gif"
                    multiple
                    style={{ display: "none" }}
                    id="image-upload"
                    onChange={handleImageUpload}
                  />
                  <label htmlFor="image-upload">
                    <ImageUploadIcon src={imageIcon} alt="upload" />
                  </label>
                </>
              )}

              <UploadedImagesContainer>
                {answers[0]?.images.map((image, index) => (
                  <div key={image.id || index} style={{ position: "relative" }}>
                    <AnswerImage
                      src={image.url}
                      alt={`answer uploaded ${index}`}
                      onClick={() => handleImageClick(image.url)}
                    />
                    {editMode && (
                      <DeleteButton
                        onClick={() => handleImageDelete(image.id || "")}
                      >
                        ×
                      </DeleteButton>
                    )}
                  </div>
                ))}
              </UploadedImagesContainer>
            </AnswerInputContainer>
            <ButtonContainer>
              {!editMode && (
                <DefaultButton onClick={() => setEditMode(true)}>
                  {answers.length > 0 ? "답변 수정하기" : "답변하기"}
                </DefaultButton>
              )}
              {editMode && (
                <DefaultButton onClick={handleAnswerSubmit}>
                  저장하기
                </DefaultButton>
              )}
            </ButtonContainer>
          </FormWrapper>
        </FormContainer>
      </Container>
    </>
  );
};

export default QuestionDetail;
