import React, { ChangeEvent, useState, useEffect } from "react";
import styled from "styled-components";
import CountrySelect from "./ContrySelect";
import Timer from "./Timer";
import axios from "axios";

interface FindIdSectionProps {
  title: string;
  type:string;
  onConfirmClick: (verificationCode: string,phoneNum:string, type:string, countryCode:string) => void;
  verificationCodeValid:boolean
}

const FindIdSection: React.FC<FindIdSectionProps> = ({
  type,
  title,
  onConfirmClick,
  verificationCodeValid,
}) => {
  const [countryCode, setCountryCode] = useState("82");
  const [phoneNum, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(false);
  const [phoneNumberCount, setPhoneNumberCount] = useState(false);

  useEffect(() => {
    setPhoneNumberCount(phoneNum.length >= 1); // considering hyphens
  }, [phoneNum]);

  const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    setPhoneNumber(onlyNums);
  };

  const handleVerificationCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 6) {
      setVerificationCode(e.target.value);
    }
  };

  const handleConfirmClick = () => {
    if (verificationCode.length === 6) {
     onConfirmClick(verificationCode,phoneNum,type,countryCode);

    } else {
      alert("인증번호가 6자리가 아닙니다.");
    }
  };

  const sendPhoneNumber = async () => {
    const ctrphonenum = `+${countryCode}${phoneNum}`;
    console.log(ctrphonenum);

    const queryParams = new URLSearchParams({
      phoneNum: ctrphonenum,
      type,
    }).toString();

    try {
      const response = await axios.post(
        `https://houberapp.com/auth/verify?${queryParams}`
      );
      setConfirmationResult(true);
    } catch (error) {
      console.error(error);
      alert('유효하지 않은 번호입니다')
    }
  };

  return (
    <>
      <DetailTextDiv>{title}</DetailTextDiv>
      <CountrySelect
        countryCode={countryCode}
        setCountryCode={setCountryCode}
      />
      <InputField
        type="text"
        value={phoneNum}
        onChange={handlePhoneNumberChange}
        placeholder="휴대전화 번호 입력"
        maxLength={13}
        style={{width:'auto'}}
      />

      <Button
        disabled={!phoneNumberCount}
        onClick={sendPhoneNumber}
      >
        전화번호 인증하기
      </Button>
      {confirmationResult && (
        <ConfrimBox>
          <LayoutDiv>
            <ConfirmText>인증번호를 입력해주세요</ConfirmText>
            <DivInput>
              <InputField
                style={{border: "none", margin: "0px" }}
                type="text"
                value={verificationCode}
                onChange={handleVerificationCodeChange}
                placeholder="인증번호 6자리"
              />

{ verificationCodeValid&& (<Timer onTimeout={() => alert("시간 초과!")} />)}
              <AuthButton
                disabled={verificationCode.length !== 6 || !verificationCodeValid}
                onClick={handleConfirmClick}
              >
                {verificationCodeValid ? "확인":"인증완료"}
              </AuthButton>

            </DivInput>
            <DivInput className="resend">
              <div>* 인증번호를 받지 못하셨나요?</div>
              <ResendBTN onClick={()=>{sendPhoneNumber(); alert("재전송 되었습니다.")}}>재전송하기</ResendBTN>
            </DivInput>
          </LayoutDiv>
        </ConfrimBox>
      )}
    </>
  );
};

export default FindIdSection;

const DetailTextDiv = styled.div`
  margin-top: 30px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 23px;
  @media (max-width: 500px) {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 11px;
  }
`;

const InputField = styled.input`
  font-family: "Noto Sans KR";
  border-radius: 10px;
  width: 330px;
  padding-left: 20px;
  margin: 5px 0;
  font-size: 23px;
  border: 1px solid #ccc;
  height: 60px;
  outline: none;
  background-color: transparent;
  font-weight: 500;

  &::placeholder {
    color: #bbbbbb;
    opacity: 1;
  }

  @media (max-width: 1024px) {
    width: 280px;
    height: 60px;
    font-size: 22px;
  }
  @media (max-width: 500px) {
    width: 230px;
    height: 34px;
    font-size: 12px;
  }
`;

const Button = styled.button<{ disabled: boolean }>`
  background-color: ${({disabled}) => (disabled ? "#ebebeb" : "#0123B4")};
  color: ${({disabled}) => (disabled ? "#9c9c9c" : "white")};
  font-size: 23px;
  border: 1px solid #cacaca;
  width: auto;
  height: 70px;
  border-radius: 10px;
  font-weight: 600;
  margin-top: 5px;
  cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};
  transition: background-color 0.3s ease;
  font-family: "Noto Sans KR";

  &:disabled {
    cursor: not-allowed;
  }

  @media (max-width: 1024px) {
    height: 60px;
    font-size: 22px;
  }
  @media (max-width: 500px) {
    height: 34px;
    font-size: 11px;
  }
`;
const AuthButton = styled.button<{ disabled: boolean }>`
  font-size: 15.5px;
  background-color: white;
  color: ${({disabled}) => (disabled ? "#BBBBBB" : "black")};
  border: none;
  margin: 0 10px 0;
  cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};
  padding: 0;
  font-weight: 400;

  &:disabled {
    cursor: not-allowed;
  }

  @media (max-width: 1024px) {
    font-size: 17px;
    margin: 0 0 0 6px;
    padding: 0;
  }
  @media (max-width: 500px) {
    font-size: 12px;
    margin: 0 0 0 3px;
    padding: 0;
  }
`;

const ConfrimBox = styled.div`
  width: 100%;
  height: 208px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;

  @media (max-width: 500px) {
    height: 142px;
  }
`;

const LayoutDiv = styled.div`
  display: flex;
  flex-direction: column;
  

  @media (max-width: 1024px) {
    width: 420px;
    height: 142px;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 500px) {
    width: 374px;
    height: 142px;
    align-items: center;
    justify-content: center;
  }
`;

const ConfirmText = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  width: 470px;

  @media (max-width: 1024px) {
    font-weight: 700;
    width: 400px;
    font-size: 14px;
  }
  @media (max-width: 500px) {
    font-weight: 700;
    width: 322px;
    font-size: 14px;
  }
`;

const DivInput = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(187, 185, 185, 0.5);
  border-radius: 5px;
  background-color: white;
  align-items: center;
  &.resend {
    background-color: #f7f7f7;
    border: none;
    margin-top: 20px;
    @media (max-width: 1024px) {
      width: 400px;
      height: 42px;
      margin-top: 0px;
      font-size: 13px;
    }

    @media (max-width: 500px) {
      width: 322px;
      height: 42px;
      margin-top: 0px;
      font-size: 13px;
    }
  }
  @media (max-width: 1024px) {
    width: 400px;
    height: 62px;
  }
  @media (max-width: 500px) {
    width: 322px;
    height: 42px;
  }
`;

const ResendBTN = styled.button`
  border: none;
  background-color: #f7f7f7;
  font-size: 16px;
  font-weight: 700;
  color: #777777;
  cursor: pointer;
  @media (max-width: 1024px) {
    font-size: 15px;
  }
  @media (max-width: 500px) {
    font-size: 14px;
  }

`;
